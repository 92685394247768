import React from 'react'
import { styled, Grid as BaseGrid } from '@mui/material'
import { Breadcrumb as BreadcrumbBase } from '@chilecompra/react-kit/components'

export const Root = styled('div')`
  width: 100%;
  padding: 7px 32px;
`

export const SpanCodeHeader = styled('span')`
  font-size: 27px;
  font-weight: 700;
  line-height: 31px;
  color: ${props => props.theme.palette.brand.black3};
  margin-left: 10px;
`

export const Breadcrumb = styled(BreadcrumbBase)`
  cursor: pointer;
`
export const GridContainer = styled(({ textAlign, ...props }) => <BaseGrid container {...props} />)`
  &.MuiGrid-container {
    height: ${props => props.height};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`
