import React, { useEffect, useMemo, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'

import { getCookie, useEffectOnce, usePathParam } from '@chilecompra/react-kit'

import DirectDealSummaryContractDialog from './DirectDealSummary.contract.dialog'
import DirectDealSummaryDecision from './DirectDealSummary.decision'
import DirectDealSummaryDetail from './DirectDealSummary.detail'
import DirectDealSummaryFooter from './DirectDealSummary.footer'
import DirectDealSummaryHeader from './DirectDealSummary.header'
import DirectDealSummaryInfoDecision from './DirectDealSummary.information'
import DirectDealSummaryIssueDialog from './DirectDealSummary.issue'
import DirectDealSummaryProducts from './DirectDealSummary.products'
import DirectDealSummaryPurchases from './DirectDealSummary.purchases'
import DirectDealSummaryQuotes from './DirectDealSummary.quotes'
import DirectDealSummaryRequestDialog from './DirectDealSummary.request'
import DirectDealSummaryRequests from './DirectDealSummary.requests'

import { useAuthorization } from '../AuthProvider/AuthProvider.hooks'

import {
  onApplyDecisionDirectDealSummaryThunk,
  onCleanDirectDealSummary,
  onCloseContractDetailDialogDirectDealSummary,
  onCloseContractDialogDirectDealSummary,
  onCloseIssueDialogDirectDealSummary,
  onCreateContractDirectDealSummaryThunk,
  onGetContractRequestsDirectDealSummaryThunk,
  onGetDetailDirectDealSummaryThunk,
  onGetInfoProviderDirectDealSummaryThunk,
  onGetQuotesDirectDealSummaryThunk,
  onIssuePurchaseDirectDealSummaryThunk,
  onOpenContractDetailDialogDirectDealSummary,
  onOpenContractDialogDirectDealSummary,
  onOpenIssueDialogDirectDealSummary,
  onPrepareContractDirectDealSummaryThunk
} from './DirectDealSummary.actions'
import { onGetPublicTokenOnDemandThunk } from '../AuthProvider/AuthProvider.actions'

import { Root } from './DirectDealSummary.styles'

import { REACT_APP_PROVIDERS_URL, REACT_APP_MERCADO_PUBLICO_BASE_URL } from '../../config/settings/environment'
import useConditionalSearchNavigate from '../../modules/hooks/useCondicionalSearchNavigate'
import { dialogScrollTop } from '../../modules/utils/dialogScrollTop'
import { useFormRef } from '../../modules/hooks/useFormRef'
import {
  DIRECT_DEAL_SHEET_STATUS_DICTIONARY,
  DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY
} from '../../config/settings/constants'

/**
 * The DirectDealSummary's container.
 */
const DirectDealSummaryContainer = () => {
  const { accessToken, isBuyer, isSeller, userDni } = useAuthorization()
  const dispatch = useDispatch()
  const directDealId = usePathParam('directDealId')
  const { contracts, issue, loading, provider, quotes, sheet } = useSelector(state => state.directDealSummary)
  const formCreateRequestRef = useFormRef()
  const formDecisionRef = useFormRef()
  const [requestContractId, setRequestContractId] = useState(null)
  const { conditionalNavigate } = useConditionalSearchNavigate(isSeller, isBuyer)

  const handlerBack = () => {
    const query = getCookie('search')
    conditionalNavigate(query)
  }

  const handlerCreateRequest = () => {
    dispatch(
      onCreateContractDirectDealSummaryThunk({
        requestAnswer: formCreateRequestRef.current.answerRequestValue.value,
        requestDni: formCreateRequestRef.current.dniRequestValue,
        requestId: contracts?.sheetIdRequesting,
        requestLegalName: formCreateRequestRef.current.businessRequestValue
      })
    )

    if (formCreateRequestRef.current) {
      formCreateRequestRef.current.resetField('answerRequestValue')
      formCreateRequestRef.current.cleanErrorField('answerRequestValue')
    }
  }

  const handlerOpenIssueDialog = () => {
    dispatch(onOpenIssueDialogDirectDealSummary())
    dialogScrollTop()
  }

  const handlerCloseIssuePurchase = () => {
    dispatch(onCloseIssueDialogDirectDealSummary())
  }

  const handlerRedirectProviderSheet = () => {
    dispatch(
      onGetPublicTokenOnDemandThunk(publicAccess =>
        window.open(
          `${REACT_APP_PROVIDERS_URL}/ficha/callback?access_token=${publicAccess.token}&rut=${sheet?.providerDni}&expires_in=${publicAccess.expires_in}`
        )
      )
    )
  }

  const handlerRedirectPurchaseSheet = id => {
    window.open(
      `${REACT_APP_MERCADO_PUBLICO_BASE_URL}/PurchaseOrder/Modules/PO/DetailsPurchaseOrder.aspx?codigooc=${id}`,
      '_blank'
    )
  }

  const handlerIssuePurchase = async () => {
    dispatch(onIssuePurchaseDirectDealSummaryThunk(sheet?.code))
  }

  const handlerRedirectStepOnePurchaseSheet = id => {
    window.location.href = `${REACT_APP_MERCADO_PUBLICO_BASE_URL}/PurchaseOrder/WizardCreateAndRedirect.aspx?POMODE=POEdit&porId=${id}`
  }

  const handlerOpenRequest = () => {
    dispatch(
      onPrepareContractDirectDealSummaryThunk({
        sheetId: sheet?.id,
        callback: () => {
          dispatch(onOpenContractDialogDirectDealSummary())
          dispatch(onGetInfoProviderDirectDealSummaryThunk(userDni))
          dialogScrollTop()
        }
      })
    )
  }

  const handlerCloseRequest = () => {
    dispatch(onCloseContractDialogDirectDealSummary())
  }

  const handlerCloseRequestDetail = () => {
    dispatch(onCloseContractDetailDialogDirectDealSummary())
  }

  const handlerOpenRequestDetail = request => {
    setRequestContractId(request?.id)
    dispatch(onOpenContractDetailDialogDirectDealSummary())
    dialogScrollTop()
  }
  const handlerDesist = () => {
    dispatch(
      onApplyDecisionDirectDealSummaryThunk({
        sheetId: sheet?.id,
        mechanism: formDecisionRef.current.mechanismValue,
        decision: formDecisionRef.current.valueRadioDecision,
        description: formDecisionRef.current.reasonValue
      })
    )
  }

  const disabledRequest = useMemo(() => {
    return (
      formCreateRequestRef.current.answerRequestValue?.value === '' ||
      formCreateRequestRef.current.answerRequestValue?.error !== '' ||
      formCreateRequestRef.current.businessRequestValue === '' ||
      formCreateRequestRef.current.businessRequestError !== '' ||
      formCreateRequestRef.current.dniRequestValue === '' ||
      formCreateRequestRef.current.dniRequestError !== ''
    )
  }, [
    formCreateRequestRef.current.answerRequestValue?.value,
    formCreateRequestRef.current.answerRequestValue?.error,
    formCreateRequestRef.current.businessRequestValue,
    formCreateRequestRef.current.businessRequestError,
    formCreateRequestRef.current.dniRequestValue,
    formCreateRequestRef.current.dniRequestError
  ])

  const disabledIssue = useMemo(() => {
    return (
      formDecisionRef.current.valueRadioDecision === '' ||
      (formDecisionRef.current.valueRadioDecision ===
        DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY.CONTINUE_WITH_ORIGINAL_PROVIDER &&
        (formDecisionRef.current.justificationValue === '' || formDecisionRef.current.justificationError !== ''))
    )
  }, [
    formDecisionRef.current.justificationError,
    formDecisionRef.current.justificationValue,
    formDecisionRef.current.valueRadioDecision
  ])

  const disabledDesist = useMemo(() => {
    return (
      formDecisionRef.current.valueRadioDecision === '' ||
      (formDecisionRef.current.valueRadioDecision ===
        DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY.USE_ANOTHER_MECHANISM &&
        (formDecisionRef.current.mechanismValue === '' ||
          formDecisionRef.current.mechanismError !== '' ||
          formDecisionRef.current.reasonValue === '' ||
          formDecisionRef.current.reasonError !== '')) ||
      (formDecisionRef.current.valueRadioDecision ===
        DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY.STOP_PROCESS &&
        (formDecisionRef.current.reasonValue === '' || formDecisionRef.current.reasonError !== ''))
    )
  }, [
    formDecisionRef.current.mechanismError,
    formDecisionRef.current.mechanismValue,
    formDecisionRef.current.reasonError,
    formDecisionRef.current.reasonValue,
    formDecisionRef.current.valueRadioDecision
  ])

  useEffectOnce(() => {
    batch(() => {
      dispatch(onCloseIssueDialogDirectDealSummary())
      dispatch(onGetDetailDirectDealSummaryThunk(directDealId))
      dispatch(onGetQuotesDirectDealSummaryThunk(directDealId))
      dispatch(onGetContractRequestsDirectDealSummaryThunk(directDealId))
    })
  })

  useEffect(() => {
    dispatch(onCleanDirectDealSummary())
  }, [dispatch])

  return (
    <Root>
      <DirectDealSummaryHeader sheetCode={sheet?.code} status={sheet?.status} loading={loading} />
      <DirectDealSummaryDetail
        sheet={sheet}
        loading={loading}
        token={accessToken}
        onRedirect={handlerRedirectProviderSheet}
      />
      {sheet?.products && sheet?.products.length > 0 && (
        <DirectDealSummaryProducts products={sheet?.products} loading={loading} />
      )}
      {quotes && quotes.length > 0 && <DirectDealSummaryQuotes quotes={quotes} loading={loading} />}
      {sheet?.purchases && sheet?.purchases.length > 0 && !sheet.withPublicity && (
        <DirectDealSummaryPurchases
          purchases={sheet?.purchases}
          loading={loading}
          onRedirect={handlerRedirectPurchaseSheet}
        />
      )}
      {isSeller && sheet?.status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.RECEIVING_REQUEST && sheet?.withPublicity && (
        <DirectDealSummaryRequests
          contracts={contracts}
          loading={loading}
          onOpenDetail={handlerOpenRequestDetail}
          onOpenForm={handlerOpenRequest}
        />
      )}
      {isBuyer && sheet?.status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED && sheet?.withPublicity && (
        <DirectDealSummaryDecision formRef={formDecisionRef} loading={loading} />
      )}
      {isBuyer &&
        (sheet?.status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.ISSUE_PURCHASE ||
          sheet?.status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CANCEL) &&
        sheet?.withPublicity && <DirectDealSummaryInfoDecision sheet={sheet} contracts={contracts} loading={loading} />}
      <DirectDealSummaryFooter
        disabledDesist={disabledDesist}
        disabledIssue={disabledIssue}
        disabledRequest={disabledRequest}
        isBuyer={isBuyer}
        loading={loading}
        showBtnDesist={
          formDecisionRef.current.valueRadioDecision ===
            DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY.USE_ANOTHER_MECHANISM ||
          formDecisionRef.current.valueRadioDecision ===
            DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY.STOP_PROCESS
        }
        status={sheet?.status}
        onBack={handlerBack}
        onDesist={handlerDesist}
        onIssue={handlerOpenIssueDialog}
      />
      <DirectDealSummaryIssueDialog
        issue={issue}
        loading={loading}
        providerDni={sheet?.providerDni}
        providerName={sheet?.providerName}
        total={sheet?.totalAmount}
        onClosed={handlerCloseIssuePurchase}
        onIssue={handlerIssuePurchase}
        onRedirect={handlerRedirectStepOnePurchaseSheet}
      />
      <DirectDealSummaryContractDialog
        contracts={contracts}
        formRef={formCreateRequestRef}
        loading={loading}
        provider={provider}
        userDni={userDni}
        onClosed={handlerCloseRequest}
        onSend={handlerCreateRequest}
      />
      <DirectDealSummaryRequestDialog
        contracts={contracts}
        loading={loading}
        requestId={requestContractId}
        onClosed={handlerCloseRequestDetail}
      />
    </Root>
  )
}

export default DirectDealSummaryContainer
