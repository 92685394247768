import React from 'react'
import { Typography } from '@chilecompra/react-kit/components'
import { Column, ErrorDiv, ErrorImage, GridContainer, GridItem, Row } from './SearchResults.styles'

const SearchResultsError = () => {
  return (
    <ErrorDiv>
      <Row alignItems="center">
        <GridContainer>
          <Column>
            <GridItem lg={12} md={12} xs={12}>
              <ErrorImage alt="Arrow" src="/assets/images/Error_Busqueda.svg" />
              <Typography variant="h4" fontWeight="bold" padding="16px 0 0 " color="black1">
                No se encontraron resultados para tu búsqueda
              </Typography>
              <Typography variant="subtitle" fontWeight="regular" padding="0 0 0">
                Intenta con otra palabra clave u otro criterio de búsqueda.
              </Typography>
            </GridItem>
          </Column>
        </GridContainer>
      </Row>
    </ErrorDiv>
  )
}

export default SearchResultsError
