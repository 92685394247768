import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  checkInputErrorMaxLength,
  putCookie,
  useInput,
  useQuery,
  useSelect,
  useEffectOnce
} from '@chilecompra/react-kit'
import { Input, Select, Typography } from '@chilecompra/react-kit/components'
import { DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES } from '../../config/settings/constants'
import { updateQuery } from '../../modules/utils/searches'
import { GridContainer, GridItem, HeadingFiltersForm, SearchButton, Image } from './SearchAndFilterBar.styles'
import {
  onGetCausesSearchThunk,
  onGetDirectDealThunk,
  onGetStatusSearchThunk,
  onSetCauseCriteria
} from './SearchAndFilterBar.actions'

import { useAuthorization } from '../AuthProvider/AuthProvider.hooks'
import useConditionalSearchNavigate from '../../modules/hooks/useCondicionalSearchNavigate'

/**
 * The SearchAndFilterBar's container.
 */
const SearchAndFilterBar = () => {
  const {
    causes: searchCauses,
    state: searchStatus,
    loading: searchLoading
  } = useSelector(state => state.searchFilters)
  const query = useQuery()
  const dispatch = useDispatch()
  const { isBuyer, isSeller } = useAuthorization()
  const { conditionalNavigate } = useConditionalSearchNavigate(isSeller, isBuyer)

  const queryString = query.toString()

  const {
    error: keywordError,
    value: keywordValue,
    onChange: keywordChange
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(50)],
    initialValue: query.has('keyword') ? query.get('keyword') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.keyword,
    overwriteCallback: () =>
      query.has('keyword') ? query.get('keyword') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.keyword
  })
  const {
    error: causeFromError,
    value: cause,
    onChange: handleCauseChange
  } = useSelect({
    initialValue: query.has('cause') ? query.get('cause') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.cause,
    overwriteCallback: () => (query.has('cause') ? query.get('cause') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.cause),
    changeCallback: updatedValue => dispatch(onSetCauseCriteria(updatedValue))
  })
  const {
    error: statusError,
    value: state,
    onChange: handleStatusChange
  } = useSelect({
    initialValue: query.has('state') ? query.get('state') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.state,
    overwriteCallback: () => (query.has('state') ? query.get('state') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.state)
  })

  const handleSearch = updatedValues => event => {
    event.preventDefault()

    const dictionary = {
      ...DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES,
      ...Object.fromEntries(query.entries()),
      keyword: updatedValues.keywordValue,
      cause: updatedValues.cause,
      state: updatedValues.state,
      page: DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.page
    }

    updateQuery(query, dictionary)
    putCookie('search', query.toString())
    conditionalNavigate(query)
  }

  useEffectOnce(() => {
    dispatch(onGetCausesSearchThunk())
    dispatch(onGetStatusSearchThunk())
  })

  useEffect(() => {
    if (!queryString) {
      return
    }

    dispatch(onGetDirectDealThunk({ query }))
  }, [queryString])

  return (
    <HeadingFiltersForm
      onSubmit={handleSearch({
        cause,
        state,
        keywordValue
      })}
    >
      <GridContainer>
        <GridItem xs={12}>
          <Typography variant="h4" fontWeight="bold" padding="0 0 0 0">
            Búsqueda de Trato Directo
          </Typography>
        </GridItem>
      </GridContainer>
      <GridContainer alignItems="center" height="100%" spacing={2}>
        <GridItem lg={4} md={4} xs={12}>
          <Input
            minLength={2}
            maxLength={30}
            error={keywordError}
            label="Palabra clave o número de Trato Directo"
            loading={searchLoading}
            value={keywordValue}
            onChange={keywordChange}
          />
        </GridItem>
        <GridItem lg={2.5} md={2.5} xs={12}>
          <Select
            error={causeFromError}
            label="Causal de Trato Directo"
            loading={searchLoading}
            options={searchCauses}
            value={cause}
            onChange={handleCauseChange}
          />
        </GridItem>
        <GridItem lg={3} md={3} xs={12}>
          <Select
            error={statusError}
            label="Estado"
            placeholder="Todos"
            loading={searchLoading}
            options={searchStatus}
            value={state}
            onChange={handleStatusChange}
          />
        </GridItem>
        <GridItem lg={2.5} md={2.5} xs={12}>
          <SearchButton color="primary" loading={searchLoading} type="submit">
            <Image alt="Submit Button" src="/assets/images/IconSearch.svg" />
          </SearchButton>
        </GridItem>
      </GridContainer>
    </HeadingFiltersForm>
  )
}

export default SearchAndFilterBar
