import {
  CREATE_DIRECT_DEAL_STEP_ZERO,
  CREATE_DIRECT_DEAL_STEP_ZERO_SUCCESS,
  CREATE_DIRECT_DEAL_STEP_ZERO_ERROR,
  GET_INFO_DNI_SIGNER,
  GET_INFO_DNI_SIGNER_SUCCESS,
  GET_INFO_DNI_SIGNER_ERROR
} from './DirectDealStepZero.actions'

const directDealStepZeroState = {
  error: undefined,
  loading: false,
  initialSheet: {},
  signer: null
}

/**
 * The DirectDealStepOne reducer.
 */
const directDealStepZeroReducer = (state = directDealStepZeroState, { payload, type }) => {
  switch (type) {
    case GET_INFO_DNI_SIGNER:
    case CREATE_DIRECT_DEAL_STEP_ZERO: {
      return { ...state, error: undefined, loading: true }
    }
    case GET_INFO_DNI_SIGNER_ERROR:
    case CREATE_DIRECT_DEAL_STEP_ZERO_ERROR: {
      return { ...state, error: payload.error, loading: false, initialSheet: null }
    }
    case CREATE_DIRECT_DEAL_STEP_ZERO_SUCCESS: {
      return { ...state, error: undefined, loading: false, initialSheet: payload }
    }
    case GET_INFO_DNI_SIGNER_SUCCESS: {
      return { ...state, error: undefined, loading: false, signer: payload }
    }
    default: {
      return state
    }
  }
}

export default directDealStepZeroReducer
