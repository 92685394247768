import React from 'react'
import { Typography } from '@chilecompra/react-kit/components'

import { Grid, Link, LogoLink, Logo, Root, SocialMediaContainer, SocialIcons, Icon } from './FooterPublic.styles'

import {
  PUBLIC_URL,
  REACT_APP_CAPACITACION_BASE_URL,
  REACT_APP_CENTROAYUDA_BASE_URL,
  REACT_APP_CHILECOMPRA_BASE_URL,
  REACT_APP_DATOSABIERTOS_CHILECOMPRA_BASE_URL,
  REACT_APP_FACEBOOK_CHILECOMPRA_BASE_URL,
  REACT_APP_INSTAGRAM_CHILECOMPRA_BASE_URL,
  REACT_APP_LINKEDIN_CHILECOMPRA_BASE_URL,
  REACT_APP_MERCADO_PUBLICO_BASE_URL,
  REACT_APP_PUBLIC_CHILECOMPRA_BASE_URL,
  REACT_APP_TWITTER_CHILECOMPRA_BASE_URL,
  REACT_APP_YOUTUBE_CHILECOMPRA_BASE_URL
} from '../../config/settings/environment'

/**
 * The FooterPublic's container.
 */
const FooterPublicContainer = () => {
  return (
    <Root>
      <Grid container spacing={2}>
        <Grid item md={3} sm={12} xs={12}>
          <LogoLink href={`${REACT_APP_MERCADO_PUBLICO_BASE_URL}/Home`} target="_top">
            <Logo alt="Chilecompra" src={`${PUBLIC_URL}/assets/images/direccion_chilecompra.svg`} />
          </LogoLink>
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <Typography fontSize="14px" fontWeight="bold" color="white">
            Dirección ChileCompra
          </Typography>
          <Typography fontSize="12px" color="white">
            Ministerio de Hacienda, Gobierno de Chile
          </Typography>
          <Typography fontSize="12px" color="white">
            Monjitas 392 - Piso 8, Santiago de Chile.
          </Typography>
          <Typography fontSize="12px" color="white">
            <Link href={`${REACT_APP_CHILECOMPRA_BASE_URL}/terminos-y-condiciones-de-uso/`} target="_top">
              Términos y condiciones
            </Link>
          </Typography>
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <Typography fontSize="14px" fontWeight="bold" color="white">
            Sitios relacionados
          </Typography>
          <Typography fontSize="12px" color="white">
            <Link href={REACT_APP_DATOSABIERTOS_CHILECOMPRA_BASE_URL} target="_top">
              Datos Abiertos ChileCompra
            </Link>
          </Typography>
          <Typography fontSize="12px" color="white">
            <Link href={REACT_APP_PUBLIC_CHILECOMPRA_BASE_URL} target="_top">
              API Mercado Público
            </Link>
          </Typography>
          <Typography fontSize="12px" color="white">
            <Link href={REACT_APP_CAPACITACION_BASE_URL} target="_top">
              Capacitación
            </Link>
          </Typography>
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <Typography fontSize="14px" fontWeight="bold" color="white">
            Mesa de ayuda
          </Typography>
          <Typography fontSize="12px" color="white">
            <Link href={REACT_APP_CENTROAYUDA_BASE_URL} target="_top">
              Centro de Ayuda
            </Link>
          </Typography>
          <Typography fontSize="12px" color="white">
            <Link href="tel:6007000600">600 7000 600</Link>
          </Typography>
          <Typography fontSize="12px" color="white">
            <Link href="tel:+56448801003">+56 44 2201003</Link>
          </Typography>
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <Typography fontSize="14px" fontWeight="bold" color="white">
            Síguenos
          </Typography>
          <SocialMediaContainer>
            <SocialIcons>
              <Link href={REACT_APP_FACEBOOK_CHILECOMPRA_BASE_URL} target="_top">
                <Icon src={`${PUBLIC_URL}/assets/images/facebook-icon.svg`} alt="Facebook" />
              </Link>
              <Link href={REACT_APP_TWITTER_CHILECOMPRA_BASE_URL} target="_top">
                <Icon src={`${PUBLIC_URL}/assets/images/x-icon.svg`} alt="Twitter" />
              </Link>
              <Link href={REACT_APP_LINKEDIN_CHILECOMPRA_BASE_URL} target="_top">
                <Icon src={`${PUBLIC_URL}/assets/images/link-icon.svg`} alt="LinkedIn" />
              </Link>
              <Link href={REACT_APP_YOUTUBE_CHILECOMPRA_BASE_URL} target="_top">
                <Icon src={`${PUBLIC_URL}/assets/images/youtube-icon.svg`} alt="YouTube" />
              </Link>
              <Link href={REACT_APP_INSTAGRAM_CHILECOMPRA_BASE_URL} target="_top">
                <Icon src={`${PUBLIC_URL}/assets/images/insta-icon.svg`} alt="Instagram" />
              </Link>
            </SocialIcons>
          </SocialMediaContainer>
        </Grid>
      </Grid>
    </Root>
  )
}

export default FooterPublicContainer
