import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Snackbar, Toast } from '@chilecompra/react-kit/components'
import { SNACKBAR_CLOSE_TIMEOUT } from '../../config/settings/constants'
import { onCloseSnackbar } from './SnackBarProvider.actions'

/**
 * The SnackbarProvider's container.
 */
const SnackbarProvider = () => {
  const dispatch = useDispatch()
  const { message, severity, showSnackbar, title } = useSelector(state => state.snackbarProvider)

  const handleCloseSnackbar = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(onCloseSnackbar())
  }

  useEffect(() => {
    if (showSnackbar) {
      return
    }

    const timeout = setTimeout(() => dispatch(onCloseSnackbar()), SNACKBAR_CLOSE_TIMEOUT)

    return () => {
      clearTimeout(timeout)
    }
  }, [showSnackbar])

  return (
    showSnackbar && (
      <Snackbar open={showSnackbar} onClose={handleCloseSnackbar}>
        <Toast title={title} severity={severity} padding="6px 16px" textDirection="vertical">
          {message}
        </Toast>
      </Snackbar>
    )
  )
}

export default SnackbarProvider
