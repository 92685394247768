import { GET_REGIONS, GET_REGIONS_ERROR, GET_REGIONS_SUCCESS } from './ConfigProvider.actions'

const configState = {
  regionInfo: {
    error: false,
    loading: false,
    regions: []
  }
}

/**
 * Reducer used to obtain and filter data's payload from Regions.
 */

const configProviderReducer = (state = configState, { payload, type }) => {
  switch (type) {
    case GET_REGIONS: {
      return { ...state, regionInfo: { error: false, loading: true } }
    }
    case GET_REGIONS_ERROR: {
      return { ...state, regionInfo: { error: true, loading: false } }
    }
    case GET_REGIONS_SUCCESS: {
      return { ...state, regionInfo: { ...state.regionInfo, error: false, loading: false, regions: payload.regions } }
    }
    default: {
      return state
    }
  }
}

export default configProviderReducer
