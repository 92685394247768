import {
  ADD_PRODUCT_SUCCESS,
  GET_PRODUCTS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCTS_SUCCESS,
  REMOVE_ALL_SUCCESS,
  REMOVE_PRODUCT_SUCCESS,
  UPDATE_PRODUCTS_SAVED_SUCCESS
} from './ProductSearch.actions'

const productsState = {
  products: [],
  productsSaved: [],
  loading: false,
  error: false
}

/**
 * The ProductSearch's reducer.
 */
const productSearchReducer = (state = productsState, { payload, type }) => {
  switch (type) {
    case GET_PRODUCTS: {
      return { ...state, loading: true, error: false }
    }
    case GET_PRODUCTS_ERROR: {
      return { ...state, loading: false, error: payload.error }
    }
    case GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: payload.products,
        loading: false
      }
    }
    case ADD_PRODUCT_SUCCESS: {
      return {
        ...state,
        productsSaved: [...state.productsSaved, payload.newProduct]
      }
    }
    case REMOVE_PRODUCT_SUCCESS:
      return {
        ...state,
        productsSaved: state.productsSaved.filter(product => product.productKey !== payload.productKey)
      }
    case UPDATE_PRODUCTS_SAVED_SUCCESS:
      return {
        ...state,
        productsSaved: payload.products
      }
    case REMOVE_ALL_SUCCESS:
      return {
        ...state,
        productsSaved: []
      }
    default: {
      return state
    }
  }
}

export default productSearchReducer
