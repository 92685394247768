import React from 'react'
import PropTypes from 'prop-types'

/**
 * The FormattedPhone's component.
 */
const FormattedPhoneComponent = props => {
  const { phone } = props
  if (phone) {
    const countryCode = `+${phone.slice(0, 2)}`
    const areaCode = phone.slice(2, 3)
    const slice1 = phone.slice(3, 7)
    const slice2 = phone.slice(7)

    return (
      <>
        {countryCode} {areaCode} {slice1} {slice2}
      </>
    )
  } else {
    return <></>
  }
}

FormattedPhoneComponent.propTypes = {
  phone: PropTypes.string
}

export default FormattedPhoneComponent
