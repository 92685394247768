import React, { useMemo } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getCookie, useEffectOnce, useQuery } from '@chilecompra/react-kit'

import DirectDealBasicBackground from './DirectDealStepOne.basicbackground'
import DirectDealRequester from './DirectDealStepOne.requester'
import DirectDealStepOneDialog from './DirectDealStepOne.dialog'
import DirectDealStepOneFooter from './DirectDealStepOne.footer'
import {
  onCreateDirectDealThunk,
  onCloseConfirmStepOneDialog,
  onGetDescriptionCausesStepOneThunk,
  onGetUTMStepOneThunk,
  onResetStepOne
} from './DirectDealStepOne.actions'
import { onSavedInfoStepOneDirectDealStepTwo } from '../DirectDealStepTwo/DirectDealStepTwo.actions'

import { Root, Divider } from './DirectDealStepOne.styles'

import routes from '../../config/settings/routes'
import { useFormRef } from '../../modules/hooks/useFormRef'
import { DIRECT_DEAL_TYPE_CAUSES_WITH_PUBLICITY } from '../../config/settings/constants'
import { dialogScrollTop } from '../../modules/utils/dialogScrollTop'

/**
 * The DirectDealStepOne's container.
 */
const DirectDealStepOneContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const formStepOneRef = useFormRef()
  const query = useQuery()

  const {
    loading: directDealStepOneLoading,
    info: requesterInfo,
    utm: directDealStepOneUTM,
    isShowConfirmDialog,
    sheetCode: directDealStepOneSheetCode,
    causes: directDealStepOneCauses
  } = useSelector(state => state.directDealStepOne)

  const initialSheetStepZero = useSelector(state => state.directDealStepZero.initialSheet.initialSheet)

  const handlerBack = () => {
    const query = getCookie('search')
    navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
  }

  const handlerNext = () => {
    dispatch(
      onCreateDirectDealThunk({
        stepOne: {
          name: formStepOneRef.current.nameValueInput,
          cause: directDealStepOneCauses?.find(cause => cause.value === formStepOneRef.current.causeValue) || null,
          amount: formStepOneRef.current.valueRadioAmount === '1',
          dni: formStepOneRef.current.dniValueInput,
          lastName: formStepOneRef.current.lastNameValueInput,
          job: formStepOneRef.current.jobValueInput,
          email: formStepOneRef.current.emailValueInput,
          withPublicity: DIRECT_DEAL_TYPE_CAUSES_WITH_PUBLICITY.some(
            item => item.id === formStepOneRef.current.causeValue
          )
        },
        stepZero: {
          id: '',
          resolution: initialSheetStepZero?.resolution || '',
          resolutionDate: initialSheetStepZero?.resolutionDate || '',
          names: initialSheetStepZero?.names || '',
          lastNames: initialSheetStepZero?.lastNames || '',
          dni: initialSheetStepZero?.dni || '',
          job: initialSheetStepZero?.job || ''
        },
        callback: sheet => {
          dispatch(onSavedInfoStepOneDirectDealStepTwo(sheet))
          dialogScrollTop()
        }
      })
    )
  }
  const handlerRedirectStepTwo = () => {
    query.set('code', directDealStepOneSheetCode)
    navigate({ pathname: routes.directDealStepTwo, search: query.toString() }, { replace: true })
  }

  const disableNext = useMemo(() => {
    return (
      formStepOneRef.current.nameValueInput === '' ||
      formStepOneRef.current.nameError !== '' ||
      formStepOneRef.current.causeValue === '' ||
      formStepOneRef.current.causeError !== '' ||
      formStepOneRef.current.valueRadioAmount === '' ||
      formStepOneRef.current.dniValueInput === '' ||
      formStepOneRef.current.dniError !== '' ||
      formStepOneRef.current.lastNameValueInput === '' ||
      formStepOneRef.current.lastNameError !== '' ||
      formStepOneRef.current.jobValueInput === '' ||
      formStepOneRef.current.jobError !== '' ||
      formStepOneRef.current.emailValueInput === '' ||
      formStepOneRef.current.emailError !== ''
    )
  }, [
    formStepOneRef.current.nameValueInput,
    formStepOneRef.current.nameError,
    formStepOneRef.current.causeValue,
    formStepOneRef.current.causeError,
    formStepOneRef.current.valueRadioAmount,
    formStepOneRef.current.dniValueInput,
    formStepOneRef.current.dniError,
    formStepOneRef.current.lastNameValueInput,
    formStepOneRef.current.lastNameError,
    formStepOneRef.current.jobValueInput,
    formStepOneRef.current.jobError,
    formStepOneRef.current.emailValueInput,
    formStepOneRef.current.emailError
  ])

  useEffectOnce(() => {
    batch(() => {
      dispatch(onResetStepOne())
      dispatch(onCloseConfirmStepOneDialog())
      dispatch(onGetDescriptionCausesStepOneThunk())
      dispatch(onGetUTMStepOneThunk())
    })
  })

  return (
    <Root>
      <Divider />
      <DirectDealBasicBackground
        causes={directDealStepOneCauses}
        formRef={formStepOneRef}
        loading={directDealStepOneLoading}
        utm={directDealStepOneUTM}
      />
      <Divider />
      <DirectDealRequester
        formRef={formStepOneRef}
        loading={directDealStepOneLoading}
        requesterInfo={requesterInfo}
        valueRadioAmount={formStepOneRef.current.valueRadioAmount}
        isRequiringMoreDocuments={formStepOneRef.current.isRequiringMoreDocuments}
      />
      <Divider />
      <DirectDealStepOneFooter onBack={handlerBack} onContinue={handlerNext} disabledContinue={disableNext} />
      <DirectDealStepOneDialog
        open={isShowConfirmDialog}
        sheetCode={directDealStepOneSheetCode}
        onBack={handlerBack}
        onNext={handlerRedirectStepTwo}
      />
    </Root>
  )
}

export default DirectDealStepOneContainer
