import {
  CLEAN_DIRECT_DEAL_VOUCHER,
  CLOSE_CONFIRM_ISSUE_OC_DIRECT_DEAL_VOUCHER,
  OPEN_CONFIRM_ISSUE_OC_DIRECT_DEAL_VOUCHER,
  SAVED_INFO_DIRECT_DEAL_VOUCHER
} from './DirectDealVoucher.actions'

const directDealVoucherState = {
  voucher: null,
  dialogs: { openConfirmIssue: false }
}

/**
 * The DirectDealStepTwoReducer reducer.
 */
const directDealVoucherReducer = (state = directDealVoucherState, { payload, type }) => {
  switch (type) {
    case SAVED_INFO_DIRECT_DEAL_VOUCHER: {
      return { ...state, voucher: payload.voucher }
    }
    case OPEN_CONFIRM_ISSUE_OC_DIRECT_DEAL_VOUCHER: {
      return { ...state, dialogs: { ...state.dialogs, openConfirmIssue: true } }
    }
    case CLOSE_CONFIRM_ISSUE_OC_DIRECT_DEAL_VOUCHER: {
      return { ...state, dialogs: { ...state.dialogs, openConfirmIssue: false } }
    }
    case CLEAN_DIRECT_DEAL_VOUCHER: {
      return { ...state, voucher: null }
    }
    default: {
      return state
    }
  }
}

export default directDealVoucherReducer
