import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCookie } from '@chilecompra/react-kit'
import routes from '../../config/settings/routes'
import { useNavigate } from 'react-router-dom'
import { Root, Divider } from './DirectDealStepZero.styles'
import DirectDealOrigin from './DirectDealStepZero.origin'
import DirectDealStepZeroFooter from './DirectDealStepZero.footer'
import { useFormRef } from '../../modules/hooks/useFormRef'
import { onSavedInfoStepZeroDirectDealStepOne } from '../DirectDealStepOne/DirectDealStepOne.actions'
import { onCreateDirectDealStepZeroThunk } from './DirectDealStepZero.actions'

/**
 * The DirectDealStepOne's container.
 */
const DirectDealStepZeroContainer = () => {
  const { loading: directDealStepZeroLoading, signer: requesterInfo } = useSelector(state => state.directDealStepZero)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formStepZeroRef = useFormRef()
  const [isFormValid, setIsFormValid] = useState(false)

  const disableSave = useMemo(() => {
    return (
      formStepZeroRef.current.resolutionNumberError !== '' ||
      formStepZeroRef.current.resolutionNumberValueInput === '' ||
      formStepZeroRef.current.endDateExecuteError !== '' ||
      formStepZeroRef.current.endDateExecuteValue === '' ||
      formStepZeroRef.current.signerError !== '' ||
      formStepZeroRef.current.signerValueInput === '' ||
      formStepZeroRef.current.nameError !== '' ||
      formStepZeroRef.current.nameValueInput === '' ||
      formStepZeroRef.current.lastNameError !== '' ||
      formStepZeroRef.current.lastNameValueInput === '' ||
      formStepZeroRef.current.chargeError !== '' ||
      formStepZeroRef.current.chargeValueInput === ''
    )
  }, [
    formStepZeroRef.current.resolutionNumberError,
    formStepZeroRef.current.resolutionNumberValueInput,
    formStepZeroRef.current.endDateExecuteError,
    formStepZeroRef.current.endDateExecuteValue,
    formStepZeroRef.current.signerError,
    formStepZeroRef.current.signerValueInput,
    formStepZeroRef.current.nameError,
    formStepZeroRef.current.nameValueInput,
    formStepZeroRef.current.lastNameError,
    formStepZeroRef.current.lastNameValueInput,
    formStepZeroRef.current.chargeError,
    formStepZeroRef.current.chargeValueInput
  ])

  const handlerBack = () => {
    const query = getCookie('search')
    navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
  }

  const handlerNext = () => {
    dispatch(
      onCreateDirectDealStepZeroThunk({
        stepZero: {
          id: '',
          resolution: formStepZeroRef.current.resolutionNumberValueInput,
          resolutionDate: formStepZeroRef.current.endDateExecuteValue,
          names: formStepZeroRef.current.nameValueInput,
          lastNames: formStepZeroRef.current.lastNameValueInput,
          dni: formStepZeroRef.current.signerValueInput,
          job: formStepZeroRef.current.chargeValueInput
        },
        callback: initialSheet => dispatch(onSavedInfoStepZeroDirectDealStepOne(initialSheet))
      })
    )
    handlerRedirectStepOne()
  }

  const handlerRedirectStepOne = () => {
    navigate({ pathname: routes.directDealStepOne }, { replace: true })
  }

  const handleFormValidationChange = isValid => {
    setIsFormValid(isValid)
  }

  return (
    <Root>
      <Divider />
      <DirectDealOrigin
        onFormValidChange={handleFormValidationChange}
        requesterInfo={requesterInfo}
        loading={directDealStepZeroLoading}
        formRef={formStepZeroRef}
        disableSave={disableSave}
      />
      <Divider />
      <DirectDealStepZeroFooter
        onBack={handlerBack}
        onContinue={handlerNext}
        disableSave={disableSave}
        radioSelected={formStepZeroRef.current.selectedRadioOption}
        disabled={!isFormValid}
      />
    </Root>
  )
}

export default DirectDealStepZeroContainer
