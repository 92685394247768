import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Card, Typography } from '@chilecompra/react-kit'

import FormattedCurrency from './../../../components/components/FormattedCurrency.component'

import { GridContainer, GridItem } from '../DirectDealStepTwo.styles'

import { useImperativeFormRef } from '../../../modules/hooks/useFormRef'
import { formatCurrencyToNumber } from '../../../modules/utils/formatters'

/**
 * The DirectDealStepTwo totals container.
 */
const DirectDealStepTwoTotals = props => {
  const { currency, formStepTwoRef, productsUpdate } = props
  const [netTotal, setNetTotal] = useState(0)

  const calculateProductsTotalNet = productsUpdate => {
    const total = Object.keys(productsUpdate).reduce((acc, product) => {
      const amount = parseInt(productsUpdate[product].amount) || 0
      const unit = formatCurrencyToNumber(productsUpdate[product].unitPrice || '0', currency?.type)

      let net = amount * unit

      if (productsUpdate[product].discountOrCharge === '1') {
        const discount = formatCurrencyToNumber(productsUpdate[product].discount || '0', currency?.type)
        net = net - discount
      } else if (productsUpdate[product].discountOrCharge === '2') {
        const charge = formatCurrencyToNumber(productsUpdate[product].charge || '0', currency?.type)
        net = net + charge
      }
      return acc + net
    }, 0)

    setNetTotal(total)
  }

  useEffect(() => {
    calculateProductsTotalNet(productsUpdate)
  }, [productsUpdate])

  useImperativeFormRef(
    formStepTwoRef,
    () => ({
      netTotal
    }),
    [netTotal]
  )

  return (
    <Card padding="24px" margin="56px 0 0">
      <GridContainer>
        <GridItem xs={12}>
          <Typography variant="subtitle" color="black1">
            Total Neto
          </Typography>
          <Typography variant="subtitle" fontSize="10px" color="grey3">
            con descuento o cargo
          </Typography>
          <Typography variant="body1" color="black1">
            <FormattedCurrency amount={netTotal} includeSymbol currency={currency?.type} />
          </Typography>
        </GridItem>
      </GridContainer>
    </Card>
  )
}

DirectDealStepTwoTotals.propTypes = {
  currency: PropTypes.shape({
    value: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    symbol: PropTypes.string
  }),
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  productsUpdate: PropTypes.objectOf(
    PropTypes.shape({
      amount: PropTypes.number,
      unitPrice: PropTypes.number,
      discount: PropTypes.number,
      charge: PropTypes.number,
      discountOrCharge: PropTypes.string
    })
  )
}

export default DirectDealStepTwoTotals
