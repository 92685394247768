import {
  APPLY_DECISION_DIRECT_DEAL_SUMMARY_ERROR,
  APPLY_DECISION_DIRECT_DEAL_SUMMARY_SUCCESS,
  APPLY_DECISION_DIRECT_DEAL_SUMMARY,
  CLEAN_SHEET_DIRECT_DEAL_SUMMARY,
  CLOSE_CONTRACT_DETAIL_DIALOG_DIRECT_DEAL_SUMMARY,
  CLOSE_CONTRACT_DIALOG_DIRECT_DEAL_SUMMARY,
  CLOSE_ISSUE_DIALOG_DIRECT_DEAL_SUMMARY,
  CREATE_CONTRACT_DIRECT_DEAL_SUMMARY_ERROR,
  CREATE_CONTRACT_DIRECT_DEAL_SUMMARY_SUCCESS,
  CREATE_CONTRACT_DIRECT_DEAL_SUMMARY,
  GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY_ERROR,
  GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY_SUCCESS,
  GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY,
  GET_DETAIL_DIRECT_DEAL_SUMMARY_ERROR,
  GET_DETAIL_DIRECT_DEAL_SUMMARY_SUCCESS,
  GET_DETAIL_DIRECT_DEAL_SUMMARY,
  GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY_ERROR,
  GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY_SUCCESS,
  GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY,
  GET_QUOTES_DIRECT_DEAL_SUMMARY_ERROR,
  GET_QUOTES_DIRECT_DEAL_SUMMARY_SUCCESS,
  GET_QUOTES_DIRECT_DEAL_SUMMARY,
  ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY_ERROR,
  ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY_SUCCESS,
  ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY,
  OPEN_CONTRACT_DETAIL_DIALOG_DIRECT_DEAL_SUMMARY,
  OPEN_CONTRACT_DIALOG_DIRECT_DEAL_SUMMARY,
  OPEN_ISSUE_DIALOG_DIRECT_DEAL_SUMMARY,
  PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY_ERROR,
  PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY_SUCCESS,
  PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY
} from './DirectDealSummary.actions'

import {
  DIRECT_DEAL_SHEET_STATUS_DICTIONARY,
  DIRECT_DEAL_SUMMARY_ISSUE_PURCHASE_STEP_DICTIONARY
} from '../../config/settings/constants'

const directDealSummaryState = {
  contracts: {
    requestTotal: 0,
    requestClosedDate: undefined,
    requests: [],
    openForm: false,
    step: 'FORM',
    openDetail: false,
    sheetIdRequesting: undefined
  },
  error: undefined,
  loading: false,
  provider: undefined,
  sheet: undefined,
  quotes: [],
  issue: {
    open: false,
    step: DIRECT_DEAL_SUMMARY_ISSUE_PURCHASE_STEP_DICTIONARY.CONFIRMATION,
    purchaseCode: undefined,
    purchaseId: undefined
  }
}

/**
 * The DirectDealStepOne reducer.
 */
const directDealSummaryReducer = (state = directDealSummaryState, { payload, type }) => {
  switch (type) {
    case CLEAN_SHEET_DIRECT_DEAL_SUMMARY: {
      return {
        ...state,
        contracts: {
          requestTotal: 0,
          requestClosedDate: undefined,
          requests: [],
          openForm: false,
          step: 'FORM',
          openDetail: false
        },
        provider: undefined,
        sheet: undefined,
        quotes: []
      }
    }
    case APPLY_DECISION_DIRECT_DEAL_SUMMARY:
    case CREATE_CONTRACT_DIRECT_DEAL_SUMMARY:
    case GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY:
    case GET_DETAIL_DIRECT_DEAL_SUMMARY:
    case GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY:
    case GET_QUOTES_DIRECT_DEAL_SUMMARY:
    case ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY:
    case PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY: {
      return { ...state, error: undefined, loading: true }
    }
    case APPLY_DECISION_DIRECT_DEAL_SUMMARY_ERROR:
    case CREATE_CONTRACT_DIRECT_DEAL_SUMMARY_ERROR:
    case GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY_ERROR:
    case GET_DETAIL_DIRECT_DEAL_SUMMARY_ERROR:
    case GET_QUOTES_DIRECT_DEAL_SUMMARY_ERROR:
    case PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY_ERROR: {
      return { ...state, error: payload.error, loading: false }
    }
    case GET_DETAIL_DIRECT_DEAL_SUMMARY_SUCCESS: {
      return { ...state, error: undefined, loading: false, sheet: { ...payload.sheet } }
    }
    case CREATE_CONTRACT_DIRECT_DEAL_SUMMARY_SUCCESS: {
      return {
        ...state,
        error: undefined,
        loading: false,
        contracts: {
          ...state.contracts,
          requestTotal: state.contracts.requestTotal,
          requests: state.contracts.requests.map(request =>
            request.id === payload.request.id
              ? {
                  ...request,
                  isActive: payload.request.isActive,
                  answer: payload.request.answer,
                  dni: payload.request.dni,
                  legalName: payload.request.legalName,
                  num: state.contracts.requests.length,
                  date: payload.request.date
                }
              : request
          ),
          step: 'SUCCESS'
        }
      }
    }

    case GET_QUOTES_DIRECT_DEAL_SUMMARY_SUCCESS: {
      return { ...state, error: undefined, loading: false, quotes: [...payload.quotes] }
    }
    case GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY_SUCCESS: {
      return { ...state, error: undefined, loading: false, provider: { ...payload.provider } }
    }
    case GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY_ERROR: {
      return { ...state, error: undefined, loading: false, provider: undefined }
    }
    case GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY_SUCCESS: {
      return { ...state, error: undefined, loading: false, contracts: { ...payload.contracts } }
    }
    case OPEN_ISSUE_DIALOG_DIRECT_DEAL_SUMMARY: {
      return {
        ...state,
        issue: { ...state.issue, open: true, step: DIRECT_DEAL_SUMMARY_ISSUE_PURCHASE_STEP_DICTIONARY.CONFIRMATION }
      }
    }
    case CLOSE_ISSUE_DIALOG_DIRECT_DEAL_SUMMARY: {
      return { ...state, issue: { ...state.issue, open: false, step: undefined } }
    }
    case ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        issue: {
          ...state.issue,
          step: DIRECT_DEAL_SUMMARY_ISSUE_PURCHASE_STEP_DICTIONARY.SUCCESS,
          purchaseCode: payload.code,
          purchaseId: payload.id
        },
        sheet: {
          ...state.sheet,
          status: DIRECT_DEAL_SHEET_STATUS_DICTIONARY.ISSUE_PURCHASE,
          purchases: [...state.sheet.purchases, { ...payload }]
        }
      }
    }
    case ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.err,
        issue: { ...state.issue, step: DIRECT_DEAL_SUMMARY_ISSUE_PURCHASE_STEP_DICTIONARY.FAILURE }
      }
    }
    case OPEN_CONTRACT_DIALOG_DIRECT_DEAL_SUMMARY: {
      return {
        ...state,
        contracts: {
          ...state.contracts,
          openForm: true,
          step: 'FORM'
        }
      }
    }
    case CLOSE_CONTRACT_DIALOG_DIRECT_DEAL_SUMMARY: {
      return {
        ...state,
        contracts: {
          ...state.contracts,
          openForm: false
        }
      }
    }
    case OPEN_CONTRACT_DETAIL_DIALOG_DIRECT_DEAL_SUMMARY: {
      return {
        ...state,
        contracts: {
          ...state.contracts,
          openDetail: true
        }
      }
    }
    case CLOSE_CONTRACT_DETAIL_DIALOG_DIRECT_DEAL_SUMMARY: {
      return {
        ...state,
        contracts: {
          ...state.contracts,
          openDetail: false
        }
      }
    }
    case APPLY_DECISION_DIRECT_DEAL_SUMMARY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        sheet: {
          ...state.sheet,
          status: DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CANCEL
        }
      }
    }
    case PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY_SUCCESS: {
      return {
        ...state,
        loading: false,
        contracts: {
          ...state.contracts,
          requestTotal: state.contracts.requestTotal + 1,
          requests: [...state.contracts.requests, payload.request],
          sheetIdRequesting: payload.request.id
        }
      }
    }

    default: {
      return state
    }
  }
}

export default directDealSummaryReducer
