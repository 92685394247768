import React from 'react'
import PropTypes from 'prop-types'

import { Skeleton, Tag, Typography } from '@chilecompra/react-kit/components'
import { useViewport } from '@chilecompra/react-kit'

import { Grid } from './DirectDealSummary.styles'

import { DIRECT_DEAL_SHEET_STATUS_DICTIONARY } from '../../config/settings/constants'

/**
 * The DirectDealSummary's header.
 */
const DirectDealSummaryHeader = props => {
  const { sheetCode, status, loading } = props
  const { size } = useViewport()

  return (
    <Grid container padding="0 24px 7px">
      <Grid container alignItems="center">
        {loading && <Skeleton width="70%" />}
        {!loading && (
          <Typography
            color="black1"
            display="inline"
            fontSize="27px"
            fontWeight="bold"
            lineHeight="31px"
            margin="0"
            variant="h2"
          >
            Detalle del Trato Directo {sheetCode}
          </Typography>
        )}
        {!loading && status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.PUBLISH && (
          <Tag color="success" text="Publicado" margin={!size.isDownToTiny && '0 0 0 24px'} />
        )}
        {!loading && status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.RECEIVING_REQUEST && (
          <Tag color="warning" text="Recibiendo solicitud" margin={!size.isDownToTiny && '0 0 0 24px'} />
        )}
        {!loading && status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED && (
          <Tag color="default" text="Cerrada" margin={!size.isDownToTiny && '0 0 0 24px'} />
        )}
        {!loading && status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.REMOVE && (
          <Tag color="error" text="Eliminada" margin={!size.isDownToTiny && '0 0 0 24px'} />
        )}
        {!loading && status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.ISSUE_PURCHASE && (
          <Tag color="primary" text="Orden de compra emitida" margin={!size.isDownToTiny && '0 0 0 24px'} />
        )}
        {!loading && status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CANCEL && (
          <Tag color="error" text="Cancelada" margin={!size.isDownToTiny && '0 0 0 24px'} />
        )}
      </Grid>
    </Grid>
  )
}

DirectDealSummaryHeader.propTypes = {
  sheetCode: PropTypes.string,
  status: PropTypes.string,
  loading: PropTypes.bool
}

export default DirectDealSummaryHeader
