import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { batch, useDispatch } from 'react-redux'

import { AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Button, Card, Skeleton, Typography, useQuery } from '@chilecompra/react-kit'

import DirectDealStepTwoProduct from './DirectDealStepTwo.product'
import DirectDealStepTwoTotals from './DirectDealStepTwo.totals'

import { onUpdateProductsSavedSuccess, onRemoveProductSuccess } from '../../ProductSearch/ProductSearch.actions'
import { onShowSnackbar } from '../../SnackBarProvider/SnackBarProvider.actions'

import { Accordion, FileRequestImage, GridContainer, GridItem, SpanTitleAccordion } from '../DirectDealStepTwo.styles'

import routes from '../../../config/settings/routes'
import { dialogScrollTop } from '../../../modules/utils/dialogScrollTop'

/**
 * The DirectDealStepTwo products container.
 */
const DirectDealStepTwoProducts = props => {
  const {
    code,
    currency,
    formStepTwoRef,
    loading,
    productsSaved,
    productsUpdate,
    setProductsUpdate,
    setProductsValid,
    unitMeasurement,
    onStorage
  } = props

  const navigate = useNavigate()
  const query = useQuery()
  const dispatch = useDispatch()

  const [accordionsExpanded, setAccordionsExpanded] = useState(false)

  const handlerRedirectProductSearch = () => {
    onStorage()

    if (currency) {
      query.set('code', code)
      navigate({ pathname: routes.productSearch, search: query.toString() }, { replace: true })
    } else {
      dispatch(
        onShowSnackbar({
          title: 'Debes seleccionar una moneda primero',
          severity: 'warning'
        })
      )
      dialogScrollTop()
    }
  }

  const handlerRemoveProduct = productKey => {
    const updatedProducts = Object.values(productsUpdate)
      .filter(product => product.productKey !== productKey)
      .reduce((dictionary, product) => ({ ...dictionary, [product.productKey]: product }), {})

    setProductsUpdate(updatedProducts)

    const products = Object.values(productsUpdate)

    batch(() => {
      dispatch(onUpdateProductsSavedSuccess({ products }))
      dispatch(onRemoveProductSuccess({ productKey }))
      dispatch(
        onShowSnackbar({
          title: 'Producto eliminado',
          severity: 'success'
        })
      )
    })
  }

  const handleUpdateProductsSaved = () => {
    const products = Object.values(productsUpdate)

    batch(() => {
      dispatch(onUpdateProductsSavedSuccess({ products }))
      dispatch(
        onShowSnackbar({
          title: 'Producto guardado',
          severity: 'success'
        })
      )
    })
    setAccordionsExpanded(false)
  }

  const handlerExpandedAccordions = panel => (event, isExpanded) => {
    event.preventDefault()
    setAccordionsExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    const isProductsInvalid = productsSaved.some(product => product.isInvalid)

    setProductsValid(productsSaved.length > 0 && !isProductsInvalid && formStepTwoRef.current.netTotal > 0)
    setProductsUpdate(() =>
      productsSaved.reduce((dictionary, product) => ({ ...dictionary, [product.productKey]: product }), {})
    )
  }, [productsSaved])

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem lg={2} md={2} sm={12} xs={12}>
        <Typography variant="body1" fontWeight="bold">
          Productos y servicios a solicitar
        </Typography>
        <Typography variant="body2">
          Los productos que ingreses deben coincidir con los que ingresarás al realizar la orden de compra.
        </Typography>
      </GridItem>

      {!loading && productsSaved.length <= 0 && (
        <GridItem lg={8} md={8} sm={12} xs={12}>
          <Card enableBorder lineColor="primary" color="blank" padding="78px 0">
            <GridContainer justifyContent="center" alignItems="center" spacing={2}>
              <GridItem xs={12} display="flex" justifyContent="center" alignItems="center">
                <FileRequestImage alt="Lupa buscando en los servidores" src="/assets/images/Error_Busqueda.svg" />
              </GridItem>
              <GridItem xs={12} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body2" fontWeight="regular" padding="16px 0 0" color="gray1">
                  No tienes productos o servicios agregados
                </Typography>
              </GridItem>
              <GridItem xs={12} display="flex" justifyContent="center" alignItems="center">
                <Button variant="outlined" color="primary" onClick={handlerRedirectProductSearch}>
                  Agregar productos
                </Button>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      )}

      {!loading && productsSaved.length > 0 && (
        <>
          <GridItem lg={8} md={8} sm={12} xs={12}>
            <GridContainer spacing={2}>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <Button variant="outlined" color="primary" onClick={handlerRedirectProductSearch}>
                  Agregar más productos o servicios
                </Button>
              </GridItem>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                {productsSaved.map((product, index) => (
                  <Accordion
                    key={product.productId}
                    expanded={accordionsExpanded === index}
                    onChange={handlerExpandedAccordions(index)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="small" color="primary" />}>
                      <Typography variant="body2" fontWeight="bold">
                        {product.productName} <SpanTitleAccordion>Cód: {product.productId}</SpanTitleAccordion>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DirectDealStepTwoProduct
                        amount={product.amount}
                        categoryId={product.categoryId}
                        charge={product.charge}
                        codeZGEN={product.codeZGEN}
                        currency={currency}
                        discount={product.discount}
                        detail={product.detail}
                        dontKnowCodeZGEN={product.dontKnowCodeZGEN}
                        productKey={product.productKey}
                        productId={product.productId}
                        productName={product.productName}
                        productsUpdate={productsUpdate}
                        setProductsUpdate={setProductsUpdate}
                        unitMeasure={product.unitMeasure}
                        unitPrice={product.unitPrice}
                        unitMeasurement={unitMeasurement}
                        withDiscountOrCharge={product.discountOrCharge}
                        onRemove={handlerRemoveProduct}
                        onSave={handleUpdateProductsSaved}
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem lg={2} md={2} sm={12} xs={12}>
            <DirectDealStepTwoTotals
              currency={currency}
              formStepTwoRef={formStepTwoRef}
              productsUpdate={productsUpdate}
            />
          </GridItem>
        </>
      )}

      {loading && (
        <GridItem lg={8} md={8} sm={12} xs={12}>
          <Skeleton />
        </GridItem>
      )}
    </GridContainer>
  )
}

DirectDealStepTwoProducts.propTypes = {
  code: PropTypes.string,
  currency: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    symbol: PropTypes.string
  }),
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  loading: PropTypes.bool,
  productsSaved: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number,
      detail: PropTypes.string,
      productId: PropTypes.number,
      productKey: PropTypes.string,
      productName: PropTypes.string,
      amount: PropTypes.number,
      unitMeasure: PropTypes.string,
      unitPrice: PropTypes.number,
      discount: PropTypes.number,
      charge: PropTypes.number,
      codeZGEN: PropTypes.string,
      discountOrCharge: PropTypes.string
    })
  ),
  productsUpdate: PropTypes.objectOf(
    PropTypes.shape({
      categoryId: PropTypes.number,
      detail: PropTypes.string,
      productId: PropTypes.number,
      productKey: PropTypes.string,
      productName: PropTypes.string,
      amount: PropTypes.number,
      unitMeasure: PropTypes.string,
      unitPrice: PropTypes.number,
      discount: PropTypes.number,
      charge: PropTypes.number,
      codeZGEN: PropTypes.string,
      discountOrCharge: PropTypes.string
    })
  ),
  setProductsUpdate: PropTypes.func,
  setProductsValid: PropTypes.func,
  unitMeasurement: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number
    })
  ),
  onStorage: PropTypes.func
}

export default DirectDealStepTwoProducts
