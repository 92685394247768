import React from 'react'
import PropTypes from 'prop-types'

import { Card, Typography } from '@chilecompra/react-kit/components'
import { Skeleton } from '@chilecompra/react-kit'

import { Divider, Grid, IconArrowForward } from './DirectDealSummary.styles'
import DirectDealSummaryPurchases from './DirectDealSummary.purchases'

/**
 * The DirectDealSummary's info decision.
 */
const DirectDealSummaryInfoDecision = props => {
  const { contracts, loading, sheet, onRedirect } = props

  const handlerRedirectPurchaseSheet = () => onRedirect()

  return (
    <Grid container spacing={3} margin="0">
      <Grid item md={8} xs={12}>
        <Card enableBorder lineColor="primary" color="blank" padding="32px 24px">
          <Grid container>
            <Grid item lg={10} md={8} sm={7} xs={12}>
              <Typography fontWeight="bold" variant="h3" margin="0 0 16px">
                Decisión del Trato Directo
              </Typography>
            </Grid>
            {sheet?.purchases && sheet?.purchases.length > 0 && (
              <>
                <Grid item lg={12}>
                  {loading && <Skeleton width="60%" />}
                  {!loading && contracts?.requestTotal > 0 && (
                    <>
                      <Typography variant="body2">
                        El Trato Directo recibió respuestas y se emitió una orden de compra.
                      </Typography>
                      <Divider margin="24px 0" />
                      <Grid item>
                        <Typography fontWeight="bold" variant="h4" margin="0 0 16px">
                          Respuestas recibidas
                        </Typography>
                        {contracts?.requests.map(contract => (
                          <Grid container spacing={1} key={contract?.id}>
                            <Grid item lg={5} md={5} sm={12} xs={12}>
                              <Typography fontWeight="bold" lineHeight="18px" fontSize="14px">
                                Usuario proveedor
                              </Typography>
                              <Typography lineHeight="18px" fontSize="14px" color="black3">
                                {contract?.dni}
                              </Typography>
                            </Grid>
                            <Grid item lg={7} md={7} sm={12} xs={12}>
                              <Grid container>
                                <Grid item lg={2} md={2} sm={1} xs={1}>
                                  <IconArrowForward fontSize="24px" />
                                </Grid>
                                <Grid item lg={10} md={10} sm={11} xs={11}>
                                  <Typography variant="body2" lineHeight="18px">
                                    {contract?.answer}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )}
                  {!loading && contracts?.requestTotal <= 0 && (
                    <Typography variant="body2">
                      El Trato Directo no recibió solicitudes y se emitió una orden de compra.
                    </Typography>
                  )}
                  <Divider margin="24px 0" />
                </Grid>
                <Grid item>
                  <Typography fontWeight="bold" variant="h4" margin="0 0 16px">
                    Justificación de emisión de orden de compra
                  </Typography>
                  {!loading && <Typography variant="body2">{sheet?.justification}</Typography>}
                  {loading && <Skeleton width="60%" />}
                </Grid>
                <Grid item lg={12}>
                  <Divider margin="24px 0" />
                </Grid>
                <DirectDealSummaryPurchases
                  purchases={sheet?.purchases}
                  loading={loading}
                  onRedirect={handlerRedirectPurchaseSheet}
                />
              </>
            )}

            {sheet?.purchases.length <= 0 && (
              <>
                {sheet?.purchaseMechanism && (
                  <>
                    <Grid item lg={12}>
                      {loading && <Skeleton width="60%" />}
                      {!loading && (
                        <Typography variant="body2">
                          El Trato Directo no recibió solicitudes pero se decide utilizar otro mecanismo de compra.
                        </Typography>
                      )}
                      <Divider margin="24px 0" />
                    </Grid>
                    <Grid item lg={4} md={4} sm={5} xs={5}>
                      <Typography fontWeight="bold" variant="h4" margin="0 0 16px">
                        Mecanismo de compra:
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} sm={7} xs={7}>
                      {!loading && <Typography variant="body2">{sheet?.purchaseMechanism}</Typography>}
                      {loading && <Skeleton width="60%" />}
                    </Grid>
                  </>
                )}
                {!sheet?.purchaseMechanism && (
                  <>
                    <Grid item lg={12}>
                      {loading && <Skeleton width="60%" />}
                      {!loading && (
                        <Typography variant="body2">
                          El Trato Directo no recibió solicitudes y se desistió de la opción de realizar la contratación
                          excepcional
                        </Typography>
                      )}
                      <Divider margin="24px 0" />
                    </Grid>
                  </>
                )}
                <Grid item lg={4} md={4} sm={5} xs={5}>
                  <Typography fontWeight="bold" variant="h4" margin="0 0 16px">
                    Motivo:
                  </Typography>
                </Grid>
                <Grid item lg={8} md={8} sm={7} xs={7}>
                  {!loading && <Typography variant="body2">{sheet?.reasonWithdrawal}</Typography>}
                  {loading && <Skeleton width="60%" />}
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

DirectDealSummaryInfoDecision.propTypes = {
  contracts: PropTypes.shape({
    requestClosedDate: PropTypes.string,
    requestTotal: PropTypes.number,
    requests: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        dni: PropTypes.string,
        isActive: PropTypes.bool,
        answer: PropTypes.string
      })
    )
  }),
  sheet: PropTypes.shape({
    justification: PropTypes.string,
    status: PropTypes.string,
    purchases: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        total: PropTypes.number,
        date: PropTypes.string,
        code: PropTypes.string
      })
    ),
    purchaseMechanism: PropTypes.string,
    reasonWithdrawal: PropTypes.string
  }),
  loading: PropTypes.bool,
  onRedirect: PropTypes.func
}

export default DirectDealSummaryInfoDecision
