import { useNavigate } from 'react-router-dom'
import routes from '../../config/settings/routes'

const useConditionalSearchNavigate = (isSeller, isBuyer) => {
  const navigate = useNavigate()

  const conditionalNavigate = query => {
    const pathname = !isSeller && !isBuyer ? routes.searchPublic : routes.search

    navigate({ pathname, search: query.toString() }, { replace: true })
  }

  return { conditionalNavigate }
}

export default useConditionalSearchNavigate
