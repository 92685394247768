import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { PROTECT_BY_STEP_DICTIONARY } from '../../config/settings/constants'

/**
 * The ProtectByStep' component.
 */
const ProtectByStep = ({ children, fallback, step }) => {
  const { voucher } = useSelector(state => state.directDealVoucher)
  const { stepOne } = useSelector(state => state.directDealStepTwo)

  if (
    (step === PROTECT_BY_STEP_DICTIONARY.STEP_TWO && !stepOne) ||
    (step === PROTECT_BY_STEP_DICTIONARY.VOUCHER && !voucher)
  ) {
    return fallback()
  }

  return <>{children}</>
}

ProtectByStep.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.func,
  step: PropTypes.string
}

export default ProtectByStep
