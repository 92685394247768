import React from 'react'
import { Grid as BaseGrid, styled } from '@mui/material'

export const Root = styled(({ children, inIframe, ...props }) => <div {...props}>{children}</div>)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  width: 100%;
`

export const Header = styled('div')`
  width: 100%;

  ${props => props.theme.breakpoints.only('xs')} {
    max-width: 540px;
  }

  ${props => props.theme.breakpoints.only('sm')} {
    max-width: 720px;
  }

  ${props => props.theme.breakpoints.only('md')} {
    max-width: ${props => (props.inIframe ? '1140px' : '960px')};
  }

  ${props => props.theme.breakpoints.only('lg')} {
    max-width: 1140px;
  }

  ${props => props.theme.breakpoints.only('xl')} {
    max-width: 1140px;
  }
`

export const Body = styled(props => <BaseGrid {...props} container />)`
  &.MuiGrid-container {
    display: flex;
    height: auto;
    margin: 0 32px;
    padding: 0;
    width: 100%;

    ${props => props.theme.breakpoints.only('xs')} {
      margin: 0 auto;
      max-width: 540px;
      padding: 0 19px;
    }

    ${props => props.theme.breakpoints.only('sm')} {
      max-width: 100%;
      margin: 0;
      padding: 0 32px;
    }

    ${props => props.theme.breakpoints.only('md')} {
      margin: 0;
      padding: 0 32px;
    }

    ${props => props.theme.breakpoints.only('lg')} {
      margin: 0;
      padding: 0 32px;
    }

    ${props => props.theme.breakpoints.only('xl')} {
      max-width: 1440px;
      margin: 0 auto;
    }
  }
`

export const Footer = styled('div')`
  margin: auto 0 0;
  width: 100%;
`

export const TopContent = styled('section')`
  width: 100%;
`
