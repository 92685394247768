import React from 'react'
import PropTypes from 'prop-types'
import { CircleSuccessIcon, Typography } from '@chilecompra/react-kit/components'
import { AddProduct, LinkAddProduct, Grid } from './ProductSearch.styles'

/**
 * The ProductsSearch's row.
 */
const ProductSearchRow = props => {
  const { categoryId, onAddProduct, isAdded, productId, productName } = props

  const handleAddProduct = () => onAddProduct(productId, productName, categoryId)

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      border={isAdded}
      background={isAdded}
      margin="10px 10px"
      padding="3px 0 0 10px"
    >
      <Grid item xs={12} lg={1}>
        {isAdded ? <CircleSuccessIcon /> : ''}
      </Grid>
      <Grid item xs={12} lg={3}>
        <LinkAddProduct onClick={handleAddProduct}>
          {isAdded ? '+ Agregar nuevamente' : '+ Agregar al listado'}
        </LinkAddProduct>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Typography color="gray1" fontSize="12px" variant="body2">
          {productId}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Typography color="black1" fontSize="12px" variant="body2">
          {productName}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={2}>
        <AddProduct>{isAdded ? 'Producto agregado' : ''}</AddProduct>
      </Grid>
    </Grid>
  )
}

ProductSearchRow.propTypes = {
  categoryId: PropTypes.string.isRequired,
  onAddProduct: PropTypes.func.isRequired,
  isAdded: PropTypes.bool.isRequired,
  productId: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired
}

export default ProductSearchRow
