import React, { useEffect, useMemo, useState } from 'react'
import { batch, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Button, Dialog, Input, Typography } from '@chilecompra/react-kit/components'
import {
  checkInputErrorRequired,
  checkInputErrorMaxLength,
  checkInputErrorOnlyNumbers,
  checkInputErrorMinLength,
  checkInputErrorEmail,
  Skeleton,
  useInput,
  useViewport
} from '@chilecompra/react-kit'

import AttachmentsWrapper from '../../../components/components/AttachmentsWrapper.component'

import {
  onGetInfoDniDirectDealQuoteThunk,
  onSavedDirectDealQuoteThunk,
  onCleanQuoteDirectDeal
} from '../../DirectDealQuote/DirectDealQuote.actions'
import { onShowSnackbar } from '../../SnackBarProvider/SnackBarProvider.actions'
import { onUpdateQuoteDirectDealStepTwo, onCloseDetailQuoteDirectDealStepTwo } from '../DirectDealStepTwo.actions'

import { AmountPrefix, Grid, SearchSuffix, SearchIconCustom } from '../../DirectDealQuote/DirectDealQuote.styles'
import { Divider } from '../DirectDealStepTwo.styles'

import { formatDNI } from '../../../modules/utils/formatters'

import { CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL } from '../../../config/settings/constants'

/**
 * The DirectDealStepTwoDetailQuote dialog
 */
const DirectDealStepTwoDetailQuoteDialog = props => {
  const { open, loading, quoteId, quotes } = props

  const dispatch = useDispatch()
  const { size } = useViewport()

  const [isEditing, setEditing] = useState(false)
  const [quoteModelEdit, setQuoteModelEdit] = useState(null)

  const {
    error: quoteDniError,
    onChange: onChangeQuoteDni,
    onError: onErrorQuoteDni,
    value: quoteDniValue,
    setValue: setQuoteDni
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()],
    formatCallbacks: [value => formatDNI(value)],
    initialValue: ''
  })

  const {
    error: quoteLegalNameError,
    onChange: onChangeQuoteLegalName,
    value: quoteLegalNameValue,
    setValue: setQuoteLegalName
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: ''
  })

  const {
    error: quotePhoneError,
    onChange: onChangeQuotePhone,
    onError: onErrorQuotePhone,
    value: quotePhoneValue,
    setValue: setQuotePhone
  } = useInput({
    errorCallbacks: [
      checkInputErrorRequired(),
      checkInputErrorOnlyNumbers(),
      checkInputErrorMaxLength(9),
      checkInputErrorMinLength(9)
    ],
    initialValue: ''
  })

  const {
    error: quoteEmailError,
    onChange: onChangeQuoteEmail,
    onError: onErrorQuoteEmail,
    value: quoteEmailValue,
    setValue: setQuoteEmail
  } = useInput({
    errorCallbacks: [
      checkInputErrorRequired(),
      checkInputErrorMaxLength(200),
      checkInputErrorEmail('Formato de email incorrecto.')
    ],
    initialValue: ''
  })

  const handlerGetLegalName = () => {
    dispatch(
      onGetInfoDniDirectDealQuoteThunk({
        quoteDni: quoteDniValue,
        callback: legalName => {
          setQuoteLegalName(legalName)
        },
        callbackError: () => {
          setQuoteLegalName('')
          dispatch(
            onShowSnackbar({
              title: 'Proveedor no encontrado',
              message: 'Te pedimos que lo vuelvas a intentar.',
              severity: 'error'
            })
          )
        }
      })
    )
  }

  const handlerSaved = () => {
    const quoteModel = {
      ...quoteModelEdit,
      contributorDni: quoteDniValue,
      contributorLegalName: quoteLegalNameValue,
      contributorPhone: quotePhoneValue,
      contributorEmail: quoteEmailValue
    }

    dispatch(
      onSavedDirectDealQuoteThunk({
        quoteModel,
        callback: quoteModel => {
          batch(() => {
            dispatch(onUpdateQuoteDirectDealStepTwo(quoteModel))
            dispatch(
              onShowSnackbar({
                title: 'Cotización actualizada con éxito',
                severity: 'success'
              })
            )
            handlerCloseQuote()
          })
        },
        callbackError: () => {
          dispatch(
            onShowSnackbar({
              title: 'No se pudo guardar la cotización.',
              message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
              severity: 'error'
            })
          )
        }
      })
    )
  }

  const handlerCloseQuote = () => {
    batch(() => {
      dispatch(onCloseDetailQuoteDirectDealStepTwo())
      dispatch(onCleanQuoteDirectDeal())
      setQuoteDni('')
      setQuoteLegalName('')
      setQuotePhone('')
      setQuoteEmail('')
      setEditing(false)
    })
  }

  const handlerChangeMode = () => {
    setEditing(!isEditing)
  }

  const disableSaved = useMemo(() => {
    return (
      quoteDniValue === '' ||
      quoteDniError !== '' ||
      quoteLegalNameValue === '' ||
      quoteLegalNameError !== '' ||
      quotePhoneValue === '' ||
      quotePhoneError !== '' ||
      quoteEmailValue === '' ||
      quoteEmailError !== ''
    )
  }, [
    quoteDniValue,
    quoteDniError,
    quoteLegalNameValue,
    quoteLegalNameError,
    quotePhoneValue,
    quotePhoneError,
    quoteEmailValue,
    quoteEmailError
  ])

  useEffect(() => {
    const quoteEdit = quotes.find(quote => quote.idQuote === quoteId)

    if (quoteEdit) {
      setQuoteModelEdit(quoteEdit)
      setQuoteDni(quoteEdit?.contributorDni)
      setQuoteLegalName(quoteEdit?.contributorLegalName)
      setQuotePhone(quoteEdit?.contributorPhone)
      setQuoteEmail(quoteEdit?.contributorEmail)
    }
  }, [quoteId])

  return (
    <Dialog
      open={open}
      disableBackdropClick
      verticalAlign="top"
      maxWidth={size.isTiny || size.isSmall ? '400px' : '600px'}
      onClose={handlerCloseQuote}
    >
      <Grid container spacing={2} margin="8px 0 0">
        <Grid item margin="0 0 4px">
          <Typography variant="h3" fontWeight="bold" color="black1" lineHeight="18px" margin="8px 0">
            Cotización Rut: {quoteModelEdit?.contributorDni || '-'}
          </Typography>
          <Divider margin="16px 0" />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Input
            disabled={!isEditing}
            error={quoteDniError}
            label="RUT cotizante"
            loading={loading}
            suffix={
              isEditing && (
                <SearchSuffix onClick={handlerGetLegalName}>
                  <SearchIconCustom />
                </SearchSuffix>
              )
            }
            onChange={onChangeQuoteDni}
            onError={onErrorQuoteDni}
            value={quoteDniValue}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Input
            disabled={!isEditing}
            error={quoteLegalNameError}
            label="Razón social cotizante"
            loading={loading}
            onChange={onChangeQuoteLegalName}
            value={quoteLegalNameValue}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Input
            disabled={!isEditing}
            error={quotePhoneError}
            label="Teléfono cotizante"
            loading={loading}
            prefix={<AmountPrefix> +56</AmountPrefix>}
            onChange={onChangeQuotePhone}
            onError={onErrorQuotePhone}
            value={quotePhoneValue}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Input
            disabled={!isEditing}
            error={quoteEmailError}
            label="Email cotizante"
            loading={loading}
            type="email"
            onChange={onChangeQuoteEmail}
            onError={onErrorQuoteEmail}
            value={quoteEmailValue}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold">
            Adjuntar archivos
          </Typography>
          <Typography variant="body2">Se admiten archivos en formato con un peso máximo de 20mb.</Typography>

          {!loading && quoteModelEdit && (
            <AttachmentsWrapper
              business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
              idBusiness={quoteModelEdit?.idBusiness}
              subBusiness={quoteModelEdit?.idSubBusiness}
              isPrivate
              color="blank"
              canRead
              canReadBulk
              canDelete={isEditing}
              canDeleteBulk={isEditing}
              canWrite={isEditing}
              canWriteBulk={isEditing}
            />
          )}

          {loading && <Skeleton width="250px" />}
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} textAlign="left" order={{ lg: 1, md: 1, sm: 1, xs: 2 }}>
          <Button width={size.isSmall || size.isTiny ? '100%' : '70%'} onClick={handlerCloseQuote}>
            Volver
          </Button>
        </Grid>
        {!isEditing && (
          <Grid item lg={6} md={6} sm={6} xs={12} textAlign="left" order={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
            <Button
              color="primary"
              disabled={disableSaved}
              variant="outlined"
              width={size.isSmall || size.isTiny ? '100%' : '70%'}
              onClick={handlerChangeMode}
            >
              Editar
            </Button>
          </Grid>
        )}
        {isEditing && (
          <Grid item lg={6} md={6} sm={6} xs={12} textAlign="left" order={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
            <Button
              color="primary"
              disabled={disableSaved}
              loading={loading}
              width={size.isSmall || size.isTiny ? '100%' : '80%'}
              onClick={handlerSaved}
            >
              Guardar cotización
            </Button>
          </Grid>
        )}
      </Grid>
    </Dialog>
  )
}

DirectDealStepTwoDetailQuoteDialog.propTypes = {
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  quoteId: PropTypes.string,
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      idQuote: PropTypes.string,
      contributorDni: PropTypes.string,
      contributorLegalName: PropTypes.string,
      contributorPhone: PropTypes.string,
      contributorEmail: PropTypes.string
    })
  )
}

export default DirectDealStepTwoDetailQuoteDialog
