import React, { useEffect, useMemo, useState } from 'react'
import { batch, useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getCookie, useEffectOnce } from '@chilecompra/react-kit'

import DirectDealStepTwoAbilityDialog from './DirectDealStepTwo.ability.dialog'
import DirectDealStepTwoAddress from './DirectDealStepTwo.address'
import DirectDealStepTwoButtons from './DirectDealStepTwo.footer'
import DirectDealStepTwoClosingDate from './DirectDealStepTwo.dateclose'
import DirectDealStepTwoConfirmDialog from './DirectDealStepTwo.confirm.dialog'
import DirectDealStepTwoContactInformation from './DirectDealStepTwo.provider'
import DirectDealStepTwoGeneralInformation from './DirectDealStepTwo.information'
import DirectDealStepTwoProducts from './Products/DirectDealStepTwo.products'
import DirectDealStepTwoResume from './DirectDealStepTwo.resume'
import DirectDealStepTwoSavedDialog from './DirectDealStepTwo.saved.dialog'
import DirectDealStepTwoTaxes from './DirectDealStepTwo.taxes'
import DirectDealStepTwoQuotes from './Quotes/DirectDealStepTwo.quotes'

import {
  onCloseAbilityDirectDealStepTwo,
  onCloseConfirmStepTwoDialog,
  onCloseSavedDirectDealStepTwo,
  onGetAllCurrienciesDirectDealStepTwoThunk,
  onGetClosingDateThunk,
  onGetContractDurationSearchThunk,
  onGetContractTypeSearchThunk,
  onGetCurrencySearchThunk,
  onGetRegionsThunk,
  onGetTaxesSearchThunk,
  onGetUnitMeasurementStepTwoThunk,
  onOpenAbilityDirectDealStepTwo,
  onOpenConfirmStepTwoDialog,
  onSaveDirectDealThunk,
  onOpenSavedDirectDealStepTwo,
  onPublishDirectDealThunk,
  onStorageDirectDealStepTwo,
  onResetDirectDealStepTwo
} from './DirectDealStepTwo.actions'

import { onShowSnackbar } from '../SnackBarProvider/SnackBarProvider.actions'
import { onSavedInfoDirectDealVoucher } from '../DirectDealVoucher/DirectDealVoucher.actions'
import { onRemoveAllSuccess } from '../ProductSearch/ProductSearch.actions'

import { Divider, Root } from './DirectDealStepTwo.styles'

import { STATUS_ABILITY_PROVIDER_DICTIONARY } from '../../config/settings/constants'
import routes from '../../config/settings/routes'
import { useFormRef } from '../../modules/hooks/useFormRef'
import { dialogScrollTop } from '../../modules/utils/dialogScrollTop'

/**
 * The DirectDealStepTwo's container.
 */
const DirectDealStepTwoContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formStepTwoRef = useFormRef()
  const {
    cities: city,
    contractDuration: directDealStepTwoContractDuration,
    contractType: directDealStepTwoContracts,
    currency: directDealStepTwoCurrency,
    currencies: directDealStepTwoCurrencies,
    closingDate: direcrDealStepTwoClosingDate,
    dialog: directDealStepTwoDialog,
    loading: directDealStepTwoLoading,
    provider: directDealStepTwoProvider,
    regions: region,
    sheetStorage: directDealStorageSheet,
    stepOne: directDealStepOneSheet,
    taxes: directDealStepTwoTaxes,
    unitMeasurement: directDealStepTwoUnitMeasurement,
    quotes: directDealStepTwoQuotes
  } = useSelector(state => state.directDealStepTwo)
  const { productsSaved: directDealProductsSaved } = useSelector(state => state.productSearch)

  const [directDealProductsUpdate, setProductsUpdate] = useState({})
  const [currencySelected, setCurrencySelected] = useState(null)
  const [isProductsValid, setProductsValid] = useState(false)

  const disableSave = useMemo(() => {
    return (
      formStepTwoRef.current.addressInputError !== '' ||
      formStepTwoRef.current.addressInputValue === '' ||
      formStepTwoRef.current.chargeError !== '' ||
      formStepTwoRef.current.chargeValue === '' ||
      formStepTwoRef.current.citiesValue === '' ||
      formStepTwoRef.current.citiesValueError !== '' ||
      formStepTwoRef.current.coin === '' ||
      formStepTwoRef.current.coinError !== '' ||
      formStepTwoRef.current.commentaryValueError !== '' ||
      formStepTwoRef.current.contractCategoryValue === '' ||
      formStepTwoRef.current.contractType === '' ||
      formStepTwoRef.current.contractTypeError !== '' ||
      formStepTwoRef.current.dateFinishExecuteError !== '' ||
      formStepTwoRef.current.dateFinishExecuteValue === '' ||
      formStepTwoRef.current.deliveryError !== '' ||
      formStepTwoRef.current.deliveryValue === '' ||
      formStepTwoRef.current.descriptionValue === '' ||
      formStepTwoRef.current.descriptionValueError !== '' ||
      formStepTwoRef.current.discountError !== '' ||
      formStepTwoRef.current.discountValue === '' ||
      formStepTwoRef.current.duration === '' ||
      formStepTwoRef.current.durationError !== '' ||
      formStepTwoRef.current.justificationValue === '' ||
      formStepTwoRef.current.justificationValueError !== '' ||
      formStepTwoRef.current.nameSheetError !== '' ||
      formStepTwoRef.current.nameSheetValue === '' ||
      formStepTwoRef.current.observationChargeError !== '' ||
      formStepTwoRef.current.observationChargeValue === '' ||
      formStepTwoRef.current.observationDiscountError !== '' ||
      formStepTwoRef.current.observationDiscountValue === '' ||
      formStepTwoRef.current.providerDniError !== '' ||
      formStepTwoRef.current.providerDniValue === '' ||
      formStepTwoRef.current.regionValue === '' ||
      formStepTwoRef.current.regionValueError !== '' ||
      formStepTwoRef.current.taxesError !== '' ||
      formStepTwoRef.current.taxesValue === '' ||
      !isProductsValid ||
      (directDealStepOneSheet?.withPublicity &&
        (formStepTwoRef.current.dateFromCloseError !== '' ||
          formStepTwoRef.current.dateFromCloseValue === '' ||
          formStepTwoRef.current.hourDateCloseError !== '' ||
          formStepTwoRef.current.hourDateCloseValue === '')) ||
      !directDealStepTwoQuotes
    )
  }, [
    formStepTwoRef.current.addressInputError,
    formStepTwoRef.current.addressInputValue,
    formStepTwoRef.current.chargeError,
    formStepTwoRef.current.chargeValue,
    formStepTwoRef.current.citiesValue,
    formStepTwoRef.current.citiesValueError,
    formStepTwoRef.current.coin,
    formStepTwoRef.current.coinError,
    formStepTwoRef.current.commentaryValue,
    formStepTwoRef.current.commentaryValueError,
    formStepTwoRef.current.contractCategoryValue,
    formStepTwoRef.current.contractType,
    formStepTwoRef.current.contractTypeError,
    formStepTwoRef.current.dateFinishExecuteError,
    formStepTwoRef.current.dateFinishExecuteValue,
    formStepTwoRef.current.dateFromCloseError,
    formStepTwoRef.current.dateFromCloseValue,
    formStepTwoRef.current.deliveryError,
    formStepTwoRef.current.deliveryValue,
    formStepTwoRef.current.descriptionValue,
    formStepTwoRef.current.descriptionValueError,
    formStepTwoRef.current.discountError,
    formStepTwoRef.current.discountValue,
    formStepTwoRef.current.duration,
    formStepTwoRef.current.durationError,
    formStepTwoRef.current.hourDateCloseError,
    formStepTwoRef.current.hourDateCloseValue,
    formStepTwoRef.current.justificationValue,
    formStepTwoRef.current.justificationValueError,
    formStepTwoRef.current.nameSheetError,
    formStepTwoRef.current.nameSheetValue,
    formStepTwoRef.current.netTotal,
    formStepTwoRef.current.observationChargeError,
    formStepTwoRef.current.observationChargeValue,
    formStepTwoRef.current.observationDiscountError,
    formStepTwoRef.current.observationDiscountValue,
    formStepTwoRef.current.providerDniError,
    formStepTwoRef.current.providerDniValue,
    formStepTwoRef.current.regionValue,
    formStepTwoRef.current.regionValueError,
    formStepTwoRef.current.taxesError,
    formStepTwoRef.current.taxesValue,
    formStepTwoRef.current.total,
    isProductsValid,
    directDealStepTwoQuotes
  ])

  const generateDirectDealModel = form => {
    return {
      id: directDealStepOneSheet?.id,
      code: directDealStepOneSheet?.code,
      contractDuration:
        directDealStepTwoContractDuration.find(duration => duration.value === form.duration)?.id || null,
      contractType: form.contractType,
      dispatchRegion: form.regionValue,
      dispatchCity: form.citiesValue,
      dispatchAddress: form.addressInputValue,
      dispatchComment: form.commentaryValue,
      dispatchAmountDelivery: form.deliveryValue,
      dispatchAmountDiscount: form.discountValue,
      dispatchObsDiscount: form.observationDiscountValue,
      dispatchAmountCharge: form.chargeValue,
      dispatchObsCharge: form.observationChargeValue,
      dispatchTax: form.taxesValue,
      quotes: [],
      sheetJustification: form.justificationValue,
      sheetName: form.nameSheetValue,
      sheetDescription: form.descriptionValue,

      dateExecute: form.dateFinishExecuteValue && form.dateFinishExecuteValue.toISOString(),
      dateClose: form.dateFromCloseValue && form.dateFromCloseValue.toISOString(),
      hourClose: form.hourDateCloseValue && form.hourDateCloseValue.toISOString(),
      providerDni: form.providerDniValue,
      sheetCurrency: form.coin,

      products: directDealProductsSaved,
      total: form.total,
      totalClp: convertClpToTotalSheet()
    }
  }

  const handleSave = () => {
    const sheetStepTwo = generateDirectDealModel(formStepTwoRef.current)

    dispatch(
      onSaveDirectDealThunk({
        sheetStepTwo,
        callback: () => {
          dispatch(onOpenSavedDirectDealStepTwo())
          handleStorageForm()
          dialogScrollTop()
        },
        callbackError: () => {
          dispatch(
            onShowSnackbar({
              title: 'No ha sido posible guardar la ficha',
              message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
              severity: 'error'
            })
          )
          handleStorageForm()
        }
      })
    )
  }
  const handlePublish = () => {
    const sheetStepTwo = generateDirectDealModel(formStepTwoRef.current)

    dispatch(
      onPublishDirectDealThunk({
        sheetStepTwo,
        callback: porId => {
          batch(() => {
            dispatch(
              onSavedInfoDirectDealVoucher({
                voucher: {
                  id: directDealStepOneSheet?.id,
                  code: directDealStepOneSheet?.code,
                  name: formStepTwoRef.current.nameSheetValue,
                  cause: directDealStepOneSheet?.cause,
                  total: formStepTwoRef.current.total,
                  currency: currencySelected,
                  providerDni: formStepTwoRef.current.providerDniValue,
                  providerLegalName: directDealStepTwoProvider?.businessName,
                  withPublicity: directDealStepOneSheet?.withPublicity,
                  dateClosed: formStepTwoRef.current.dateFromCloseValue,
                  hourClose: formStepTwoRef.current.hourDateCloseValue,
                  porId
                }
              })
            )
            dispatch(onResetDirectDealStepTwo())
            dispatch(onRemoveAllSuccess())
            navigate({ pathname: routes.directDealVoucher }, { replace: true })
          })
        },
        callbackError: () => {
          dispatch(
            onShowSnackbar({
              title: 'No ha sido posible crear la ficha',
              message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
              severity: 'error'
            })
          )
          handleStorageForm()
        }
      })
    )
  }
  const handleBack = () => {
    const query = getCookie('search')
    navigate({ pathname: routes.search, search: query.toString() }, { replace: true })

    dispatch(onCloseSavedDirectDealStepTwo())
    dispatch(onResetDirectDealStepTwo())
    dispatch(onRemoveAllSuccess())
  }
  const handleStorageForm = () => {
    dispatch(
      onStorageDirectDealStepTwo({
        sheet: {
          address: formStepTwoRef.current.addressInputValue,
          chargeValue: formStepTwoRef.current.chargeValue,
          city: formStepTwoRef.current.citiesValue,
          code: directDealStepOneSheet?.code,
          coin: formStepTwoRef.current.coin,
          comment: formStepTwoRef.current.commentaryValue,
          contractCategory: formStepTwoRef.current.contractCategoryValue,
          contractType: formStepTwoRef.current.contractType,
          dateFinishExecute: formStepTwoRef.current.dateFinishExecuteValue,
          dateFromClose: formStepTwoRef.current.dateFromCloseValue,
          delivery: formStepTwoRef.current.deliveryValue,
          description: formStepTwoRef.current.descriptionValue,
          discount: formStepTwoRef.current.discountValue,
          duration: formStepTwoRef.current.duration,
          hourDateCLose: formStepTwoRef.current.hourDateCloseValue,
          justification: formStepTwoRef.current.justificationValue,
          nameSheet: formStepTwoRef.current.nameSheetValue,
          observationCharge: formStepTwoRef.current.observationChargeValue,
          observationDiscount: formStepTwoRef.current.observationDiscountValue,
          providerDni: formStepTwoRef.current.providerDniValue,
          providerEmail: formStepTwoRef.current.providerEmailValue,
          providerName: formStepTwoRef.current.providerNameValue,
          providerPhone: formStepTwoRef.current.providerPhoneValue,
          providerPricing: formStepTwoRef.current.providerPricingValue,
          region: formStepTwoRef.current.regionValue,
          tax: formStepTwoRef.current.taxesValue,
          total: formStepTwoRef.current.total
        }
      })
    )
  }
  const handleCloseSavedDialog = () => {
    dispatch(onCloseSavedDirectDealStepTwo())
  }
  const handleOpenAbilityDialog = () => {
    dispatch(onOpenAbilityDirectDealStepTwo())
    dialogScrollTop()
  }
  const handleCloseAbilityDialog = () => {
    dispatch(onCloseAbilityDirectDealStepTwo())
  }
  const handleOpenConfirmDialog = () => {
    if (directDealStepTwoProvider?.skillState !== STATUS_ABILITY_PROVIDER_DICTIONARY.ABILITY) {
      handleOpenAbilityDialog()
      return
    }

    dispatch(onOpenConfirmStepTwoDialog())
    dialogScrollTop()
  }
  const handleCloseConfirmDialog = () => {
    dispatch(onCloseConfirmStepTwoDialog())
  }

  const convertClpToTotalSheet = () => {
    const currencySheet = formStepTwoRef.current.coin || ''
    const totalSheet = formStepTwoRef.current.total || 0

    if (currencySheet) {
      if (currencySheet !== 'CLP') {
        const valueClp =
          directDealStepTwoCurrencies.find(currency => currency.type === formStepTwoRef.current.coin)?.valueClp || 0

        return parseInt(totalSheet * valueClp)
      }

      return parseInt(totalSheet)
    }

    return 0
  }

  useEffectOnce(() => {
    batch(() => {
      dispatch(onCloseConfirmStepTwoDialog())
      dispatch(onCloseAbilityDirectDealStepTwo())
      dispatch(onCloseSavedDirectDealStepTwo())
      dispatch(onGetRegionsThunk())
      dispatch(onGetContractTypeSearchThunk())
      dispatch(onGetContractDurationSearchThunk())
      dispatch(onGetCurrencySearchThunk())
      dispatch(onGetClosingDateThunk({ closingDate: new Date().toLocaleDateString().replace(/\//g, '-') }))
      dispatch(onGetTaxesSearchThunk())
      dispatch(onGetUnitMeasurementStepTwoThunk())
      dispatch(onGetAllCurrienciesDirectDealStepTwoThunk())
    })
  })

  useEffect(() => {
    if (directDealStepTwoCurrency) {
      setCurrencySelected(directDealStepTwoCurrency.find(currency => currency.value === formStepTwoRef.current.coin))
    }
  }, [formStepTwoRef.current.coin])

  return (
    <Root>
      <Divider />
      <DirectDealStepTwoGeneralInformation
        idBusinessSheet={directDealStepOneSheet?.idBusinessSheet}
        subBusinessSheet={directDealStepOneSheet?.subBusinessSheet}
        contracts={directDealStepTwoContracts}
        contractDurations={directDealStepTwoContractDuration}
        currencies={directDealStepTwoCurrency}
        formStepTwoRef={formStepTwoRef}
        loading={directDealStepTwoLoading}
        storage={directDealStorageSheet}
      />
      <Divider />
      {directDealStepOneSheet?.withPublicity && (
        <DirectDealStepTwoClosingDate
          closeDate={direcrDealStepTwoClosingDate}
          formStepTwoRef={formStepTwoRef}
          loading={directDealStepTwoLoading}
          storage={directDealStorageSheet}
        />
      )}

      <Divider />
      <DirectDealStepTwoContactInformation
        formStepTwoRef={formStepTwoRef}
        loading={directDealStepTwoLoading}
        provider={directDealStepTwoProvider}
        storage={directDealStorageSheet}
        onOpenDialog={handleOpenAbilityDialog}
      />
      <Divider />
      <DirectDealStepTwoQuotes code={directDealStepOneSheet?.code} onAdd={handleStorageForm} />
      <Divider />
      <DirectDealStepTwoProducts
        code={directDealStepOneSheet?.code}
        currency={currencySelected}
        formStepTwoRef={formStepTwoRef}
        loading={directDealStepTwoLoading}
        productsSaved={directDealProductsSaved}
        productsUpdate={directDealProductsUpdate}
        setProductsUpdate={setProductsUpdate}
        setProductsValid={setProductsValid}
        unitMeasurement={directDealStepTwoUnitMeasurement}
        onStorage={handleStorageForm}
      />
      <Divider />
      <DirectDealStepTwoAddress
        city={city}
        formStepTwoRef={formStepTwoRef}
        loading={directDealStepTwoLoading}
        region={region}
        storage={directDealStorageSheet}
      />
      <Divider />

      {directDealProductsSaved.length > 0 && (
        <>
          <DirectDealStepTwoTaxes
            currency={currencySelected}
            formStepTwoRef={formStepTwoRef}
            loading={directDealStepTwoLoading}
            storage={directDealStorageSheet}
            taxes={directDealStepTwoTaxes}
          />
          <Divider />
          <DirectDealStepTwoResume
            currency={currencySelected}
            currencies={directDealStepTwoCurrencies}
            formStepTwoRef={formStepTwoRef}
            loading={directDealStepTwoLoading}
            taxes={directDealStepTwoTaxes}
          />
        </>
      )}
      <Divider />
      <DirectDealStepTwoButtons
        disableSave={disableSave}
        loading={directDealStepTwoLoading}
        onContinue={handleOpenConfirmDialog}
        onSave={handleSave}
        onBack={handleBack}
      />
      <DirectDealStepTwoSavedDialog
        open={directDealStepTwoDialog?.openSaved}
        onBack={handleBack}
        onClose={handleCloseSavedDialog}
        sheetCode={directDealStepOneSheet?.code}
      />
      <DirectDealStepTwoAbilityDialog open={directDealStepTwoDialog?.openAbility} onClose={handleCloseAbilityDialog} />
      <DirectDealStepTwoConfirmDialog
        loading={directDealStepTwoLoading}
        open={directDealStepTwoDialog?.openConfirmation}
        onCreate={handlePublish}
        onClose={handleCloseConfirmDialog}
      />
    </Root>
  )
}
export default DirectDealStepTwoContainer
