import { createContext } from 'react'

const contextPayload = {
  regionInfo: {}
}

const InitialConfigurationContext = createContext(contextPayload)
InitialConfigurationContext.displayName = 'initial-configuration'

export default InitialConfigurationContext
