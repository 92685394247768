import { useContext } from 'react'
import AuthorizationContext from './AuthProvider.context'

/**
 * The authorization custom hook.
 */
export const useAuthorization = () => {
  const { accessToken, isBuyer, isSeller, isPrivated, userDni, userType } = useContext(AuthorizationContext)

  return { accessToken, isBuyer, isSeller, isPrivated, userDni, userType }
}
