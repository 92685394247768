import React from 'react'
import PropTypes from 'prop-types'

import { formatDate } from '../../modules/utils/formatters'

/**
 * The FormattedDate component.
 */
const FormattedDateComponent = props => {
  const { date, includeHours = false } = props
  return <>{formatDate(date, includeHours)}</>
}

FormattedDateComponent.propTypes = {
  date: PropTypes.string,
  includeHours: PropTypes.bool
}

export default FormattedDateComponent
