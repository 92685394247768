import { factoryService, getJsonRequest, postJsonRequest, putJsonRequest } from '@chilecompra/react-kit/requests'
import { REACT_APP_API_BASE_URL } from '../config/settings/environment'

/**
 * PUT: updated contract request for provider
 */
export const putCreateContractRequest = factoryService(({ request }) => {
  const body = {
    idSolicitud: request.requestId,
    respuesta: request.requestAnswer,
    rutCotizante: request.requestDni,
    razonSocialCotizante: request.requestLegalName
  }

  return putJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/solicitud`, { body })
})

/**
 * Get of the list of contract requests
 */
export const getContractRequests = factoryService(({ sheetCode }) => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/solicitud/resumen?codTratoDirecto=${sheetCode}`)
})

/**
 * POST: Create a contract request for provider
 */
export const postPrepareContractRequest = factoryService(({ request }) => {
  const body = {
    idTratoDirecto: request
  }

  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/solicitud`, { body })
})
