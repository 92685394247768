import {
  GENERATE_DIRECT_DEAL_QUOTE_ERROR,
  GENERATE_DIRECT_DEAL_QUOTE_SUCCESS,
  GENERATE_DIRECT_DEAL_QUOTE,
  GET_INFO_DNI_DIRECT_DEAL_QUOTE_ERROR,
  GET_INFO_DNI_DIRECT_DEAL_QUOTE_SUCCESS,
  GET_INFO_DNI_DIRECT_DEAL_QUOTE,
  SAVED_DIRECT_DEAL_QUOTE_ERROR,
  SAVED_DIRECT_DEAL_QUOTE_SUCCESS,
  SAVED_DIRECT_DEAL_QUOTE,
  STORAGE_ID_DIRECT_DEAL_QUOTE,
  CLEAN_QUOTE_DIRECT_DEAL_QUOTE
} from './DirectDealQuote.actions'

const directDealQuoteState = {
  error: undefined,
  loading: false,
  quote: null,
  idQuoteEdit: null
}

/**
 * The DirectDealQuote reducer.
 */
const directDealQuoteReducer = (state = directDealQuoteState, { payload, type }) => {
  switch (type) {
    case GET_INFO_DNI_DIRECT_DEAL_QUOTE:
    case SAVED_DIRECT_DEAL_QUOTE:
    case GENERATE_DIRECT_DEAL_QUOTE: {
      return { ...state, error: undefined, loading: true }
    }
    case SAVED_DIRECT_DEAL_QUOTE_SUCCESS: {
      return { ...state, loading: false, quote: null }
    }
    case GENERATE_DIRECT_DEAL_QUOTE_SUCCESS: {
      return { ...state, loading: false, quote: { ...state.quote, ...payload.quote } }
    }
    case GET_INFO_DNI_DIRECT_DEAL_QUOTE_ERROR:
    case SAVED_DIRECT_DEAL_QUOTE_ERROR:
    case GENERATE_DIRECT_DEAL_QUOTE_ERROR: {
      return { ...state, error: payload.err, loading: false }
    }
    case GET_INFO_DNI_DIRECT_DEAL_QUOTE_SUCCESS: {
      return { ...state, loading: false, error: undefined }
    }
    case STORAGE_ID_DIRECT_DEAL_QUOTE: {
      return { ...state, idQuoteEdit: payload }
    }
    case CLEAN_QUOTE_DIRECT_DEAL_QUOTE: {
      return { ...state, quote: null, idQuoteEdit: null }
    }
    default: {
      return state
    }
  }
}

export default directDealQuoteReducer
