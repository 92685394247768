import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Input, Select, Typography } from '@chilecompra/react-kit/components'
import {
  checkInputErrorMaxLength,
  checkInputErrorRequired,
  Textarea,
  useInput,
  useSelect,
  useTextarea
} from '@chilecompra/react-kit'

import { GridContainer, GridItem } from './DirectDealStepTwo.styles'
import { onGetCitiesThunk } from './DirectDealStepTwo.actions'

import { useImperativeFormRef } from '../../modules/hooks/useFormRef'
/**
 * The DirectDealStepTwoAddress's container.
 */
const DirectDealStepTwoAddress = props => {
  const { formStepTwoRef, loading, region, storage } = props

  const dispatch = useDispatch()
  const { cities: city } = useSelector(state => state.directDealStepTwo)

  const {
    error: addressInputError,
    value: addressInputValue,
    onChange: addressInputValueOnChange,
    onError: addressInputValueOnError
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(100), checkInputErrorRequired()],
    initialValue: storage?.address || ''
  })
  const {
    error: commentaryValueError,
    onChange: commentaryValueOnChange,
    value: commentaryValue,
    onError: commentaryValueOnError
  } = useTextarea({ errorCallbacks: [checkInputErrorMaxLength(300)], initialValue: storage?.comment || '' })
  const {
    error: regionValueError,
    value: regionValue,
    onChange: regionValueOnChange,
    onError: regionValueOnError
  } = useSelect({
    initialValue: storage?.region || '',
    changeCallback: updatedValue => dispatch(onGetCitiesThunk(updatedValue)),
    errorCallbacks: [checkInputErrorRequired()]
  })
  const {
    error: citiesValueError,
    value: citiesValue,
    onChange: citiesValueOnChange,
    onError: citiesValueOnError
  } = useSelect({
    initialValue: storage?.city || '',
    errorCallbacks: [checkInputErrorRequired()]
  })

  useImperativeFormRef(
    formStepTwoRef,
    () => ({
      addressInputError,
      addressInputValue,
      commentaryValueError,
      commentaryValue,
      regionValueError,
      regionValue,
      citiesValueError,
      citiesValue
    }),
    [
      addressInputError,
      addressInputValue,
      commentaryValueError,
      commentaryValue,
      regionValueError,
      regionValue,
      citiesValueError,
      citiesValue
    ]
  )

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2}>
        <Typography variant="body1" fontWeight="bold">
          Dirección de entrega o ejecución del servicio
        </Typography>
        <Typography fontSize="12px" fontWeight="regular" lineHeight="16px" variant="body2" padding="16px 0 0">
          Ingresa la dirección a la cuál serán despachados los productos o donde será realizado el servicio contratado.
        </Typography>
      </GridItem>
      <GridItem xs={12} md={6}>
        <GridContainer spacing={2}>
          <GridItem lg={6} md={6} sm={12} xs={12}>
            <Select
              error={regionValueError}
              label="Región"
              loading={loading}
              options={region}
              placeholder="Seleccione una Región"
              value={regionValue}
              onChange={regionValueOnChange}
              onError={regionValueOnError}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xs={12}>
            <Select
              error={citiesValueError}
              label="Comuna"
              loading={loading}
              options={city}
              placeholder="Seleccione una Comuna"
              value={citiesValue}
              onChange={citiesValueOnChange}
              onError={citiesValueOnError}
            />
          </GridItem>
          <GridItem xs={12}>
            <Input
              error={addressInputError}
              label="Dirección"
              loading={loading}
              maxCount={100}
              placeholder="Ingresa el nombre de la calle y número"
              value={addressInputValue}
              onChange={addressInputValueOnChange}
              onError={addressInputValueOnError}
            />
          </GridItem>
          <GridItem xs={12}>
            <Textarea
              error={commentaryValueError}
              label="Comentarios (opcional)"
              loading={loading}
              maxCount={300}
              size="large"
              value={commentaryValue}
              onChange={commentaryValueOnChange}
              onError={commentaryValueOnError}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

DirectDealStepTwoAddress.propTypes = {
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  region: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
      orden: PropTypes.number
    })
  ),
  city: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
      orden: PropTypes.number
    })
  ),
  loading: PropTypes.bool,
  storage: PropTypes.shape({
    address: PropTypes.string,
    comment: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string
  })
}
export default DirectDealStepTwoAddress
