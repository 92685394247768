import React, { useState, useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { Button, Card, CheckBox, Input, Select, Radio, Textarea } from '@chilecompra/react-kit/components'
import {
  checkInputErrorMaxLength,
  checkInputErrorRegEx,
  checkInputErrorRequired,
  useCheckBox,
  useInput,
  useRadio,
  useSelect,
  useTextarea
} from '@chilecompra/react-kit'

import FormattedCurrencyComponent from '../../../components/components/FormattedCurrency.component'

import { AmountPrefix, DeleteLink, DeleteIconCustom, GridContainer, GridItem } from '../DirectDealStepTwo.styles'

import { ONLY_NUMBERS_CHECKED } from '../../../modules/utils/regexes'
import {
  DIRECT_DEAL_STEP_TWO_RADIO_OPTIONS_PRODUCTS,
  DIRECT_DEAL_STEP_TWO_PRODUCT_ONU_CODE
} from '../../../config/settings/constants'
import { checkInputErrorMinValue, getRegexByCurrency } from '../../../modules/utils/checkers'
import { formatTypeCurrency, formatCurrencyToNumber } from '../../../modules/utils/formatters'

/**
 * The DirectDealStepTwo product container.
 */
const DirectDealStepTwoProduct = props => {
  const {
    amount,
    categoryId,
    charge,
    codeZGEN,
    currency,
    detail,
    discount,
    dontKnowCodeZGEN,
    productId,
    productKey,
    productName,
    productsUpdate,
    setProductsUpdate,
    unitMeasure,
    unitMeasurement,
    unitPrice,
    withDiscountOrCharge,
    onRemove,
    onSave
  } = props

  const [withdiscountOrCharge, setWithdiscountOrCharge] = useState(false)
  const [withdiscount, setWithdiscount] = useState(false)
  const [withCharge, setWithCharge] = useState(false)
  const [totalItem, setTotalItem] = useState(0)
  const prevCurrency = useRef(currency)

  const handleChanges = (updatedValue, key) => {
    setProductsUpdate({
      ...productsUpdate,
      [productKey]: {
        ...productsUpdate[productKey],
        [key]: updatedValue,
        productId,
        productKey,
        productName,
        categoryId,
        isInvalid: disableSave
      }
    })
  }

  const handleChangesAddDiscountOrCharges = () => {
    setProductsUpdate({
      ...productsUpdate,
      [productKey]: {
        ...productsUpdate[productKey],
        productId,
        productKey,
        productName,
        categoryId,
        discount: 0,
        charge: 0,
        discountOrCharge: '',
        isInvalid: disableSave
      }
    })
  }

  const handleChangesDiscountOrCharges = updatedValue => {
    setProductsUpdate({
      ...productsUpdate,
      [productKey]: {
        ...productsUpdate[productKey],
        productId,
        productKey,
        productName,
        categoryId,
        discount: 0,
        charge: 0,
        discountOrCharge: updatedValue,
        isInvalid: disableSave
      }
    })
  }

  const handleResetAmounts = () => {
    setProductsUpdate({
      ...productsUpdate,
      [productKey]: {
        ...productsUpdate[productKey],
        productId,
        productKey,
        productName,
        categoryId,
        discount: 0,
        charge: 0,
        amount: 0,
        unitPrice: 0,
        isInvalid: true
      }
    })
  }

  const handleChangesDontKnowCodeZGEN = dontKnow => {
    setProductsUpdate({
      ...productsUpdate,
      [productKey]: {
        ...productsUpdate[productKey],
        productId,
        productKey,
        productName,
        categoryId,
        dontKnowCodeZGEN: dontKnow,
        codeZGEN: '',
        isInvalid: disableSave
      }
    })
  }

  const {
    error: amountError,
    value: amountValue,
    onChange: handleChangeAmount,
    onError: handleErrorAmount,
    setValue: setAmount
  } = useInput({
    changeCallback: updatedValue => {
      handleChanges(updatedValue, 'amount')
      calculateTotalItem(updatedValue, unitPriceValue, discountValue, chargeValue)
    },
    initialValue: amount || 0,
    errorCallbacks: [
      checkInputErrorMaxLength(20),
      checkInputErrorRequired(),
      checkInputErrorMinValue(1, 'El valor no debe ser inferior a 1.'),
      checkInputErrorRegEx(ONLY_NUMBERS_CHECKED)
    ]
  })
  const {
    error: unitMeasureError,
    value: unitMeasureValue,
    onChange: handleChangeUnitMeasure,
    onError: handleErrorUnitMeasure
  } = useSelect({
    changeCallback: updatedValue => handleChanges(updatedValue, 'unitMeasure'),
    initialValue: unitMeasure || '',
    errorCallbacks: [checkInputErrorRequired()]
  })
  const {
    error: unitPriceError,
    value: unitPriceValue,
    onChange: handleChangeUnitPrice,
    onError: handleErrorUnitPrice,
    setValue: setUnitPrice
  } = useInput({
    changeCallback: updatedValue => {
      handleChanges(updatedValue, 'unitPrice')
      calculateTotalItem(amountValue, updatedValue, discountValue, chargeValue)
    },
    formatCallbacks: [value => formatTypeCurrency(currency?.type, value)],
    initialValue: unitPrice || 0,
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorRegEx(getRegexByCurrency(currency?.type))]
  })
  const { value: adddiscountValue, onChange: handleChangeAdddiscount } = useCheckBox({
    changeCallback: updatedValue => {
      setWithdiscountOrCharge(updatedValue)
      setDiscountOrCharge('')
      setDiscount(0)
      setCharge(0)
      setWithCharge(false)
      setWithdiscount(false)
      handleChangesAddDiscountOrCharges()
      calculateTotalItem(amountValue, unitPriceValue, 0, 0)
    },
    initialValue: false
  })
  const {
    value: discountOrChargeValue,
    onChange: handleChangeDiscountOrChange,
    setValue: setDiscountOrCharge
  } = useRadio({
    changeCallback: updatedValue => {
      setDiscount(0)
      setCharge(0)
      setWithdiscount(updatedValue === '1')
      setWithCharge(updatedValue === '2')
      handleChangesDiscountOrCharges(updatedValue)
      calculateTotalItem(amountValue, unitPriceValue, 0, 0)
    },
    initialValue: withDiscountOrCharge || ''
  })
  const {
    error: discountError,
    value: discountValue,
    onChange: handleChangeDiscount,
    onError: handleErrordiscount,
    setValue: setDiscount
  } = useInput({
    changeCallback: updatedValue => {
      handleChanges(updatedValue, 'discount')
      calculateTotalItem(amountValue, unitPriceValue, updatedValue, chargeValue)
    },
    formatCallbacks: [value => formatTypeCurrency(currency?.type, value)],
    initialValue: discount || 0,
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorRegEx(getRegexByCurrency(currency?.type))]
  })
  const {
    error: chargeError,
    value: chargeValue,
    onChange: handleChangeCharge,
    onError: handleErrorCharge,
    setValue: setCharge
  } = useInput({
    changeCallback: updatedValue => {
      handleChanges(updatedValue, 'charge')
      calculateTotalItem(amountValue, unitPriceValue, discountValue, updatedValue)
    },
    formatCallbacks: [value => formatTypeCurrency(currency?.type, value)],
    initialValue: charge || 0,
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorRegEx(getRegexByCurrency(currency?.type))]
  })
  const {
    error: codeZGENError,
    value: codeZGENValue,
    onChange: handleChangeZGEN,
    onError: handleErrorZGEN
  } = useInput({
    changeCallback: updatedValue => handleChanges(updatedValue, 'codeZGEN'),
    initialValue: codeZGEN || '',
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorMaxLength(9), checkInputErrorRegEx(ONLY_NUMBERS_CHECKED)]
  })
  const { value: notCodeZGENValue, onChange: handleChangeNotCode } = useCheckBox({
    errorCallbacks: [checkInputErrorRequired()],
    changeCallback: updatedValue => {
      handleChangesDontKnowCodeZGEN(updatedValue)
    },
    initialValue: dontKnowCodeZGEN
  })
  const {
    error: detailError,
    value: detailValue,
    onChange: handleChangeDetail,
    onError: handleErrorDetail
  } = useTextarea({
    changeCallback: updatedValue => handleChanges(updatedValue, 'detail'),
    initialValue: detail || '',
    errorCallbacks: [checkInputErrorMaxLength(300), checkInputErrorRequired()]
  })

  const calculateTotalItem = (amount, unit, discount, charge) => {
    const amountItem = parseInt(amount) || 0
    const unitItem = formatCurrencyToNumber(unit || '0', currency?.type)

    let net = amountItem * unitItem

    if (discountOrChargeValue === '1') {
      const discountItem = formatCurrencyToNumber(discount || '0', currency?.type)
      net = net - discountItem
    } else if (discountOrChargeValue === '2') {
      const chargeItem = formatCurrencyToNumber(charge || '0', currency?.type)
      net = net + chargeItem
    }

    setTotalItem(net)
  }

  const handlerRemoveProduct = () => {
    onRemove(productKey)
  }

  const handlerSaveProduct = () => {
    onSave()
  }

  const disableSave = useMemo(() => {
    return (
      amountValue === '' ||
      amountError !== '' ||
      unitMeasureError !== '' ||
      unitMeasureValue === '' ||
      unitPriceError !== '' ||
      unitPriceValue === '' ||
      detailError !== '' ||
      detailValue === '' ||
      (withCharge && (chargeError !== '' || chargeValue === '')) ||
      (withdiscount && (discountError !== '' || discountValue === '')) ||
      (productId?.toString().startsWith(DIRECT_DEAL_STEP_TWO_PRODUCT_ONU_CODE) &&
        (codeZGENError !== '' || codeZGENValue === '') &&
        !notCodeZGENValue)
    )
  }, [
    amountError,
    amountValue,
    chargeError,
    chargeValue,
    codeZGENError,
    codeZGENValue,
    detailError,
    detailValue,
    discountError,
    discountValue,
    notCodeZGENValue,
    unitMeasureError,
    unitMeasureValue,
    unitPriceError,
    unitPriceValue
  ])

  useEffect(() => {
    if (currency?.value !== prevCurrency.current?.value) {
      handleResetAmounts()
      setDiscount(0)
      setCharge(0)
      setAmount(0)
      setUnitPrice(0)
      calculateTotalItem(0, 0, 0, 0)
    }
  }, [currency])

  return (
    <Card padding="0">
      <GridContainer spacing={1} alignItems="center">
        <GridItem lg={6} md={6} sm={12} xs={12}>
          <Input
            error={amountError}
            label="Cantidad"
            value={amountValue}
            onChange={handleChangeAmount}
            onError={handleErrorAmount}
          />
        </GridItem>
        <GridItem lg={6} md={6} sm={12} xs={12}>
          <Select
            error={unitMeasureError}
            label="Unidad"
            options={unitMeasurement}
            placeholder="Seleccione una unidad"
            value={unitMeasureValue}
            onChange={handleChangeUnitMeasure}
            onError={handleErrorUnitMeasure}
          />
        </GridItem>
        <GridItem lg={6} md={6} sm={12} xs={12}>
          <Input
            error={unitPriceError}
            info={
              <>
                Total items: <FormattedCurrencyComponent amount={totalItem} currency={currency?.type} includeSymbol />
              </>
            }
            label="Valor unitario"
            prefix={<AmountPrefix>{currency?.symbol}</AmountPrefix>}
            value={unitPriceValue}
            onChange={handleChangeUnitPrice}
            onError={handleErrorUnitPrice}
          />
        </GridItem>
        <GridItem lg={6} md={6} sm={12} xs={12}>
          <CheckBox label="Agregar descuentos o cargos" value={adddiscountValue} onChange={handleChangeAdddiscount} />
        </GridItem>
        {withdiscountOrCharge && (
          <>
            <GridItem lg={6} md={6} sm={12} xs={12}>
              <Radio
                direction="horizontal"
                options={DIRECT_DEAL_STEP_TWO_RADIO_OPTIONS_PRODUCTS}
                value={discountOrChargeValue}
                onChange={handleChangeDiscountOrChange}
              />
            </GridItem>
            <GridItem
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{ visibility: withdiscount || withCharge ? 'visible' : 'hidden' }}
            >
              {withdiscount && (
                <Input
                  error={discountError}
                  label="Descuentos"
                  prefix={<AmountPrefix>{currency?.symbol}</AmountPrefix>}
                  value={discountValue}
                  onChange={handleChangeDiscount}
                  onError={handleErrordiscount}
                />
              )}
              {withCharge && (
                <Input
                  error={chargeError}
                  label="Cargos"
                  prefix={<AmountPrefix> {currency?.symbol}</AmountPrefix>}
                  value={chargeValue}
                  onChange={handleChangeCharge}
                  onError={handleErrorCharge}
                />
              )}
            </GridItem>
          </>
        )}

        {productId?.toString().startsWith(DIRECT_DEAL_STEP_TWO_PRODUCT_ONU_CODE) && (
          <>
            <GridItem lg={6} md={6} sm={12} xs={12}>
              <Input
                disabled={notCodeZGENValue}
                error={codeZGENError}
                info="Informado por CENABAST"
                label="Código ZGEN"
                maxCount={9}
                value={codeZGENValue}
                onChange={handleChangeZGEN}
                onError={handleErrorZGEN}
              />
            </GridItem>
            <GridItem lg={6} md={6} sm={12} xs={12}>
              <CheckBox label="No conozco el código ZGEN" value={notCodeZGENValue} onChange={handleChangeNotCode} />
            </GridItem>
          </>
        )}

        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Textarea
            error={detailError}
            label="Detalle"
            maxCount="300"
            value={detailValue}
            onChange={handleChangeDetail}
            onError={handleErrorDetail}
          />
        </GridItem>
        <GridItem lg={6} md={6} sm={6} xs={6}>
          <DeleteLink onClick={handlerRemoveProduct}>Eliminar ítem</DeleteLink>
          <DeleteIconCustom margin="0 0 -3px 5px" fontSize="16px" />
        </GridItem>
        <GridItem lg={6} md={6} sm={6} xs={6} textAlign="right">
          <Button variant="outlined" color="primary" disabled={disableSave} onClick={handlerSaveProduct}>
            Guardar
          </Button>
        </GridItem>
      </GridContainer>
    </Card>
  )
}

DirectDealStepTwoProduct.propTypes = {
  amount: PropTypes.number,
  categoryId: PropTypes.string,
  charge: PropTypes.number,
  codeZGEN: PropTypes.number,
  currency: PropTypes.shape({
    value: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    symbol: PropTypes.string
  }),
  discount: PropTypes.number,
  detail: PropTypes.string,
  dontKnowCodeZGEN: PropTypes.bool,
  productsUpdate: PropTypes.objectOf(
    PropTypes.shape({
      categoryId: PropTypes.number,
      detail: PropTypes.string,
      productId: PropTypes.number,
      productKey: PropTypes.string,
      productName: PropTypes.string,
      amount: PropTypes.number,
      unitMeasure: PropTypes.string,
      unitPrice: PropTypes.number,
      discount: PropTypes.number,
      charge: PropTypes.number,
      codeZGEN: PropTypes.string,
      discountOrCharge: PropTypes.string
    })
  ),
  setProductsUpdate: PropTypes.func,
  productKey: PropTypes.string,
  productId: PropTypes.string,
  productName: PropTypes.string,
  unitMeasure: PropTypes.string,
  unitPrice: PropTypes.number,
  unitMeasurement: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number
    })
  ),
  withDiscountOrCharge: PropTypes.string,
  onRemove: PropTypes.func,
  onSave: PropTypes.func
}

export default DirectDealStepTwoProduct
