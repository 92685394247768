import React from 'react'
import { styled, Divider as DividerBase, Grid as BaseGrid } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export const Root = styled('div')`
  width: 100%;
`
export const Divider = styled(DividerBase)`
  width: 67%;
`
export const Grid = styled(({ ...props }) => <BaseGrid {...props} />)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;
  text-align: ${props => props.textAlign};
`
export const RowRadio = styled('div')`
  text-align: left;
  width: 100%;
  margin-top: 8px;
`

export const SearchIconCustom = styled(SearchIcon)`
  width: 20px;
  height: 20px;
`

export const SearchSuffix = styled('button')`
  border-right: 1px solid ${props => props.theme.palette.blue[50]};
  background-color: ${props => props.theme.palette.blue[50]};
  color: ${props => props.theme.palette.brand.white};
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;

  &:hover {
    background-color: ${props => props.theme.palette.blue[100]}; /* Ejemplo de estilo hover */
  }
`

export const Strong = styled('strong')`
  font-size: 14px;
`
