import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@chilecompra/react-kit/components'

import { GridContainer, GridItem, Root } from './DirectDealStepZero.styles'
import { useViewport } from '@chilecompra/react-kit'

/**
 * The DirectDealStepZeroFooter's container.
 */
const DirectDealStepZeroFooter = props => {
  const { onBack, onContinue, disabled } = props
  const { size } = useViewport()

  return (
    <Root>
      <GridContainer margin="33px 0" spacing={2} alignItems="center">
        <GridItem md={6} sm={12} xs={6} order={{ xs: 2, sm: 1 }}>
          <Button
            color="default"
            variant="outlined"
            onClick={onBack}
            width={size.isDesktop || size.isSmall ? '40%' : '200%'}
          >
            Volver
          </Button>
        </GridItem>
        <GridItem md={6} sm={6} xs={12} order={{ xs: 1, sm: 1 }}>
          <GridContainer spacing={1}>
            <GridItem md={2} sm={6} xs={6}>
              <Button
                color="primary"
                disabled={disabled}
                variant="contained"
                width={size.isDesktop || size.isSmall ? '300%' : '200%'}
                onClick={onContinue}
              >
                Siguiente
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Root>
  )
}

DirectDealStepZeroFooter.propTypes = {
  onBack: PropTypes.func,
  onContinue: PropTypes.func,
  disabled: PropTypes.bool
}

export default DirectDealStepZeroFooter
