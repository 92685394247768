import React from 'react'
import { Container } from './SearchResults.styles'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import SearchResultPaginator from './SearchResults.paginator'
import SearchResultsDesktop from './SearchResults.desktop'
import SearchResultsProvider from './SearchResults.provider'
import SearchResultsError from './SearchResults.error'
import SearchResultsLoading from './SearchResults.loading'
import { useAuthorization } from '../AuthProvider/AuthProvider.hooks'

import routes from '../../config/settings/routes'

/**
 * The SearchResult's container.
 */
const SearchResults = () => {
  const { isBuyer } = useAuthorization()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    loading: loadingSearch,
    error,
    searchResult: { results: data, resultCount, page, pageCount }
  } = useSelector(state => state.searchFilters)

  /**
   * One more page is added to the page because
   * the search service starts with page 0.
   */
  const pageValue = page + 1
  const pageSize = data?.length || 0

  const handleLineColors = state => {
    switch (state) {
      case 'Cancelada':
      case 'Cerrada':
        return '#536479'
      case 'Eliminada':
        return '#E22C2C'
      case 'Publicada':
        return '#0064FF'
      case 'Recibiendo Solicitud':
        return '#2FA12B'
      case 'OC Emitida':
        return '#0064FF'
      case 'Guardada':
        return '#FD7E14'
      default:
        return 'default'
    }
  }

  const handleRedirectDetails = sheetId => {
    navigate(routes.directDealSummary.replace(/:directDealId/, sheetId), { state: { from: location.pathname } })
  }

  return (
    <Container>
      {!loadingSearch && isBuyer ? (
        <SearchResultsDesktop
          directDealSheets={data || []}
          onLineColors={handleLineColors}
          onRedirect={handleRedirectDetails}
        />
      ) : (
        <SearchResultsProvider
          directDealSheets={data || []}
          onLineColors={handleLineColors}
          onRedirect={handleRedirectDetails}
        />
      )}

      {(error || data.length === 0) && !loadingSearch && <SearchResultsError />}
      {loadingSearch && <SearchResultsLoading />}
      {!loadingSearch && (
        <SearchResultPaginator result={resultCount} page={pageValue} size={pageSize} count={pageCount} />
      )}
    </Container>
  )
}

export default SearchResults
