import React from 'react'
import PropTypes from 'prop-types'

import { Button, useViewport } from '@chilecompra/react-kit'
import { Grid } from './ProductSearch.styles'

/**
 * The ProductSearch's footer.
 */
const ProductSearchFooter = props => {
  const { onBack } = props
  const {
    size: { isDownToTiny }
  } = useViewport()

  return (
    <Grid container>
      <Grid item>
        <Button variant="outlined" width={isDownToTiny ? '100%' : undefined} onClick={onBack}>
          Volver
        </Button>
      </Grid>
    </Grid>
  )
}

ProductSearchFooter.propTypes = {
  onBack: PropTypes.func
}
export default ProductSearchFooter
