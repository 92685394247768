import React from 'react'
import PropTypes from 'prop-types'
import { Body, Footer, Header, Root, TopContent } from './BrandLayout.styles'

/**
 * The BrandLayout's component.
 */
const BrandLayout = props => {
  const { children, footer, header, featured } = props

  return (
    <Root>
      <Body container>
        {header && <Header>{header}</Header>}
        {featured && <TopContent>{featured}</TopContent>}
        {children}
      </Body>
      {footer && <Footer>{footer}</Footer>}
    </Root>
  )
}

BrandLayout.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  header: PropTypes.node,
  featured: PropTypes.node
}

export default BrandLayout
