import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import {
  initializeAnalytics,
  initializeLogger,
  inIframe,
  ThemeProvider,
  observerResizeIframe
} from '@chilecompra/react-kit'
import {
  REACT_APP_ENVIRONMENT,
  REACT_APP_GOOGLE_ANALYTICS_KEY,
  REACT_APP_GOOGLE_TASK_MANAGER_KEY,
  REACT_APP_HOTJAR_ID,
  REACT_APP_HOTJAR_SNIPPET_VERSION,
  REACT_APP_MERCADO_PUBLICO_BASE_URL
} from './config/settings/environment'
import storeSingleton from './config/store/store'
import Routing from './Routing'
import SnackbarProvider from './containers/SnackBarProvider/SnackBarProvider.container'

initializeAnalytics({
  gaKey: REACT_APP_GOOGLE_ANALYTICS_KEY,
  gtmId: REACT_APP_GOOGLE_TASK_MANAGER_KEY,
  hotjarId: REACT_APP_HOTJAR_ID,
  hotjarSV: REACT_APP_HOTJAR_SNIPPET_VERSION
})
initializeLogger({ env: REACT_APP_ENVIRONMENT === 'prod' ? 'prod' : 'dev' })

const store = storeSingleton()
const container = document.getElementById('__root')
const root = createRoot(container)

// This enables communication between react and MP applications to resize the fraDetalle iframe.
if (inIframe()) {
  observerResizeIframe(REACT_APP_MERCADO_PUBLICO_BASE_URL, { childList: true })
}

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider>
        <Routing />
        <SnackbarProvider />
      </ThemeProvider>
    </ReduxProvider>
  </StrictMode>
)
