import React from 'react'
import PropTypes from 'prop-types'

import { Button, CircleSuccessIcon, Dialog, Typography } from '@chilecompra/react-kit/components'
import { GridContainer, GridItem } from './DirectDealStepTwo.styles'

/**
 * The DirectDealStepTwo's saved dialog.
 */
const DirectDealStepTwoSavedDialog = props => {
  const { onBack, onClose, open, sheetCode } = props

  return (
    <Dialog open={open} disableBackdropClick verticalAlign="top" onClose={onClose}>
      <GridContainer direction="row" justifyContent="center" alignItems="center">
        <GridItem textAlign="center" padding="13px" xs={12}>
          <CircleSuccessIcon />
          <Typography fontSize="18px" lineHeight="22px" fontWeight="bold" color="black1">
            Guardado
          </Typography>
        </GridItem>
        <GridItem margin="30px 0" textAlign="center" xs={12}>
          <Typography fontSize="16px" lineHeight="21px" color="black1">
            Se ha guardado con éxito la Ficha de Trato Directo ID:
          </Typography>
          <Typography fontSize="16px" lineHeight="21px" color="black1">
            {sheetCode}
          </Typography>
        </GridItem>
        <GridItem padding="0 50px" margin="4px 0" textAlign="center" xs={12}>
          <Button color="primary" variant="contained" onClick={onClose}>
            Continuar con el Trato Directo
          </Button>
        </GridItem>
        <GridItem padding="0 50px" margin="4px 0" textAlign="center" xs={12}>
          <Button variant="text" onClick={onBack}>
            Volver al inicio del módulo
          </Button>
        </GridItem>
      </GridContainer>
    </Dialog>
  )
}

DirectDealStepTwoSavedDialog.propTypes = {
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  sheetCode: PropTypes.string
}

export default DirectDealStepTwoSavedDialog
