import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Button,
  checkInputErrorRequired,
  checkInputErrorOnlyNumbers,
  checkInputErrorMaxLength,
  checkInputErrorEmail,
  useInput,
  useViewport,
  useQueryParam,
  useQuery,
  Skeleton,
  checkInputErrorMinLength
} from '@chilecompra/react-kit'
import { Card, Input, Typography } from '@chilecompra/react-kit/components'

import AttachmentsWrapper from '../../components/components/AttachmentsWrapper.component'

import { onGetInfoDniDirectDealQuoteThunk, onSavedDirectDealQuoteThunk } from './DirectDealQuote.actions'
import { onUpdateQuoteDirectDealStepTwo } from '../DirectDealStepTwo/DirectDealStepTwo.actions'
import { onShowSnackbar } from '../SnackBarProvider/SnackBarProvider.actions'

import { formatDNI } from '../../modules/utils/formatters'
import routes from '../../config/settings/routes'
import { CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL } from '../../config/settings/constants'

import { AmountPrefix, Grid, SearchSuffix, SearchIconCustom } from './DirectDealQuote.styles'

/**
 * The DirectDealQuote's editor container.
 */
const DirectDealQuoteEditor = props => {
  const { quote: quoteModelEdit, loading } = props

  const { size } = useViewport()
  const directDealSheetCode = useQueryParam('code')
  const query = useQuery()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isEditing, setEditing] = useState(false)

  const {
    error: quoteDniError,
    onChange: onChangeQuoteDni,
    onError: onErrorQuoteDni,
    value: quoteDniValue
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()],
    formatCallbacks: [value => formatDNI(value)],
    initialValue: quoteModelEdit?.contributorDni || ''
  })

  const {
    error: quoteLegalNameError,
    onChange: onChangeQuoteLegalName,
    value: quoteLegalNameValue,
    setValue: setQuoteLegalName
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: quoteModelEdit?.contributorLegalName || ''
  })

  const {
    error: quotePhoneError,
    onChange: onChangeQuotePhone,
    onError: onErrorQuotePhone,
    value: quotePhoneValue
  } = useInput({
    errorCallbacks: [
      checkInputErrorRequired(),
      checkInputErrorOnlyNumbers(),
      checkInputErrorMaxLength(9),
      checkInputErrorMinLength(9)
    ],
    initialValue: quoteModelEdit?.contributorPhone || ''
  })

  const {
    error: quoteEmailError,
    onChange: onChangeQuoteEmail,
    onError: onErrorQuoteEmail,
    value: quoteEmailValue
  } = useInput({
    errorCallbacks: [
      checkInputErrorRequired(),
      checkInputErrorMaxLength(200),
      checkInputErrorEmail('Formato de email incorrecto.')
    ],
    initialValue: quoteModelEdit?.contributorEmail || ''
  })

  const handlerBack = () => {
    query.set('code', directDealSheetCode)
    navigate({ pathname: routes.directDealStepTwo, search: query.toString() }, { replace: true })
  }

  const handlerSaved = () => {
    const quoteModel = {
      ...quoteModelEdit,
      contributorDni: quoteDniValue,
      contributorLegalName: quoteLegalNameValue,
      contributorPhone: quotePhoneValue,
      contributorEmail: quoteEmailValue
    }

    dispatch(
      onSavedDirectDealQuoteThunk({
        quoteModel,
        callback: quoteModel => {
          dispatch(onUpdateQuoteDirectDealStepTwo(quoteModel))
          dispatch(
            onShowSnackbar({
              title: 'Cotización actualizada con éxito',
              severity: 'success'
            })
          )
          handlerBack()
        },
        callbackError: () => {
          dispatch(
            onShowSnackbar({
              title: 'No se pudo guardar la cotización.',
              message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
              severity: 'error'
            })
          )
        }
      })
    )
  }

  const handlerGetLegalName = () => {
    dispatch(
      onGetInfoDniDirectDealQuoteThunk({
        quoteDni: quoteDniValue,
        callback: legalName => {
          setQuoteLegalName(legalName)
        },
        callbackError: () => {
          setQuoteLegalName('')
          dispatch(
            onShowSnackbar({
              title: 'Proveedor no encontrado',
              message: 'Te pedimos que lo vuelvas a intentar.',
              severity: 'error'
            })
          )
        }
      })
    )
  }

  const handlerChangeMode = () => {
    setEditing(!isEditing)
  }

  const disableSaved = useMemo(() => {
    return (
      quoteDniValue === '' ||
      quoteDniError !== '' ||
      quoteLegalNameValue === '' ||
      quoteLegalNameError !== '' ||
      quotePhoneValue === '' ||
      quotePhoneError !== '' ||
      quoteEmailValue === '' ||
      quoteEmailError !== ''
    )
  }, [
    quoteDniValue,
    quoteDniError,
    quoteLegalNameValue,
    quoteLegalNameError,
    quotePhoneValue,
    quotePhoneError,
    quoteEmailValue,
    quoteEmailError
  ])

  return (
    <Card color="blank" enableBorder padding="24px 32px">
      <Grid container spacing={2} margin="8px 0 0">
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Input
            disabled={!isEditing}
            error={quoteDniError}
            label="RUT cotizante"
            loading={loading}
            suffix={
              isEditing && (
                <SearchSuffix onClick={handlerGetLegalName}>
                  <SearchIconCustom />
                </SearchSuffix>
              )
            }
            onChange={onChangeQuoteDni}
            onError={onErrorQuoteDni}
            value={quoteDniValue}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Input
            disabled={!isEditing}
            error={quoteLegalNameError}
            label="Razón social cotizante"
            loading={loading}
            onChange={onChangeQuoteLegalName}
            value={quoteLegalNameValue}
          />
        </Grid>
        <Grid item lg={6} md={4} sm={false} xs={false} />
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Input
            disabled={!isEditing}
            error={quotePhoneError}
            label="Teléfono cotizante"
            loading={loading}
            prefix={<AmountPrefix> +56</AmountPrefix>}
            onChange={onChangeQuotePhone}
            onError={onErrorQuotePhone}
            value={quotePhoneValue}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Input
            disabled={!isEditing}
            error={quoteEmailError}
            label="Email cotizante"
            loading={loading}
            type="email"
            onChange={onChangeQuoteEmail}
            onError={onErrorQuoteEmail}
            value={quoteEmailValue}
          />
        </Grid>
        <Grid item lg={6} md={4} sm={false} xs={false} />
        <Grid item lg={6} md={8} sm={12} xs={12}>
          <Typography variant="body1" fontWeight="bold">
            Adjuntar archivos
          </Typography>
          <Typography variant="body2">Se admiten archivos en formato con un peso máximo de 20mb.</Typography>

          {!loading && (
            <AttachmentsWrapper
              business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
              idBusiness={quoteModelEdit?.idBusiness}
              subBusiness={quoteModelEdit?.idSubBusiness}
              isPrivate
              color="blank"
              canRead
              canReadBulk
              canDelete={isEditing}
              canDeleteBulk={isEditing}
              canWrite={isEditing}
              canWriteBulk={isEditing}
              showModal
            />
          )}

          {loading && <Skeleton width="250px" />}
        </Grid>
        <Grid item lg={6} md={4} sm={false} xs={false} />
        <Grid item lg={4} md={6} sm={6} xs={12} textAlign="left" order={{ lg: 1, md: 1, sm: 1, xs: 2 }}>
          <Button width={size.isSmall || size.isTiny ? '100%' : '50%'} onClick={handlerBack}>
            Volver
          </Button>
        </Grid>
        {!isEditing && (
          <Grid item lg={4} md={6} sm={6} xs={12} textAlign="left" order={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
            <Button
              color="primary"
              disabled={disableSaved}
              variant="outlined"
              width={size.isSmall || size.isTiny ? '100%' : '60%'}
              onClick={handlerChangeMode}
            >
              Editar
            </Button>
          </Grid>
        )}
        {isEditing && (
          <Grid item lg={4} md={6} sm={6} xs={12} textAlign="left" order={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
            <Button
              color="primary"
              disabled={disableSaved}
              loading={loading}
              width={size.isSmall || size.isTiny ? '100%' : '60%'}
              onClick={handlerSaved}
            >
              Guardar cotización
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  )
}

DirectDealQuoteEditor.propTypes = {
  quote: PropTypes.shape({
    idQuote: PropTypes.number,
    contributorDni: PropTypes.string,
    contributorLegalName: PropTypes.string,
    contributorPhone: PropTypes.string,
    contributorEmail: PropTypes.string,
    idBusiness: PropTypes.number,
    idSubBusiness: PropTypes.number
  }),
  loading: PropTypes.bool
}

export default DirectDealQuoteEditor
