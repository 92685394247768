import React from 'react'
import { styled, Grid as BaseGrid } from '@mui/material'

export const Root = styled('div')`
  width: 100%;
  background-color: #001c41;
  color: #fff;
  padding: 32px;
  margin-top: 90px;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
`

export const Grid = styled(({ ...props }) => <BaseGrid {...props} />)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;
  text-align: ${props => props.textAlign};
`

export const Logo = styled('img')`
  height: auto;
  pointer-events: none;
  user-select: none;
  width: 215px;
`

export const LogoLink = styled('a')`
  cursor: pointer;
  float: left;
  margin: 0 20px 0 0;
`

export const Link = styled('a')`
  cursor: pointer;
  color: white;
  font-size: 12px;
  display: block;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: #044498;
  }
`

export const SocialMediaContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const SocialIcons = styled('div')`
  display: flex;
  gap: 10px;
`

export const Icon = styled('img')`
  width: 24px;
  height: 24px;
`
