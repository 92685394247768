import { REACT_APP_CAPTCHA_ENABLED, REACT_APP_CAPTCHA_SITE_KEY } from '../../config/settings/environment'

/**
 * Custom hook to get a reCaptcha token.
 */
export const useReCaptcha = (isPrivate, action = 'search') => {
  const executeReCaptcha = () =>
    new Promise(resolve => {
      if (!(!isPrivate && REACT_APP_CAPTCHA_ENABLED === 'true')) {
        return resolve('')
      }

      return window.grecaptcha.ready(() => {
        window.grecaptcha.execute(REACT_APP_CAPTCHA_SITE_KEY, { action }).then(token => {
          resolve(token)
        })
      })
    })

  return { executeReCaptcha }
}

/**
 * To initialize reCaptcha.
 */
export const initializeReCaptcha = () => {
  if (REACT_APP_CAPTCHA_ENABLED !== 'true') {
    return
  }

  const script = document.createElement('script')
  script.src = `https://www.google.com/recaptcha/api.js?render=${REACT_APP_CAPTCHA_SITE_KEY}`
  document.body.appendChild(script)
}
