import { makeActionCreator } from '../../config/store/utils'

export const SAVED_INFO_DIRECT_DEAL_VOUCHER = 'SAVED_INFO_DIRECT_DEAL_VOUCHER'
export const onSavedInfoDirectDealVoucher = makeActionCreator(SAVED_INFO_DIRECT_DEAL_VOUCHER, 'payload')

export const OPEN_CONFIRM_ISSUE_OC_DIRECT_DEAL_VOUCHER = 'OPEN_CONFIRM_ISSUE_OC_DIRECT_DEAL_VOUCHER'
export const onOpenConfirmIssueDirectDealVoucher = makeActionCreator(OPEN_CONFIRM_ISSUE_OC_DIRECT_DEAL_VOUCHER)

export const CLOSE_CONFIRM_ISSUE_OC_DIRECT_DEAL_VOUCHER = 'CLOSE_CONFIRM_ISSUE_OC_DIRECT_DEAL_VOUCHER'
export const onCloseConfirmIssueDirectDealVoucher = makeActionCreator(CLOSE_CONFIRM_ISSUE_OC_DIRECT_DEAL_VOUCHER)

export const CLEAN_DIRECT_DEAL_VOUCHER = 'CLEAN_DIRECT_DEAL_VOUCHER'
export const onCleanDirectDealVoucher = makeActionCreator(CLEAN_DIRECT_DEAL_VOUCHER)
