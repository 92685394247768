import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Button, DataTable, Typography } from '@chilecompra/react-kit'

import {
  onStorageIdDirectDealQuote,
  onGenerateDirectDealQuoteThunk
} from '../../DirectDealQuote/DirectDealQuote.actions'
import { onShowSnackbar } from '../../SnackBarProvider/SnackBarProvider.actions'

import {
  onRemoveQuoteDirectDealStepTwoThunk,
  onOpenQuoteDirectDealStepTwo,
  onOpenDetailQuoteDirectDealStepTwo
} from '../DirectDealStepTwo.actions'

import DirectDealStepTwoQuoteDialog from '../Quotes/DirectDealStepTwo.quote.dialog'
import DirectDealStepTwoDetailQuoteDialog from './DirectDealStepTwo.quote.detail.dialog'

import {
  ArrowButton,
  GridContainer,
  GridItem,
  Link,
  PlusIcon,
  DeleteIconCustom,
  DeleteLink
} from '../DirectDealStepTwo.styles'

import { dialogScrollTop } from '../../../modules/utils/dialogScrollTop'

/**
 * The DirectDealStepTwo quotes container.
 */
const DirectDealStepTwoQuotes = props => {
  const { code, onAdd } = props

  const dispatch = useDispatch()

  const { quotes, loading: stepTwoLoading, dialog } = useSelector(state => state.directDealStepTwo)
  const { quote, loading: quoteLoading, idQuoteEdit } = useSelector(state => state.directDealQuote)

  const handlerOpenQuoteEdit = idQuote => {
    return () => {
      onAdd()
      dispatch(onStorageIdDirectDealQuote(idQuote))
      dispatch(onOpenDetailQuoteDirectDealStepTwo())
      dialogScrollTop()
    }
  }

  const handlerOpenQuote = () => {
    dispatch(
      onGenerateDirectDealQuoteThunk({
        code,
        callback: () => {
          onAdd()
          dispatch(onOpenQuoteDirectDealStepTwo())
          dialogScrollTop()
        }
      })
    )
  }

  const handlerRemoveQuote = idQuote => {
    return () => {
      dispatch(
        onRemoveQuoteDirectDealStepTwoThunk({
          idQuote,
          callback: () =>
            dispatch(
              onShowSnackbar({
                title: 'Cotización removida con éxito.',
                severity: 'success'
              })
            ),
          callbackError: () =>
            dispatch(
              onShowSnackbar({
                title: 'No ha sido posible remover la cotización.',
                message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
                severity: 'error'
              })
            )
        })
      )
    }
  }

  return (
    <>
      <GridContainer margin="33px 0" spacing={2}>
        <GridItem lg={2} md={2} sm={12} xs={12}>
          <Typography variant="body1" fontWeight="bold">
            Datos de cotización
          </Typography>
          <Typography variant="body2">
            En caso de que hayas realizado un proceso de cotización previo, puedes adjuntar dicha información a tu Trato
            Directo.
          </Typography>
        </GridItem>

        <GridItem lg={6} md={6} sm={12} xs={12}>
          <Typography variant="body2" fontWeight="bold">
            Cotizaciones
          </Typography>
          <Typography variant="body2" margin="0 0 17px">
            Para incluir la o las cotizaciones recibidas haz clic en "Crear nueva cotización", completa el formulario
            con la información del proveedor y adjunta tus archivos.
          </Typography>

          {quotes && quotes.length > 0 && (
            <DataTable
              accessibility={{ label: 'Cotizaciones' }}
              headers={[
                { label: 'Rut', value: 'contributorDni' },
                { label: 'Razón social', value: 'contributorLegalName' },
                { label: '', value: '' },
                { label: '', value: '' }
              ]}
              headersCellsProps={[
                { enableSort: true },
                { enableSort: true },
                { enableSort: false },
                { enableSort: false }
              ]}
              enableStripped
              loading={stepTwoLoading}
              records={quotes}
              recordsDecorators={[
                ({ contributorDni }) => (
                  <Typography variant="body2" lineHeight="18px">
                    {contributorDni}
                  </Typography>
                ),
                ({ contributorLegalName }) => (
                  <Typography variant="body2" lineHeight="18px">
                    {contributorLegalName}
                  </Typography>
                ),
                ({ idQuote }) => (
                  <>
                    <Typography variant="body2">
                      <Link fontWeight="bold" onClick={handlerOpenQuoteEdit(idQuote)}>
                        Ver detalle <ArrowButton margin="0 0 -3px 4px" fontSize="16px" />
                      </Link>
                    </Typography>
                  </>
                ),
                ({ idQuote }) => (
                  <Typography variant="body2">
                    <DeleteLink fontWeight="bold" onClick={handlerRemoveQuote(idQuote)}>
                      Eliminar cotización <DeleteIconCustom margin="0 0 -3px 4px" fontSize="16px" />
                    </DeleteLink>
                  </Typography>
                )
              ]}
            />
          )}
          {!stepTwoLoading && (
            <Button variant="outlined" color="primary" onClick={handlerOpenQuote}>
              <PlusIcon /> Crear nueva cotización
            </Button>
          )}
        </GridItem>
      </GridContainer>
      <DirectDealStepTwoQuoteDialog open={dialog.openQuote} quote={quote} loading={quoteLoading} />
      <DirectDealStepTwoDetailQuoteDialog
        open={dialog.openQuoteDetail}
        quotes={quotes}
        quoteId={idQuoteEdit}
        loading={quoteLoading}
      />
    </>
  )
}

DirectDealStepTwoQuotes.propTypes = {
  code: PropTypes.string,
  onAdd: PropTypes.func
}

export default DirectDealStepTwoQuotes
