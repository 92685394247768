import { factoryService, getJsonRequest } from '@chilecompra/react-kit/requests'
import { REACT_APP_API_BASE_URL } from '../config/settings/environment'

/**
 * Gets a list of providers by the given pattern.
 */
export const getProviders = factoryService(({ pattern }) =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/organismo/autocomplete?q=${pattern}&searchType=1`)
)

/**
 * Gets a list of organizations by the given pattern.
 */
export const getOrganizations = factoryService(({ pattern }) =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/organismo/autocomplete?q=${pattern}&searchType=2`)
)

/**
 * Gets a list of items by the given pattern.
 */
export const getItems = factoryService(({ pattern }) =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/organismo/autocomplete/rubro?rubroProveedor=${pattern}`)
)

/**
 * Gets info by DNI Provider
 */
export const getInfoProviderByDni = factoryService(({ dni }) =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/organismo/proveedor/${dni}`)
)
