import React from 'react'

import { styled, Grid as BaseGrid } from '@mui/material'
import { ShoppingCartOutlined } from '@mui/icons-material'
import { Button } from '@chilecompra/react-kit'

export const Root = styled('div')`
  display: flex;
  height: auto;
  margin: 0;
  width: 100%;
  padding: 7px 32px;
`

export const Logo = styled('img')`
  height: auto;
  pointer-events: none;
  user-select: none;
  width: 215px;
`

export const LogoLink = styled('a')`
  cursor: pointer;
  float: left;
  margin: 0 20px 0 0;
`

export const ExternalLink = styled(({ children, color, display, margin, ...props }) => <a {...props}>{children}</a>)`
  color: ${({ color, theme }) => theme.palette.MP[color].main};
  display: ${props => props.display};
  font-size: 14px;
  margin: ${props => props.margin};
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: ${({ color, theme }) => theme.palette.MP[color].hover};
    text-decoration: none;
  }
`

export const ShoppingCart = styled(ShoppingCartOutlined)`
  color: inherit;
  height: 14px;
  margin: -4px 8px 0 8px;
  vertical-align: middle;
  width: 14px;
`

export const LinkButton = styled(({ children, display, margin, padding, variant, ...props }) => (
  <a {...props}>{children}</a>
))`
  background: ${props => (props.variant === 'outlined' ? 'none' : props.theme.palette.MP.endeavour)};
  border: ${props => (props.variant === 'outlined' ? `1px solid ${props.theme.palette.MP.endeavour}` : 'none')};
  color: ${props =>
    props.variant === 'outlined' ? props.theme.palette.MP.endeavour : props.theme.palette.brand.white};
  cursor: pointer;
  display: inline-block;
  display: ${props => props.display ?? 'inline-block'};
  font-size: 14px;
  font-weight: 400;
  height: 20px;
  line-height: 1.42857143;
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover {
    color: ${props => props.theme.palette.brand.white};
    background: ${props =>
      props.variant === 'outlined' ? props.theme.palette.MP.endeavour : props.theme.palette.MP.congressBlue};
    text-decoration: none;
  }
`

export const Container = styled(({ children, display, float, width, ...props }) => (
  <BaseGrid container {...props}>
    {children}
  </BaseGrid>
))`
  &.MuiGrid-container {
    display: ${props => props.display};
    float: ${props => props.float};
    width: ${props => props.width};
  }
`

export const Item = styled(({ children, width, ...props }) => (
  <BaseGrid item {...props}>
    {children}
  </BaseGrid>
))`
  width: ${props => props.width};
`
export const ExternalButton = styled(Button)`
  &.MuiButtonBase-root {
    background-color: ${props => props.theme.palette.blue[95]};
    border: none;
    border-radius: 0;
    color: ${props => props.theme.palette.MP.endeavour};
    font-size: 12px;
    height: 48px;
    margin: 0 15px 10px;
    padding: 10px 25px;
    width: 166px;
  }
  &.MuiButtonBase-root:hover {
    background-color: ${props => props.theme.palette.MP.endeavour};
    color: ${props => props.theme.palette.white};
  }
`
