import { useContext } from 'react'
import ConfigContext from './ConfigProvider.context'

/**
 * Hook to use stored data to obtain Region's data
 */
export const useConfig = () => {
  const { regionInfo } = useContext(ConfigContext)

  return { regionInfo }
}
