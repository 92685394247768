import React from 'react'
import PropTypes from 'prop-types'

import { Card, DataTable, Typography } from '@chilecompra/react-kit/components'

import { Grid, Link } from './DirectDealSummary.styles'

import FormattedCurrency from '../../components/components/FormattedCurrency.component'
import FormattedDateComponent from '../../components/components/FormattedDate.component'

/**
 * The DirectDealSummary's list purchases.
 */
const DirectDealSummaryPurchases = props => {
  const { loading, purchases, onRedirect } = props
  return (
    <Grid container spacing={3} margin="0">
      <Grid item md={8} xs={12}>
        <Card enableBorder lineColor="primary" color="blank" padding="32px 24px">
          <Grid container>
            <Grid item>
              <Typography fontWeight="bold" variant="h3">
                Órdenes de compra emitidas
              </Typography>
            </Grid>
            <Grid item margin="24px 0 0">
              <DataTable
                accessibility={{ label: 'Órdenes de compra emitidas' }}
                headers={[
                  { label: 'Orden de compra', value: 'code' },
                  { label: 'Fecha de envío', value: 'date' },
                  { label: 'Monto total', value: 'total' },
                  { label: 'Estado', value: 'status' }
                ]}
                headersCellsProps={[
                  { enableSort: true },
                  { enableSort: true },
                  { enableSort: true },
                  { enableSort: true }
                ]}
                enableStripped
                initialRows="5"
                loading={loading}
                records={purchases}
                recordsDecorators={[
                  ({ code }) => <Link onClick={() => onRedirect(code)}>{code}</Link>,
                  ({ date }) => (
                    <Typography variant="body2" lineHeight="18px">
                      <FormattedDateComponent date={date} />
                    </Typography>
                  ),
                  ({ total }) => (
                    <Typography variant="body2" lineHeight="18px">
                      <FormattedCurrency amount={total} symbol />
                    </Typography>
                  ),
                  ({ status }) => (
                    <Typography variant="body2" lineHeight="18px">
                      {status}
                    </Typography>
                  )
                ]}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

DirectDealSummaryPurchases.propTypes = {
  loading: PropTypes.bool,
  purchases: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      total: PropTypes.number,
      date: PropTypes.string,
      code: PropTypes.string
    })
  ),
  onRedirect: PropTypes.func
}

export default DirectDealSummaryPurchases
