import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { Input, DatePicker, Typography, Select, Radio } from '@chilecompra/react-kit/components'
import {
  checkInputErrorMaxLength,
  checkInputErrorRequired,
  Textarea,
  useDatePicker,
  useEffectOnce,
  useInput,
  useRadio,
  useSelect
} from '@chilecompra/react-kit'

import {
  DIRECT_DEAL_STEP_TWO_TYPE_CONTRACT_OPTIONS,
  CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL,
  DIRECT_DEAL_STEP_TWO_CONTRACT_DURATION_DICTIONARY
} from '../../config/settings/constants'
import { useImperativeFormRef } from '../../modules/hooks/useFormRef'

import { onShowSnackbar } from '../SnackBarProvider/SnackBarProvider.actions'

import { GridContainer, GridItem } from './DirectDealStepTwo.styles'

import AttachmentsWrapper from '../../components/components/AttachmentsWrapper.component'

/**
 * The DirectDealStepTwoGeneralInformation's container.
 */
const DirectDealStepTwoGeneralInformation = props => {
  const {
    contracts,
    contractDurations,
    currencies,
    formStepTwoRef,
    loading,
    idBusinessSheet,
    subBusinessSheet,
    storage
  } = props

  const [contractTypeOptions, setContractTypeOptions] = useState([])
  const dispatch = useDispatch()

  const {
    error: nameSheetError,
    value: nameSheetValue,
    onChange: nameSheetOnChange
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(100), checkInputErrorRequired()],
    initialValue: storage?.nameSheet || ''
  })

  const {
    error: descriptionValueError,
    value: descriptionValue,
    onChange: descriptionValueOnChange
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(500), checkInputErrorRequired()],
    initialValue: storage?.description || ''
  })

  const {
    error: justificationValueError,
    value: justificationValue,
    onChange: justificationValueOnChange
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(500), checkInputErrorRequired()],
    initialValue: storage?.justification || ''
  })

  const {
    error: durationError,
    value: duration,
    onChange: handledurationChange
  } = useSelect({
    changeCallback: () => setValueDateFinishExecute(new Date()),
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: storage?.duration || ''
  })

  const {
    error: contractTypeError,
    value: contractType,
    onChange: handleContractTypeChange,
    setValue: setContractTypeValue
  } = useSelect({
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: storage?.contractType || ''
  })

  const {
    error: coinError,
    value: coin,
    onChange: handleCoinChange
  } = useSelect({
    initialValue: storage?.coin || '',
    errorCallbacks: [checkInputErrorRequired()],
    changeCallback: () =>
      dispatch(
        onShowSnackbar({
          title: 'Moneda cambiada',
          message: 'Se han limpiado todos los valores.',
          severity: 'warning'
        })
      )
  })

  const {
    error: dateFinishExecuteError,
    value: dateFinishExecuteValue,
    onChange: handleDateFinishExecuteChange,
    setValue: setValueDateFinishExecute
  } = useDatePicker({
    initialValue: storage?.dateFinishExecute || new Date()
  })

  const { value: contractCategoryValue, onChange: contractCategoryOnChange } = useRadio({
    changeCallback: updateValue => {
      setContractTypeValue('')
      setContractTypeByContractCategory(updateValue)
    },
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: storage?.contractCategory || ''
  })

  const setContractTypeByContractCategory = category => {
    if (category === '1') {
      setContractTypeOptions(contracts?.GOODS)
    } else {
      setContractTypeOptions(contracts?.SERVICES)
    }
  }

  useEffectOnce(() => {
    setContractTypeByContractCategory(contractCategoryValue)
  })

  useImperativeFormRef(
    formStepTwoRef,
    () => ({
      coin,
      coinError,
      contractType,
      contractTypeError,
      dateFinishExecuteError,
      dateFinishExecuteValue,
      descriptionValue,
      descriptionValueError,
      duration,
      durationError,
      justificationValue,
      justificationValueError,
      nameSheetError,
      nameSheetValue,
      contractCategoryValue
    }),
    [
      coin,
      coinError,
      contractType,
      contractTypeError,
      dateFinishExecuteError,
      dateFinishExecuteValue,
      descriptionValue,
      descriptionValueError,
      duration,
      durationError,
      justificationValue,
      justificationValueError,
      nameSheetError,
      nameSheetValue,
      contractCategoryValue
    ]
  )

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2}>
        <Typography variant="body1" fontWeight="bold">
          Información general
        </Typography>
        <Typography fontWeight="regular" lineHeight="16px" variant="body2" padding="4px 0 0">
          Ingresa los datos generales de tu contratación.
        </Typography>
      </GridItem>

      <GridItem xs={12} md={6}>
        <GridContainer spacing={3}>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Typography variant="body1" fontWeight="bold">
              Causal de Trato Directo
            </Typography>
            <Typography fontWeight="regular" lineHeight="16px" variant="body1" padding="4px 0 0">
              Confianza y seguridad de los proveedores, derivados de su experiencia
            </Typography>
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Input
              error={nameSheetError}
              info="Este será el nombre que tendrá el Trato Directo y la orden de compra"
              label="Nombre"
              loading={loading}
              maxCount={100}
              onChange={nameSheetOnChange}
              value={nameSheetValue}
            />
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Textarea
              error={descriptionValueError}
              label="Descripción"
              loading={loading}
              maxCount={500}
              onChange={descriptionValueOnChange}
              size="large"
              value={descriptionValue}
            />
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Textarea
              error={justificationValueError}
              label="Justificación del Trato Directo"
              loading={loading}
              maxCount={500}
              onChange={justificationValueOnChange}
              size="large"
              value={justificationValue}
            />
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Typography fontSize="16px" fontWeight="bold">
              Adjuntar archivos
            </Typography>
            <Typography fontSize="14px" lineHeight="18px" margin="0 0 13px">
              Adjunta documentos que justifiquen la realización de la contratación excepcional. Se admiten archivos con
              un peso máximo de 20mb.
            </Typography>

            {!loading && (
              <AttachmentsWrapper
                business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
                idBusiness={idBusinessSheet}
                subBusiness={subBusinessSheet}
                isPrivate
                color="blank"
                canDelete
                canDeleteBulk
                canRead
                canReadBulk
                canWrite
                canWriteBulk
                showModal
              />
            )}
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xs={12}>
            <Select
              error={durationError}
              label="Duración del contrato"
              loading={loading}
              onChange={handledurationChange}
              options={contractDurations}
              placeholder="Seleccione la duración del contrato"
              value={duration}
            />
          </GridItem>
          {duration === DIRECT_DEAL_STEP_TWO_CONTRACT_DURATION_DICTIONARY.FOR_PERIOD && (
            <GridItem lg={6} md={6} sm={12} xs={12}>
              <DatePicker
                error={dateFinishExecuteError}
                format="dd/MM/yyyy"
                label="Fecha de Término de ejecución"
                loading={loading}
                value={dateFinishExecuteValue}
                typing={false}
                onChange={handleDateFinishExecuteChange}
              />
            </GridItem>
          )}
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Radio
              label="Categoría del Contrato"
              loading={loading}
              onChange={contractCategoryOnChange}
              options={DIRECT_DEAL_STEP_TWO_TYPE_CONTRACT_OPTIONS}
              value={contractCategoryValue}
            />
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Select
              error={contractTypeError}
              label="Tipo de contrato"
              loading={loading}
              onChange={handleContractTypeChange}
              options={contractTypeOptions}
              placeholder="Selecciona el tipo de contrato"
              value={contractType}
            />
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Select
              error={coinError}
              label="Moneda"
              loading={loading}
              onChange={handleCoinChange}
              options={currencies}
              placeholder="Selecciona el tipo de moneda"
              value={coin}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

DirectDealStepTwoGeneralInformation.propTypes = {
  idBusinessSheet: PropTypes.number,
  subBusinessSheet: PropTypes.number,
  contracts: PropTypes.shape({
    GOODS: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, name: PropTypes.string })),
    SERVICES: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, name: PropTypes.string }))
  }),
  contractDurations: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, name: PropTypes.string })),
  currencies: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, name: PropTypes.string })),
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  loading: PropTypes.bool,
  storage: PropTypes.shape({
    coin: PropTypes.string,
    contractType: PropTypes.string,
    dateFinishExecute: PropTypes.instanceOf(Date),
    description: PropTypes.string,
    duration: PropTypes.string,
    justification: PropTypes.string,
    nameSheet: PropTypes.string,
    contractCategory: PropTypes.string
  })
}
export default DirectDealStepTwoGeneralInformation
