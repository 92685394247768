import {
  GET_CAUSE,
  GET_CAUSE_ERROR,
  GET_CAUSE_SUCCESS,
  GET_DIRECT_DEAL_SEARCH,
  GET_DIRECT_DEAL_SEARCH_ERROR,
  GET_DIRECT_DEAL_SEARCH_SUCCESS,
  GET_STATUS,
  GET_STATUS_ERROR,
  GET_STATUS_SUCCESS,
  SET_CAUSE_CRITERIA
} from './SearchAndFilterBar.actions'

const searchAndFilterReducerState = {
  error: undefined,
  loading: false,
  searchResult: {
    page: 1,
    pageCount: 0,
    pageSize: 0,
    resultCount: 0,
    results: []
  },
  causes: [],
  state: [],
  causeCriteria: undefined
}

/**
 * The quote search heading filters's reducer.
 */
const searchAndFilterReducer = (state = searchAndFilterReducerState, { payload, type }) => {
  switch (type) {
    case GET_DIRECT_DEAL_SEARCH:
    case GET_STATUS:
    case GET_CAUSE: {
      return { ...state, loading: true }
    }
    case GET_DIRECT_DEAL_SEARCH_ERROR:
    case GET_STATUS_ERROR:
    case GET_CAUSE_ERROR: {
      return { ...state, error: payload.error, loading: false }
    }
    case GET_CAUSE_SUCCESS: {
      return {
        ...state,
        error: undefined,
        loading: false,
        causes: payload.causes
      }
    }
    case GET_STATUS_SUCCESS: {
      return {
        ...state,
        error: undefined,
        loading: false,
        state: payload.state
      }
    }
    case GET_DIRECT_DEAL_SEARCH_SUCCESS: {
      return {
        ...state,
        error: undefined,
        loading: false,
        searchResult: {
          page: payload.page,
          pageCount: payload.pageCount,
          pageSize: payload.pageSize,
          resultCount: payload.resultCount,
          results: payload.results
        }
      }
    }
    case SET_CAUSE_CRITERIA: {
      return {
        ...state,
        causeCriteria: payload
      }
    }
    default: {
      return state
    }
  }
}

export default searchAndFilterReducer
