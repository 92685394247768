import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Typography, Radio, Input, DatePicker } from '@chilecompra/react-kit/components'

import { SearchSuffix, SearchIconCustom, GridItem, GridContainer } from './DirectDealStepZero.styles'
import { DIRECT_DEAL_STEP_ZERO_OPTIONS } from '../../config/settings/constants'
import {
  checkInputErrorRequired,
  checkInputErrorMaxLength,
  useInput,
  useDatePicker,
  useRadio
} from '@chilecompra/react-kit'
import { formatDNI, unformatDNI } from '../../modules/utils/formatters'
import { onGetSignerInfoDNIThunk } from './DirectDealStepZero.actions'

/**
 * The DirectDealOrigin's
 */
const DirectDealOrigin = props => {
  const { requesterInfo, loading, formRef, onFormValidChange } = props
  const dispatch = useDispatch()
  const [isFormValid, setIsFormValid] = useState(false)
  const [currentRadioOption, setcurrentRadioOption] = useState('1')

  const {
    error: resolutionNumberError,
    onChange: resolutionNumberInputChange,
    onError: updatedresolutionNumberError,
    value: resolutionNumberValueInput,
    setValue: setResolutionNumberValueInput
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(99), checkInputErrorRequired()]
  })

  const {
    error: endDateExecuteError,
    value: endDateExecuteValue,
    onChange: handleendDateExecuteChange,
    setValue: setEndDateExecuteValue
  } = useDatePicker({})

  const {
    error: signerError,
    onChange: signerInputChange,
    onError: updatedSignerError,
    value: signerValueInput,
    setValue: setSignerValueInput
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()],
    formatCallbacks: [value => formatDNI(value)]
  })

  const {
    error: nameError,
    onChange: nameInputChange,
    onError: updatedNameError,
    value: nameValueInput,
    setValue: setNameValueInput
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()]
  })

  const {
    error: lastNameError,
    onChange: lastNameInputChange,
    onError: updatedLastNameError,
    value: lastNameValueInput,
    setValue: setLastNameValueInput
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()]
  })

  const {
    error: chargeError,
    onChange: chargeInputChange,
    onError: updatedChargeError,
    value: chargeValueInput,
    setValue: setChargeValueInput
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()]
  })

  const { value: selectedRadioOption, onChange: handleSelectedRadioOption } = useRadio({
    errorCallbacks: [checkInputErrorRequired()],
    changeCallback: updatedValue => {
      handleSelectedRadioChange(updatedValue)
    }
  })

  const handleSelectedRadioChange = value => {
    setcurrentRadioOption(value)
  }

  useEffect(() => {
    let isValid
    if (currentRadioOption === '2') {
      isValid = true
    } else {
      isValid =
        resolutionNumberValueInput.trim() !== '' &&
        endDateExecuteValue !== null &&
        signerValueInput.trim() !== '' &&
        nameValueInput.trim() !== '' &&
        lastNameValueInput.trim() !== '' &&
        chargeValueInput.trim() !== ''
    }

    setIsFormValid(isValid)
    onFormValidChange(isValid)
  }, [
    currentRadioOption,
    resolutionNumberValueInput,
    endDateExecuteValue,
    signerValueInput,
    nameValueInput,
    lastNameValueInput,
    chargeValueInput
  ])

  const handlerSearchDni = () => {
    dispatch(onGetSignerInfoDNIThunk({ dni: unformatDNI(signerValueInput) }))
  }

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current = {
        selectedRadioOption,
        resolutionNumberError,
        resolutionNumberValueInput,
        endDateExecuteError,
        endDateExecuteValue,
        signerError,
        signerValueInput,
        nameError,
        nameValueInput,
        lastNameError,
        lastNameValueInput,
        chargeError,
        chargeValueInput
      }
    }
    const isValid =
      resolutionNumberValueInput.trim() !== '' &&
      endDateExecuteValue !== null &&
      signerValueInput.trim() !== '' &&
      nameValueInput.trim() !== '' &&
      lastNameValueInput.trim() !== '' &&
      chargeValueInput.trim() !== ''

    setIsFormValid(isValid)
  }, [
    currentRadioOption,
    resolutionNumberValueInput,
    endDateExecuteValue,
    signerValueInput,
    nameValueInput,
    lastNameValueInput,
    chargeValueInput
  ])
  console.log('Validación necesaria para controlar', isFormValid)

  useEffect(() => {
    setNameValueInput(requesterInfo?.names || '')
    setLastNameValueInput(requesterInfo?.lastNames || '')
  }, [requesterInfo])

  useEffect(() => {
    if (currentRadioOption === '2') {
      setResolutionNumberValueInput('')
      setEndDateExecuteValue(null)
      setSignerValueInput('')
      setNameValueInput('')
      setLastNameValueInput('')
      setChargeValueInput('')
    }
  }, [currentRadioOption])

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2} width="114%">
        <Typography fontWeight="bold" variant="body2" fontSize="16px" padding="0 0 16px">
          Origen del Trato Directo
        </Typography>
        <Typography fontSize="14px" fontWeight="regular" lineHeight="18px" variant="body2">
          Indícanos si este nuevo Trato Directo proviene de una resolución o decreto que haya sido firmado antes de la
          entrada en vigencia de la nueva ley de compras públicas N°19.886.
        </Typography>
      </GridItem>

      <GridItem xs={12} md={6.5}>
        <GridContainer spacing={3}>
          <GridItem item xs={12} md={12}>
            <Typography fontWeight="bold" variant="body2" fontSize="16px" padding="0 8px 16px">
              ¿El Trato Directo proviene de una resolución firmada antes del día 12 de diciembre de 2024?
            </Typography>
            <Radio
              setValue={handleSelectedRadioChange}
              onChange={handleSelectedRadioOption}
              options={DIRECT_DEAL_STEP_ZERO_OPTIONS}
              value={selectedRadioOption}
            />
          </GridItem>
          {selectedRadioOption === '1' && (
            <GridContainer margin="33px 16px" spacing={2}>
              <GridItem lg={6} md={6} sm={12} xs={12}>
                <Input
                  label="Número de resolución o decreto"
                  value={resolutionNumberValueInput}
                  onChange={resolutionNumberInputChange}
                  onError={updatedresolutionNumberError}
                  error={resolutionNumberError}
                  maxLength="100"
                />
              </GridItem>
              <GridItem lg={6} md={6} sm={12} xs={12}>
                <DatePicker
                  error={endDateExecuteError}
                  format="dd/MM/yyyy"
                  label="Fecha de resolución o decreto"
                  value={endDateExecuteValue}
                  typing={false}
                  onChange={handleendDateExecuteChange}
                />
              </GridItem>
              <GridItem lg={6} md={6} sm={12} xs={12}>
                <Input
                  label="RUT Firmante"
                  value={signerValueInput}
                  onChange={signerInputChange}
                  onError={updatedSignerError}
                  error={signerError}
                  suffix={
                    <SearchSuffix onClick={handlerSearchDni}>
                      <SearchIconCustom />
                    </SearchSuffix>
                  }
                />
              </GridItem>
              <GridItem md={6} />
              <GridItem lg={6} md={6} sm={12} xs={12}>
                <Input
                  label="Nombre"
                  value={nameValueInput}
                  onChange={nameInputChange}
                  onError={updatedNameError}
                  error={nameError}
                  loading={loading}
                  disabled
                />
              </GridItem>
              <GridItem lg={6} md={6} sm={12} xs={12}>
                <Input
                  label="Apellido"
                  value={lastNameValueInput}
                  onChange={lastNameInputChange}
                  onError={updatedLastNameError}
                  error={lastNameError}
                  loading={loading}
                  disabled
                />
              </GridItem>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <Input
                  label="Cargo"
                  value={chargeValueInput}
                  onChange={chargeInputChange}
                  onError={updatedChargeError}
                  error={chargeError}
                />
              </GridItem>
            </GridContainer>
          )}
        </GridContainer>
      </GridItem>
      <GridItem md={2}> </GridItem>
    </GridContainer>
  )
}

DirectDealOrigin.propTypes = {
  loading: PropTypes.bool,
  requesterInfo: PropTypes.shape({
    names: PropTypes.string,
    lastNames: PropTypes.string
  }),
  formRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  onFormValidChange: PropTypes.func
}

export default DirectDealOrigin
