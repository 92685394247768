import React from 'react'
import PropTypes from 'prop-types'

import { formatOtherCurrencyChile, formatCurrencyUSD, formatCLP } from '../../modules/utils/formatters'
import { CURRENCY_TRANSLATION } from '../../config/settings/constants'

/**
 * The FormattedCurrency component.
 */
const FormattedCurrencyComponent = props => {
  const { amount, currency, includeSymbol = false } = props
  let formattedAmount = '0'

  if (currency === 'CLF' || currency === 'UTM') {
    formattedAmount = formatOtherCurrencyChile(amount)
  } else if (currency === 'USD') {
    formattedAmount = formatCurrencyUSD(amount)
  } else if (currency === 'CLP') {
    formattedAmount = formatCLP(amount)
  }

  return (
    <>
      {includeSymbol && CURRENCY_TRANSLATION[currency]?.symbol}
      {amount && formattedAmount}
    </>
  )
}

FormattedCurrencyComponent.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  includeSymbol: PropTypes.bool
}

export default FormattedCurrencyComponent
