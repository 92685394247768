import React from 'react'
import { Spinner, Typography } from '@chilecompra/react-kit/components'
import { Column, ErrorDiv, GridContainer, GridItem, Row } from './SearchResults.styles'

const SearchResultsLoading = () => {
  return (
    <ErrorDiv>
      <Row alignItems="center">
        <GridContainer>
          <Column>
            <GridItem lg={12} md={12} xs={12}>
              <Typography variant="h4" fontWeight="bold" padding="16px 0 20px " color="black1">
                Estamos realizando la búsqueda de Tratos Directos
              </Typography>
              <Spinner padding="16px 0 0" />
            </GridItem>
          </Column>
        </GridContainer>
      </Row>
    </ErrorDiv>
  )
}

export default SearchResultsLoading
