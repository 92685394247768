import React from 'react'
import routes from '../../config/settings/routes'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@chilecompra/react-kit/components'
import { Div, Column, Row, Link, Image } from './NewDirectDealBox.styles'
import { useAuthorization } from '../AuthProvider/AuthProvider.hooks'

/**
 * The NewDirectDealBox's container.
 */
const NewDirectDealBox = () => {
  const { isBuyer, isSeller } = useAuthorization()
  const navigate = useNavigate()

  const handlerNewDirectDeal = () => {
    navigate({ pathname: routes.directDealStepZero }, { replace: true })
  }

  return (
    <Div>
      {isBuyer && (
        <Column backgroundColor padding="8px">
          <Row>
            <Image alt="NewDD" src="/assets/images/direct-dealSheet.svg" />
          </Row>
          <Row>
            <Typography variant="h4" fontWeight="bold" padding="8px 0 0">
              Nuevo Trato Directo
            </Typography>
          </Row>
          <Row>
            <Typography variant="subtitle" fontWeight="regular" padding="8px 0 0">
              Publica la contratación excepcional que deseas realizar.
            </Typography>
          </Row>
          <Row marginTop="8px">
            <Typography variant="subtitle" fontWeight="regular" padding="8px 0 0">
              <Link onClick={handlerNewDirectDeal}>Crear Nuevo Trato Directo</Link>
            </Typography>
          </Row>
        </Column>
      )}
      {!isBuyer && !isSeller && (
        <Column border padding="8px">
          <Row>
            <Typography variant="h4" fontWeight="bold" padding="8px 0 0">
              ¿Que es Trato Directo?
            </Typography>
          </Row>
          <Row>
            <Typography variant="body2" fontWeight="regular" padding="8px 0 0">
              Mecanismo excepcional de compra que implica la contratación de un sólo proveedor previa resolución fundada
              o decreto alcaldicio.
            </Typography>
          </Row>
        </Column>
      )}
    </Div>
  )
}

export default NewDirectDealBox
