import React from 'react'
import { styled, Grid as BaseGrid } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

export const Grid = styled(({ textAlign, ...props }) => <BaseGrid container {...props} />)`
  grid-area: resume;
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props => props.theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`

export const GridItem = styled(({ textAlign, ...props }) => <BaseGrid item {...props} />)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  text-align: ${props => props.textAlign || 'initial'};
  align-items: center;
`

export const Link = styled('a')`
  color: ${props => props.theme.palette.blue[50]};
  cursor: pointer;
  font-size: 16px;
  margin: ${props => props.margin};
  font-family: ${props => props.theme.typography.fontFamily};
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
`

export const IconArrowDownward = styled(ArrowDownwardIcon)`
  color: ${props => props.theme.palette.blue[50]};
  margin: ${props => props.margin};
  font-size: ${props => props.fontSize};
  text-decoration-line: underline;
`
