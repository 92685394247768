import React from 'react'
import { styled, Grid as BaseGrid } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export const Grid = styled(({ ...props }) => <BaseGrid {...props} />)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;
  text-align: ${props => props.textAlign};
`

export const SearchIconCustom = styled(SearchIcon)`
  width: 20px;
  height: 20px;
`

export const SearchSuffix = styled('button')`
  border-right: 1px solid ${props => props.theme.palette.blue[50]};
  background-color: ${props => props.theme.palette.blue[50]};
  color: ${props => props.theme.palette.brand.white};
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;

  &:hover {
    background-color: ${props => props.theme.palette.blue[100]};
  }
`
export const AmountPrefix = styled('abbr')`
  background-color: ${props => props.theme.palette.brand.gray3};
  height: 100%;
  width: 35px;
  align-content: center;
  text-align-last: center;
`
