import { createContext } from 'react'

const contextPayload = {
  accessToken: undefined,
  isBuyer: undefined,
  isSeller: undefined,
  isPrivated: undefined,
  userDni: undefined,
  userType: undefined
}

const AuthorizationContext = createContext(contextPayload)
AuthorizationContext.displayName = 'authorization-context'

export default AuthorizationContext
