import { factoryService, getJsonRequest } from '@chilecompra/react-kit/requests'
import { REACT_APP_API_PRINCIPAL_URL, REACT_APP_API_BASE_URL } from '../config/settings/environment'

/**
 * Gets the list of Regions types.
 */
export const getRegion = factoryService(() => {
  return getJsonRequest(`${REACT_APP_API_PRINCIPAL_URL}/v1/geografia/region`)
})

/**
 * Gets the identity by the given DNI.
 */
export const getIdentity = factoryService(({ dni }) => {
  return getJsonRequest(`${REACT_APP_API_PRINCIPAL_URL}/v1/usuario-registro/informacionPersona/${dni}`)
})

/**
 * Gets the identity by the given Provider.
 */
export const getProvider = factoryService(({ providerDni }) => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/organismo/proveedor?rutProveedor=${providerDni}`)
})

/**
 * Gets the information about the given Provider.
 */
export const getProviderPricing = factoryService(({ providerDni }) => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/organismo/proveedor/${providerDni}`)
})

/**
 * Gets the Regions and Cities.
 */
export const getRegionsAndCities = factoryService(() => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/parametros/comunas`)
})

/**
 * Gets the UTM value
 */
export const getUTM = factoryService(() => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/parametros/utm`)
})

/**
 * Gets the units measurements
 */
export const getUnitsMeasurements = factoryService(() => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/catalogo/unidades`)
})

/**
 * Gets all currencies
 */
export const getAllCurrencies = factoryService(() => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/parametros/amounts`)
})

/**
 * Gets the Ability by the given Provider.
 */
export const getProviderAbility = factoryService(({ providerDni }) => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/info-habilidad/${providerDni}`)
})
