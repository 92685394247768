import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, Typography } from '@chilecompra/react-kit/components'
import { GridContainer, GridItem, ProviderNotSuitableImage } from './DirectDealStepTwo.styles'

/**
 * The DirectDealStepTwo's ability dialog.
 */
const DirectDealStepTwoAbilityDialog = props => {
  const { open, onClose } = props

  return (
    <Dialog
      open={open}
      disableBackdropClick={false}
      maxWidth="700px"
      height="522px"
      verticalAlign="top"
      onClose={onClose}
    >
      <GridContainer container direction="row" justifyContent="center" alignItems="center">
        <GridItem>
          <ProviderNotSuitableImage alt="NewDD" src="/assets/images/ProviderNotSuitable.svg" />
        </GridItem>
        <GridItem>
          <GridItem item textAlign="-webkit-center">
            <Typography align="center" variant="h3" lineHeight="26px" color="black1" fontWeight="bold">
              Para crear el Trato Directo el proveedor debe estar en estado hábil
            </Typography>
          </GridItem>
          <GridItem padding="16px 24px">
            <Typography align="center" variant="body1" regular lineHeight="21px" color="black1">
              El proveedor con el cuál deseas realizar el Trato Directo no se encuentra hábil según la información del
              Registro de Proveedores. Revisa más información sobre este proveedor accediendo a su ficha en Mercado
              Público
            </Typography>
          </GridItem>
        </GridItem>
        <GridItem item padding="0 30px" margin="4px 0" textAlign="center">
          <Button color="default" variant="contained" onClick={onClose}>
            Cerrar
          </Button>
        </GridItem>
      </GridContainer>
    </Dialog>
  )
}

DirectDealStepTwoAbilityDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
}

export default DirectDealStepTwoAbilityDialog
