import { batch } from 'react-redux'

import { downloadFile, generateBlob } from '@chilecompra/react-kit'

import { makeActionCreator } from '../../config/store/utils'
import { getDownloadDirectDealSearch } from '../../services/directdeal'

import { onShowSnackbar } from '../SnackBarProvider/SnackBarProvider.actions'

export const DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS = 'DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS'
export const DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS_ERROR = 'DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS_ERROR'
export const DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS_SUCCESS = 'DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS_SUCCESS'

export const onDownloadDirectDealSearchResults = makeActionCreator(DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS)
export const onDownloadDirectDealSearchResultsError = makeActionCreator(
  DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS_ERROR,
  'payload'
)
export const onDownloadDirectDealSearchResultsSuccess = makeActionCreator(
  DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS_SUCCESS,
  'payload'
)

export const onDownloadDirectDealResultsThunk = payload => async dispatch => {
  dispatch(onDownloadDirectDealSearchResults())
  try {
    const filters = Object.fromEntries(payload.query.entries())
    const queryString = new URLSearchParams(filters)

    const { data, headers } = await getDownloadDirectDealSearch({
      actionType: DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS,
      queryString
    })
    const blob = generateBlob(data, headers['content-type'])

    const fileDate = new Date()
    const fileDateYear = fileDate.getFullYear()
    const fileDateMonth = (fileDate.getMonth() + 1).toString().padStart(2, '0')
    const fileDateDay = fileDate.getDate().toString().padStart(2, '0')

    return batch(() => {
      downloadFile(blob, `FTD_${fileDateDay}_${fileDateMonth}_${fileDateYear}`)
      dispatch(onDownloadDirectDealSearchResultsSuccess())
      dispatch(
        onShowSnackbar({
          title: 'Descarga realizada con éxito',
          severity: 'success'
        })
      )
    })
  } catch (error) {
    return batch(() => {
      dispatch(
        onDownloadDirectDealSearchResultsError({
          error: {
            code: error.code,
            message: error.message
          }
        })
      )
      dispatch(
        onShowSnackbar({
          title: 'No ha sido posible realizar la descarga',
          message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
          severity: 'error'
        })
      )
    })
  }
}
