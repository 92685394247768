const ACCEPTED_FILTERS_KEYS = [
  'keyword',
  'cause',
  'state',
  'dateFrom',
  'dateTo',
  'provider',
  'orderBy',
  'page',
  'organization',
  'checkReceived',
  'checkSend',
  'minBudget',
  'maxBudget',
  'region',
  'item',
  'size'
]

/**
 * Updates the given query object.
 */
export const updateQuery = (query, dictionary) => {
  Object.entries(dictionary)
    .filter(([key]) => {
      return ACCEPTED_FILTERS_KEYS.indexOf(key) !== -1
    })
    .reduce((collection, [key, value]) => {
      query.delete(key)

      const minBud = dictionary.minBudget || 0
      const maxBud = dictionary.maxBudget || 0

      if (key === 'minBudget' || key === 'maxBudget') {
        if ((minBud === 0 || minBud === '0') && (maxBud === 0 || maxBud === '0')) {
          return collection
        }
      }

      if (value === '' || value === 'ALL') {
        return collection
      }

      return collection.concat([[key, value]])
    }, [])
    .forEach(([key, value]) => query.set(key, value))
}
