import React from 'react'
import PropTypes from 'prop-types'

import { Button, Card, DataTable, Typography } from '@chilecompra/react-kit/components'

import { Grid, Link } from './DirectDealSummary.styles'
import FormattedDateComponent from '../../components/components/FormattedDate.component'

/**
 * The DirectDealSummaryRequets's container.
 */
const DirectDealSummaryRequests = props => {
  const { contracts, loading, onOpenForm, onOpenDetail } = props

  const handlerOpenDetail = request => {
    return () => onOpenDetail(request)
  }

  return (
    <Grid container spacing={3} margin="0">
      <Grid item md={8} xs={12}>
        <Card enableBorder lineColor="primary" color="blank" padding="32px 24px">
          <Grid container spacing={4}>
            <Grid item>
              <Typography fontWeight="bold" variant="h3" lineHeight="26px" margin="0 0 8px">
                Solicitud de un nuevo proceso de contratación
              </Typography>
              <Typography variant="body2" lineHeight="18px">
                Si ofreces el mismo producto o servicio, o conoces otro proveedor que lo haga por favor adjunta la
                documentación necesaria que lo demuestre.
              </Typography>
            </Grid>

            <Grid item>
              <Button color="primary" variant="contained" onClick={onOpenForm} loading={loading}>
                Ingresar solicitud
              </Button>
            </Grid>
            {contracts.requestTotal > 0 && (
              <Grid item>
                <DataTable
                  accessibility={{ label: 'Solicitudes de procesos de contratación' }}
                  headers={[
                    { label: 'Solicitudes enviadas', value: 'num' },
                    { label: 'Fecha de solicitud', value: 'date' },
                    { label: 'Mensaje de solicitud', value: 'answer' },
                    { label: '', value: 'detail' }
                  ]}
                  headersCellsProps={[
                    { enableSort: true, initialDirection: 'descending', initialSort: true },
                    { enableSort: true },
                    { enableSort: true },
                    { enableSort: false }
                  ]}
                  enableStripped
                  initialRows="5"
                  loading={loading}
                  records={contracts?.requests.filter(request => request.isActive)}
                  recordsDecorators={[
                    ({ num }) => (
                      <Typography variant="body2" lineHeight="18px">
                        Solicitud N° {num}
                      </Typography>
                    ),
                    ({ date }) => (
                      <Typography variant="body2" lineHeight="18px">
                        <FormattedDateComponent date={date} />
                      </Typography>
                    ),
                    ({ answer }) => (
                      <Typography variant="body2" lineHeight="18px">
                        {answer}
                      </Typography>
                    ),
                    request => <Link onClick={handlerOpenDetail(request)}>ver detalle</Link>
                  ]}
                />
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

DirectDealSummaryRequests.propTypes = {
  contracts: PropTypes.shape({
    requestClosedDate: PropTypes.string,
    requestTotal: PropTypes.number,
    requests: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        dni: PropTypes.string,
        isActive: PropTypes.bool,
        answer: PropTypes.string
      })
    )
  }),
  loading: PropTypes.bool,
  onOpenForm: PropTypes.func,
  onOpenDetail: PropTypes.func
}

export default DirectDealSummaryRequests
