import { addMilliseconds } from 'date-fns'
import { batch } from 'react-redux'

import { putCookie } from '@chilecompra/react-kit'
import { customizeError } from '@chilecompra/react-kit/errors'

import { getAuthInfo, getPublicToken } from '../../services/auth'

import { makeActionCreator } from '../../config/store/utils'
import { USER_TYPES_TRANSLATION_DICTIONARY, USER_TYPES_DICTIONARY } from '../../config/settings/constants'
import { REACT_APP_ACCESS_TOKEN_MP_NAME, REACT_APP_ACCESS_TOKEN_NAME } from '../../config/settings/environment'

import { getCookieFromDomain } from '../../modules/cookies'
import { onShowSnackbar } from '../SnackBarProvider/SnackBarProvider.actions'

export const GET_AUTH_INFO = 'GET_AUTH_INFO'
export const GET_AUTH_INFO_ERROR = 'GET_AUTH_INFO_ERROR'
export const GET_AUTH_INFO_SUCCESS = 'GET_AUTH_INFO_SUCCESS'
export const onGetAuthInfo = makeActionCreator(GET_AUTH_INFO)
export const onGetAuthInfoError = makeActionCreator(GET_AUTH_INFO_ERROR, 'payload')
export const onGetAuthInfoSuccess = makeActionCreator(GET_AUTH_INFO_SUCCESS, 'payload')
export const onGetAuthInfoThunk = () => async dispatch => {
  dispatch(onGetAuthInfo())

  try {
    const { data } = await getAuthInfo()
    const isAdmin = data?.payload?.roles?.includes(12)
    const isBaseSupplier = data?.payload?.roles?.includes(3)

    return dispatch(
      onGetAuthInfoSuccess({
        authInfo: {
          isAdmin,
          isBaseSupplier,
          orgCode: data?.payload?.codigoOrganismo || '',
          roles: data?.payload?.roles,
          userCode: data?.payload?.codigoUsuario || '',
          userName: data?.payload?.name || data?.payload?.preferred_username,
          userType: data?.payload?.tipoUsuario || 'Publico',
          isBuyer: USER_TYPES_TRANSLATION_DICTIONARY[data?.payload?.tipoUsuario] === USER_TYPES_DICTIONARY.BUYER,
          isSeller: USER_TYPES_TRANSLATION_DICTIONARY[data?.payload?.tipoUsuario] === USER_TYPES_DICTIONARY.SELLER,
          userDni: data?.payload?.rut || ''
        }
      })
    )
  } catch (error) {
    return dispatch(
      onGetAuthInfoError({
        error: {
          code: error.code,
          message: error.reason
        }
      })
    )
  }
}

export const GET_PUBLIC_TOKEN = 'GET_PUBLIC_TOKEN'
export const GET_PUBLIC_TOKEN_ERROR = 'GET_PUBLIC_TOKEN_ERROR'
export const GET_PUBLIC_TOKEN_SUCCESS = 'GET_PUBLIC_TOKEN_SUCCESS'
export const onGetPublicToken = makeActionCreator(GET_PUBLIC_TOKEN)
export const onGetPublicTokenError = makeActionCreator(GET_PUBLIC_TOKEN_ERROR, 'payload')
export const onGetPublicTokenSuccess = makeActionCreator(GET_PUBLIC_TOKEN_SUCCESS, 'payload')
export const onGetPublicTokenThunk = () => async dispatch => {
  dispatch(onGetPublicToken())

  try {
    const { data } = await getPublicToken()

    putCookie(REACT_APP_ACCESS_TOKEN_NAME, data.payload?.access_token, {
      expires: addMilliseconds(new Date(), data.payload?.expires_in * 1000)
    })

    return batch(() => {
      dispatch(onGetPublicTokenSuccess({ accessToken: data.payload.access_token }))
      dispatch(onGetAuthInfoThunk())
    })
  } catch (error) {
    return dispatch(
      onGetPublicTokenError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'PUBLIC_TOKEN_ERROR'
        }).toObject()
      })
    )
  }
}

export const GET_TOKEN = 'GET_TOKEN'
export const GET_TOKEN_ERROR = 'GET_TOKEN_ERROR'
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS'
export const onGetToken = makeActionCreator(GET_TOKEN)
export const onGetTokenError = makeActionCreator(GET_TOKEN_ERROR, 'payload')
export const onGetTokenSuccess = makeActionCreator(GET_TOKEN_SUCCESS, 'payload')
export const onGetTokenThunk = () => async dispatch => {
  dispatch(onGetToken())
  try {
    const accessToken = await Promise.resolve()
      .then(() => getCookieFromDomain(REACT_APP_ACCESS_TOKEN_MP_NAME))
      .catch(() => {
        throw customizeError({ code: 400, reason: 'SOMETHING_WENT_WRONG_ERROR' })
      })

    putCookie(REACT_APP_ACCESS_TOKEN_NAME, accessToken)

    return batch(() => {
      dispatch(onGetTokenSuccess({ accessToken }))
      dispatch(onGetAuthInfoThunk())
    })
  } catch (error) {
    const { reason } = error.toObject()
    return dispatch(onGetTokenError({ reason }))
  }
}

export const GET_PUBLIC_TOKEN_ON_DEMAND = 'GET_PUBLIC_TOKEN_ON_DEMAND'
export const GET_PUBLIC_TOKEN_ON_DEMAND_ERROR = 'GET_PUBLIC_TOKEN_ON_DEMAND_ERROR'
export const GET_PUBLIC_TOKEN_ON_DEMAND_SUCCESS = 'GET_PUBLIC_TOKEN_ON_DEMAND_SUCCESS'
export const onGetPublicTokenOnDemand = makeActionCreator(GET_PUBLIC_TOKEN_ON_DEMAND)
export const onGetPublicTokenOnDemandError = makeActionCreator(GET_PUBLIC_TOKEN_ON_DEMAND_ERROR, 'payload')
export const onGetPublicTokenOnDemandSuccess = makeActionCreator(GET_PUBLIC_TOKEN_ON_DEMAND_SUCCESS)
export const onGetPublicTokenOnDemandThunk = callback => async dispatch => {
  dispatch(onGetPublicTokenOnDemand())

  try {
    const { data } = await getPublicToken()

    const publicAccess = {
      token: data.payload?.access_token,
      expires_in: data.payload?.expires_in
    }

    if (callback) {
      callback(publicAccess)
    }

    return dispatch(onGetPublicTokenOnDemandSuccess())
  } catch (error) {
    return batch(() => {
      dispatch(
        onGetPublicTokenOnDemandError({
          error: customizeError({
            code: error.code,
            originalError: error.originalError,
            reason: 'PUBLIC_TOKEN_ERROR'
          }).toObject()
        })
      )
      dispatch(
        onShowSnackbar({
          title: 'No ha sido posible direccionar',
          message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
          severity: 'error'
        })
      )
    })
  }
}
