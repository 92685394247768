import React from 'react'
import { useNavigate } from 'react-router-dom'

import { getCookie, Tabs, Tab, useQuery, useQueryParam } from '@chilecompra/react-kit'

import AuthorizationProviderContainer from '../../containers/AuthProvider/AuthProvider.container'
import AuthorizationContext from '../../containers/AuthProvider/AuthProvider.context'

import BrandLayout from '../../components/Layouts/BrandLayout'

import { Grid } from './ProductSearch.styles'

import DirectDealPageHeader from '../../containers/DirectDealPageHeader/DirectDealPageHeader.container'
import ProductSearchFooter from '../../containers/ProductSearch/ProductSearch.footer'
import ProductSearchTabCategory from '../../containers/ProductCategorySearch/ProductCategorySearch.container'
import ProductSearchTabProducts from '../../containers/ProductSearch/ProductSearch.products'

import routes from '../../config/settings/routes'

/**
 * The ProductSeach's page.
 */
const ProductSearch = () => {
  const navigate = useNavigate()
  const query = useQuery()
  const directDealCode = useQueryParam('code')

  const handlerBack = () => {
    query.set('code', directDealCode)
    navigate({ pathname: routes.directDealStepTwo, search: query.toString() }, { replace: true })
  }

  const productSearchHeader = {
    breadcrumbs: [
      {
        text: 'Escritorio',
        onRedirectTo: () => {
          const query = getCookie('search')
          navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
        }
      },
      {
        text: 'Trato Directo',
        onRedirectTo: () => {
          const query = getCookie('search')
          navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
        }
      },
      {
        text: `Crear Trato Directo`,
        onRedirectTo: () => handlerBack()
      },
      {
        text: `Buscador Productos y Servicios`
      }
    ],
    title: 'Agregar Productos o Servicios',
    description: `Selecciona los productos o servicios a cotizar.`
  }

  return (
    <AuthorizationProviderContainer>
      <AuthorizationContext.Consumer>
        {() => (
          <BrandLayout featured={<DirectDealPageHeader {...productSearchHeader} />}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Tabs value="tab1" padding="34px 0 0" variant="fullWidth">
                  <Tab id="tab1" label="Buscar por producto o servicio">
                    <ProductSearchTabProducts />
                  </Tab>
                  <Tab id="tab2" label="Navegar por rubros">
                    <ProductSearchTabCategory />
                  </Tab>
                </Tabs>
                <ProductSearchFooter onBack={handlerBack} />
              </Grid>
            </Grid>
          </BrandLayout>
        )}
      </AuthorizationContext.Consumer>
    </AuthorizationProviderContainer>
  )
}

export default ProductSearch
