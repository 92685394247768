import { CLOSE_SNACKBAR, SHOW_SNACKBAR } from './SnackBarProvider.actions'

const snackbarProviderState = {
  message: '',
  severity: '',
  showSnackbar: false,
  title: ''
}

/**
 * The SnackbarProvider reducer.
 */
const snackbarProviderReducer = (state = snackbarProviderState, { payload, type }) => {
  switch (type) {
    case CLOSE_SNACKBAR: {
      return {
        ...state,
        message: '',
        severity: '',
        showSnackbar: false,
        title: ''
      }
    }
    case SHOW_SNACKBAR: {
      return {
        ...state,
        message: payload.message,
        severity: payload.severity,
        showSnackbar: true,
        title: payload.title
      }
    }
    default: {
      return state
    }
  }
}

export default snackbarProviderReducer
