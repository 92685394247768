import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAuthorization } from '../../containers/AuthProvider/AuthProvider.hooks'

/**
 * The ProtectByProfile' component.
 */
const ProtectByProfile = ({ children, fallback, redirectTo, rule }) => {
  const { userType } = useAuthorization()
  const navigate = useNavigate()
  const result = rule(userType)

  if (!result && redirectTo) {
    navigate(redirectTo)
  }

  if (!result) {
    return fallback(userType)
  }

  return <>{children}</>
}

ProtectByProfile.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.func,
  redirectTo: PropTypes.string,
  rule: PropTypes.func
}

export default ProtectByProfile
