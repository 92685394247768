import {
  GET_AUTH_INFO,
  GET_AUTH_INFO_ERROR,
  GET_AUTH_INFO_SUCCESS,
  GET_PUBLIC_TOKEN,
  GET_PUBLIC_TOKEN_ERROR,
  GET_PUBLIC_TOKEN_ON_DEMAND,
  GET_PUBLIC_TOKEN_ON_DEMAND_SUCCESS,
  GET_PUBLIC_TOKEN_ON_DEMAND_ERROR,
  GET_PUBLIC_TOKEN_SUCCESS,
  GET_TOKEN,
  GET_TOKEN_ERROR,
  GET_TOKEN_SUCCESS
} from './AuthProvider.actions'

const authorizationProviderState = {
  access_token: undefined,
  error: undefined,
  loading: false,
  authInfo: {
    isAdmin: false,
    isBaseSupplier: false,
    loading: false,
    error: undefined,
    orgCode: '',
    userCode: '',
    userName: '',
    userType: '',
    isBuyer: false,
    isSeller: false,
    userDni: ''
  }
}

/**
 * The authorization provider's reducer.
 */
const authorizationProviderReducer = (state = authorizationProviderState, { payload, type }) => {
  switch (type) {
    case GET_AUTH_INFO: {
      return { ...state, authInfo: { ...state.authInfo }, loading: true }
    }
    case GET_AUTH_INFO_ERROR: {
      return { ...state, authInfo: { ...state.authInfo }, error: payload.error, loading: false }
    }
    case GET_AUTH_INFO_SUCCESS: {
      return { ...state, authInfo: { ...payload.authInfo }, loading: false }
    }
    case GET_PUBLIC_TOKEN_ON_DEMAND:
    case GET_PUBLIC_TOKEN: {
      return {
        ...state,
        error: undefined,
        loading: true
      }
    }
    case GET_PUBLIC_TOKEN_ERROR: {
      return {
        ...state,
        accessToken: undefined,
        error: payload.error,
        loading: true
      }
    }
    case GET_PUBLIC_TOKEN_SUCCESS: {
      return {
        ...state,
        accessToken: payload.accessToken,
        error: undefined,
        loading: false
      }
    }
    case GET_TOKEN: {
      return { ...state, loading: true, error: undefined }
    }
    case GET_PUBLIC_TOKEN_ON_DEMAND_ERROR:
    case GET_TOKEN_ERROR: {
      return { ...state, loading: true, error: payload.error }
    }
    case GET_TOKEN_SUCCESS: {
      return { ...state, loading: false, accessToken: payload.accessToken }
    }
    case GET_PUBLIC_TOKEN_ON_DEMAND_SUCCESS: {
      return { ...state, loading: false }
    }
    default: {
      return state
    }
  }
}

export default authorizationProviderReducer
