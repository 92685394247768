import React from 'react'
import PropTypes from 'prop-types'

import { Card, DataTable, Typography } from '@chilecompra/react-kit/components'

import { Grid } from './DirectDealSummary.styles'

import FormattedPhoneComponent from '../../components/components/FormattedPhone.component'
import AttachmentsWrapper from '../../components/components/AttachmentsWrapper.component'

import { CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL } from '../../config/settings/constants'

/**
 * The DirectDealSummary's quotes.
 */
const DirectDealSummaryQuotes = props => {
  const { loading, quotes } = props
  return (
    <Grid container spacing={3} margin="0">
      <Grid item md={8} xs={12}>
        <Card enableBorder lineColor="primary" color="blank" padding="32px 24px">
          <Grid container>
            <Grid item>
              <Typography fontWeight="bold" variant="h3">
                Listado de cotizaciones recibidas
              </Typography>
            </Grid>
            <Grid item margin="24px 0 0">
              <DataTable
                accessibility={{ label: 'Listado de cotizaciones recibidas' }}
                headers={[
                  { label: 'RUT cotizante', value: 'quoteDni' },
                  { label: 'Razón social cotizante', value: 'quoteName' },
                  { label: 'Teléfono cotizante', value: 'quotePhone' },
                  { label: 'Correo electrónico cotizante', value: 'quoteEmail' },
                  { label: 'Cotización', value: 'quoteAttachmentId' }
                ]}
                headersCellsProps={[
                  { enableSort: true, width: '129px' },
                  { enableSort: true, width: '150px' },
                  { enableSort: true, width: '129px' },
                  { enableSort: true, width: '150px' },
                  { enableSort: false, with: '100px' }
                ]}
                enableStripped
                initialRows="5"
                loading={loading}
                records={quotes}
                recordsDecorators={[
                  ({ quoteDni }) => (
                    <Typography variant="body2" lineHeight="18px">
                      {quoteDni}
                    </Typography>
                  ),
                  ({ quoteName }) => (
                    <Typography variant="body2" lineHeight="18px">
                      {quoteName}
                    </Typography>
                  ),
                  ({ quotePhone }) => (
                    <Typography variant="body2" lineHeight="18px">
                      <FormattedPhoneComponent phone={quotePhone} />
                    </Typography>
                  ),
                  ({ quoteEmail }) => (
                    <Typography variant="body2" lineHeight="18px">
                      {quoteEmail}
                    </Typography>
                  ),
                  ({ quoteAttachmentId, quoteSubBusiness }) => {
                    if (quoteAttachmentId && quoteSubBusiness) {
                      return (
                        <AttachmentsWrapper
                          business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
                          idBusiness={quoteAttachmentId}
                          subBusiness={quoteSubBusiness}
                          isPrivate
                          canRead
                          color="default"
                          showIconList={false}
                          typeView="list"
                        />
                      )
                    }
                    return <></>
                  }
                ]}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

DirectDealSummaryQuotes.propTypes = {
  loading: PropTypes.bool,
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      quoteDni: PropTypes.string,
      quoteEmail: PropTypes.string,
      quoteName: PropTypes.string,
      quotePhone: PropTypes.string,
      quoteAttachmentId: PropTypes.string,
      quoteSubBusiness: PropTypes.string
    })
  )
}

export default DirectDealSummaryQuotes
