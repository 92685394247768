import React from 'react'
import { Grid as BaseGrid, styled, Divider as DividerBase } from '@mui/material'
import {
  Button as ButtonBase,
  DatePicker as DatePickerBase,
  Card as BaseCard,
  Typography as TypographyBase
} from '@chilecompra/react-kit/components'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

export const Card = styled(({ lineColor, ...props }) => <BaseCard {...props} />)`
  &.MuiPaper-root > div:first-child {
    background-color: ${props => props.lineColor};
  }
`
export const Typography = styled(({ color, ...props }) => <TypographyBase {...props} />)`
  &.MuiTypography-root {
    color: ${props => props.color};
  }
`

export const Container = styled('div')`
  grid-area: results;
`
export const ErrorDiv = styled('div')`
  border-color: ${props => props.theme.palette.gray[0.16]};
  border: solid 1px;
  height: 327px;
  width: 98%;
  padding: 0;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2%;
  text-align: center;
`

export const SeparatorDiv = styled('div')`
  border: solid 1px;
  border-color: ${props => props.theme.palette.gray[0.16]};
  width: 98%;
  margin: 36px 10px 0;
  ${props => props.theme.breakpoints.up('lg')} {
    margin-top: 1vw;
  }
`

export const Link = styled('a')`
  color: ${props => props.theme.palette.blue[50]};
  cursor: pointer;
  font-size: 14px;
  margin: ${props => props.margin};
  font-family: ${props => props.theme.typography.fontFamily};
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
`

export const Row = styled(({ margin, padding, ...props }) => <BaseGrid container {...props} />)`
  &.MuiGrid-container {
    margin: ${props => props.margin || 0};
    padding: ${props => props.padding || 0};
  }
`
export const Image = styled('img')`
  width: 16px;
  height: 16px;
  margin: -4px;
  padding: 0 8px 0;
`
export const ErrorImage = styled('img')`
  width: 82px;
  height: 69px;
`

export const Column = styled(({ margin, padding, ...props }) => <BaseGrid item {...props} />)`
  &.MuiGrid-item {
    margin: ${props => props.margin || 0};
    padding: ${props => props.padding || 0};
    width: 100%;
  }
`

export const SearchButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 189px;
    height: 50px;
    gap: 0;
    opacity: 0;
  }
`

export const GridContainer = styled(({ ...props }) => <BaseGrid container {...props} />)`
  &.MuiGrid-container {
    height: ${props => props.height};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`

export const GridItem = styled(({ ...props }) => <BaseGrid item {...props} />)`
  &.MuiGrid-item {
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    justify-content: ${props => props.justifycontent};
  }
`

export const RowPaginator = styled(({ textAlign, ...props }) => <BaseGrid container {...props} />)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props => props.theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`

export const ColumnPaginator = styled(({ ...props }) => <BaseGrid item {...props} />)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  text-align: ${props => props.textAlign || 'initial'};
  align-items: center;
`
export const Hr = styled(DividerBase)`
  background: ${props => props.theme.palette.gray[90]};
  margin: ${props => props.margin};
`

export const IconArrowForward = styled(ArrowForwardIcon)`
  color: ${props => props.theme.palette.blue[50]};
  margin: ${props => props.margin};
  font-size: ${props => props.fontSize};
  text-decoration-line: underline;
`
