import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@chilecompra/react-kit/components'
import { useViewport } from '@chilecompra/react-kit'

import { Grid } from './DirectDealStepOne.styles'

/**
 * The DirectDealStepOneFooter's component.
 */
const DirectDealStepOneFooter = ({ onBack, onContinue, disabledContinue }) => {
  const { size } = useViewport()

  return (
    <Grid container margin="32px 0" spacing={2}>
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        textAlign={size.isSmall || size.isTiny ? 'center' : 'left'}
        order={{ lg: 1, md: 1, sm: 2, xs: 2 }}
      >
        <Button variant="outlined" onClick={onBack} width={size.isSmall || size.isTiny ? '100%' : '40%'}>
          Volver
        </Button>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        textAlign={size.isSmall || size.isTiny ? 'center' : 'right'}
        order={{ lg: 2, md: 2, sm: 1, xs: 1 }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={onContinue}
          disabled={disabledContinue}
          width={size.isSmall || size.isTiny ? '100%' : '40%'}
        >
          Siguiente
        </Button>
      </Grid>
    </Grid>
  )
}

DirectDealStepOneFooter.propTypes = {
  onBack: PropTypes.func,
  onContinue: PropTypes.func,
  disabledContinue: PropTypes.bool
}

export default DirectDealStepOneFooter
