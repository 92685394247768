import { styled, Grid as BaseGrid } from '@mui/material'
import { PUBLIC_URL } from '../../config/settings/environment'

export const ExternalLink = styled('a')`
  border-top: ${props =>
    props.selected ? `5px solid ${props.theme.palette.MP.mountainMeadow}` : '6px solid transparent'};
  color: ${props => props.theme.palette.MP.veniceBlue};
  display: block;
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  white-space: nowrap;
  padding: 10px 12px;
  cursor: pointer;

  &:hover {
    border-top: ${props =>
      props.selected
        ? `5px solid ${props.theme.palette.MP.mountainMeadow}`
        : `6px solid ${props.theme.palette.MP.corduroy}`};
    box-shadow: ${props => `inset 0 -3px 0 0 ${props.theme.palette.brand.white}`};
    color: ${props => props.theme.palette.MP.regalBlue};
    text-decoration: none;
  }

  &:focus {
    background: ${props => props.theme.palette.MP.black};
    color: ${props => props.theme.palette.brand.white};
    text-decoration: underline;
  }
`

export const RootNav = styled('nav')`
  background: transparent url(${PUBLIC_URL}/assets/images/Barrasuperior.jpg) repeat-x;
  background-position-y: -1px;
  margin-bottom: 10px;
  overflow: hidden;
  width: 100%;
`

export const Item = styled('li')`
  float: left;
  list-style: none;
`
export const List = styled('ul')`
  display: block;
  float: right;
  list-style: none;
  margin: 0 0 10px;
  padding: 0;
`

export const Container = styled(BaseGrid)`
  &.MuiGrid-container {
    display: block;
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;

    @media (min-width: 768px) {
      width: 750px;
    }

    @media (min-width: 1200px) {
      width: 1170px;
    }
  }
`
