import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { Input, Typography, Card } from '@chilecompra/react-kit/components'
import {
  checkInputErrorRequired,
  checkInputErrorMaxLength,
  checkInputErrorEmail,
  useInput,
  useViewport
} from '@chilecompra/react-kit'

import { Grid, SearchSuffix, SearchIconCustom } from './DirectDealStepOne.styles'
import { onGetInfoDNIThunk } from './DirectDealStepOne.actions'

import { useImperativeFormRef } from '../../modules/hooks/useFormRef'
import { formatDNI, unformatDNI } from '../../modules/utils/formatters'
import { DIRECT_DEAL_MORE_DOCUMENTS_ARE_REQUIRED } from '../../config/settings/constants'

/**
 * The DirectDealStepOne's requester information.
 */
const DirectDealRequester = props => {
  const { formRef, loading, requesterInfo, valueRadioAmount, isRequiringMoreDocuments } = props
  const { size } = useViewport()
  const dispatch = useDispatch()
  const {
    error: dniError,
    onChange: dniInputChange,
    onError: updatedDniError,
    value: dniValueInput
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()],
    formatCallbacks: [value => formatDNI(value)]
  })
  const {
    error: nameError,
    onChange: nameInputChange,
    onError: updatedNameError,
    setValue: setNameValueInput,
    value: nameValueInput
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()]
  })
  const {
    error: lastNameError,
    onChange: lastNameChange,
    onError: updatedLastNameError,
    setValue: setLastNameValueInput,
    value: lastNameValueInput
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()]
  })
  const {
    error: jobError,
    onChange: jobInputChange,
    onError: updatedJobError,
    value: jobValueInput
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()]
  })
  const {
    error: emailError,
    onChange: emailInputChange,
    onError: updatedEmailError,
    value: emailValueInput
  } = useInput({
    errorCallbacks: [
      checkInputErrorRequired(),
      checkInputErrorMaxLength(200),
      checkInputErrorEmail('Formato de email incorrecto.')
    ]
  })

  const handlerSearchDni = () => {
    dispatch(onGetInfoDNIThunk({ dni: unformatDNI(dniValueInput) }))
  }

  useEffect(() => {
    setNameValueInput(requesterInfo?.names || '')
    setLastNameValueInput(requesterInfo?.lastNames || '')
  }, [requesterInfo])

  useImperativeFormRef(
    formRef,
    () => ({
      dniError,
      dniValueInput,
      emailError,
      emailValueInput,
      jobError,
      jobValueInput,
      lastNameError,
      lastNameValueInput,
      nameError,
      nameValueInput
    }),
    [
      dniError,
      dniValueInput,
      emailError,
      emailValueInput,
      jobError,
      jobValueInput,
      lastNameError,
      lastNameValueInput,
      nameError,
      nameValueInput
    ]
  )

  return (
    <Grid container margin="33px 0" spacing={2}>
      <Grid item xs={12} md={2}>
        <Typography fontWeight="bold" variant="body2" fontSize="16px">
          Requirente del Trato Directo
        </Typography>
        <Typography fontSize="14px" fontWeight="regular" lineHeight="18px" variant="body2">
          Información de la persona que realizó la solicitud de compra
        </Typography>
      </Grid>
      <Grid container item xs={12} md={6} spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Input
            label="RUT"
            value={dniValueInput}
            onChange={dniInputChange}
            onError={updatedDniError}
            error={dniError}
            suffix={
              <SearchSuffix onClick={handlerSearchDni}>
                <SearchIconCustom />
              </SearchSuffix>
            }
            loading={loading}
          />
        </Grid>

        <Grid item md={6} />

        <Grid item xs={12} sm={6} md={6}>
          <Input
            label="Nombres"
            disabled
            value={nameValueInput}
            onChange={nameInputChange}
            onError={updatedNameError}
            error={nameError}
            loading={loading}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Input
            label="Apellidos"
            disabled
            value={lastNameValueInput}
            onChange={lastNameChange}
            onError={updatedLastNameError}
            error={lastNameError}
            loading={loading}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Input
            label="Cargo"
            value={jobValueInput}
            onChange={jobInputChange}
            onError={updatedJobError}
            error={jobError}
            loading={loading}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Input
            type="email"
            label="Correo electrónico"
            value={emailValueInput}
            onChange={emailInputChange}
            onError={updatedEmailError}
            error={emailError}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid md={4} padding="17px" margin="-65px 0 0">
        {size.isDesktop && isRequiringMoreDocuments && valueRadioAmount === '1' && (
          <Grid item xs={12} md={12}>
            <Card enableBorder="true" lineColor="default" color="blank" padding="24px">
              <Grid container>
                <Grid item>
                  <Typography variant="h4" fontWeight="bold" padding="0 0 8px">
                    Se requiere informe técnico
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" lineHeight="18px">
                    {DIRECT_DEAL_MORE_DOCUMENTS_ARE_REQUIRED}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

DirectDealRequester.propTypes = {
  loading: PropTypes.bool,
  isRequiringMoreDocuments: PropTypes.bool,
  valueRadioAmount: PropTypes.string,
  requesterInfo: PropTypes.shape({
    names: PropTypes.string,
    lastNames: PropTypes.string
  }),
  formRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  })
}

export default DirectDealRequester
