import { makeActionCreator } from '../../config/store/utils'
import {
  getContractDuration,
  getContractType,
  getCurrencyType,
  getTaxesDetail,
  getClosingDate,
  saveDirectDeal,
  publishDirectDeal
} from '../../services/directDealStepTwo'
import { getAllCurrencies, getProviderAbility, getRegionsAndCities, getUnitsMeasurements } from '../../services/config'
import { getDetailDirectDealSheet } from '../../services/directdeal'
import { CURRENCY_TRANSLATION } from '../../config/settings/constants'
import { batch } from 'react-redux'
import { deleteQuote } from '../../services/quotes'

export const OPEN_CONFIRM_STEP_TWO_DIALOG = 'OPEN_CONFIRM_STEP_TWO_DIALOG'
export const onOpenConfirmStepTwoDialog = makeActionCreator(OPEN_CONFIRM_STEP_TWO_DIALOG)
export const CLOSE_CONFIRM_STEP_TWO_DIALOG = 'CLOSE_CONFIRM_STEP_TWO_DIALOG'
export const onCloseConfirmStepTwoDialog = makeActionCreator(CLOSE_CONFIRM_STEP_TWO_DIALOG)

export const PUBLISH_DIRECT_DEAL = 'PUBLISH_DIRECT_DEAL'
export const PUBLISH_DIRECT_DEAL_SUCCESS = 'PUBLISH_DIRECT_DEAL_SUCCESS'
export const PUBLISH_DIRECT_DEAL_ERROR = 'PUBLISH_DIRECT_DEAL_ERROR'
export const onPublishDirectDeal = makeActionCreator(PUBLISH_DIRECT_DEAL, 'payload')
export const onPublishDirectDealSuccess = makeActionCreator(PUBLISH_DIRECT_DEAL_SUCCESS, 'payload')
export const onPublishDirectDealError = makeActionCreator(PUBLISH_DIRECT_DEAL_ERROR, 'payload')

export const onPublishDirectDealThunk = payload => async dispatch => {
  dispatch(onPublishDirectDeal())
  try {
    const { data } = await publishDirectDeal({ sheet: payload.sheetStepTwo, actionType: PUBLISH_DIRECT_DEAL })

    return batch(() => {
      dispatch(onPublishDirectDealSuccess())
      payload.callback(data?.payload?.idOrdenCompra || '')
    })
  } catch (err) {
    return batch(() => {
      dispatch(onPublishDirectDealError({ err }))
      payload.callbackError()
    })
  }
}

export const SAVE_DIRECT_DEAL = 'SAVE_DIRECT_DEAL'
export const SAVE_DIRECT_DEAL_SUCCESS = 'SAVE_DIRECT_DEAL_SUCCESS'
export const SAVE_DIRECT_DEAL_ERROR = 'SAVE_DIRECT_DEAL_ERROR'
export const onSaveDirectDeal = makeActionCreator(SAVE_DIRECT_DEAL, 'payload')
export const onSaveDirectDealSuccess = makeActionCreator(SAVE_DIRECT_DEAL_SUCCESS)
export const onSaveDirectDealError = makeActionCreator(SAVE_DIRECT_DEAL_ERROR, 'payload')

export const onSaveDirectDealThunk = payload => async dispatch => {
  dispatch(onSaveDirectDeal())

  try {
    await saveDirectDeal({ sheet: payload.sheetStepTwo, actionType: SAVE_DIRECT_DEAL })

    return batch(() => {
      dispatch(onSaveDirectDealSuccess())
      payload.callback()
    })
  } catch (err) {
    return batch(() => {
      dispatch(onSaveDirectDealError({ err }))
      payload.callbackError()
    })
  }
}

export const GET_CONTRACT_DURATION = 'GET_CONTRACT_DURATION'
export const GET_CONTRACT_DURATION_ERROR = 'GET_CONTRACT_DURATION_ERROR'
export const GET_CONTRACT_DURATION_SUCCESS = 'GET_CONTRACT_DURATION_SUCCESS'
export const onGetContractDurationSearch = makeActionCreator(GET_CONTRACT_DURATION)
export const onGetContractDurationSearchError = makeActionCreator(GET_CONTRACT_DURATION_ERROR, 'payload')
export const onGetContractDurationSearchSuccess = makeActionCreator(GET_CONTRACT_DURATION_SUCCESS, 'payload')
export const onGetContractDurationSearchThunk = () => async dispatch => {
  dispatch(onGetContractDurationSearch())
  try {
    const { data } = await getContractDuration({ actionType: GET_CONTRACT_DURATION })
    return dispatch(
      onGetContractDurationSearchSuccess({
        contractDuration:
          data?.payload.map(item => ({
            value: item.desc,
            name: item.desc,
            id: item.id
          })) || []
      })
    )
  } catch (error) {
    return dispatch(
      onGetContractDurationSearchError({
        error
      })
    )
  }
}

export const GET_CONTRACT_TYPE = 'GET_CONTRACT_TYPE'
export const GET_CONTRACT_TYPE_ERROR = 'GET_CONTRACT_TYPE_ERROR'
export const GET_CONTRACT_TYPE_SUCCESS = 'GET_CONTRACT_TYPE_SUCCESS'
export const onGetContractTypeSearch = makeActionCreator(GET_CONTRACT_TYPE)
export const onGetContractTypeSearchError = makeActionCreator(GET_CONTRACT_TYPE_ERROR, 'payload')
export const onGetContractTypeSearchSuccess = makeActionCreator(GET_CONTRACT_TYPE_SUCCESS, 'payload')
export const onGetContractTypeSearchThunk = () => async dispatch => {
  dispatch(onGetContractTypeSearch())
  try {
    const { data } = await getContractType({ actionType: GET_CONTRACT_TYPE })
    return dispatch(
      onGetContractTypeSearchSuccess({
        contractType: data?.payload.reduce((acc, category) => {
          if (category.descCategoriaContrato === 'Bienes') {
            acc.GOODS = category?.tipos.map(item => ({
              value: item.idTipoContrato,
              name: item.descTipoContrato,
              id: item.idTipoContrato
            }))
          } else if (category.descCategoriaContrato === 'Servicios') {
            acc.SERVICES = category?.tipos.map(item => ({
              value: item.idTipoContrato,
              name: item.descTipoContrato,
              id: item.idTipoContrato
            }))
          }
          return acc
        }, {})
      })
    )
  } catch (error) {
    return dispatch(
      onGetContractDurationSearchError({
        error
      })
    )
  }
}

export const GET_CURRENCY = 'GET_CURRENCY'
export const GET_CURRENCY_ERROR = 'GET_CURRENCY_ERROR'
export const GET_CURRENCY_SUCCESS = 'GET_CURRENCY_SUCCESS'
export const onGetCurrencySearch = makeActionCreator(GET_CURRENCY)
export const onGetCurrencySearchError = makeActionCreator(GET_CURRENCY_ERROR, 'payload')
export const onGetCurrencySearchSuccess = makeActionCreator(GET_CURRENCY_SUCCESS, 'payload')
export const onGetCurrencySearchThunk = () => async dispatch => {
  dispatch(onGetCurrencySearch())
  try {
    const { data } = await getCurrencyType({ actionType: GET_CURRENCY })
    return dispatch(
      onGetCurrencySearchSuccess({
        currency:
          data?.payload.map(item => ({
            value: item.moneda,
            name: item.nombre,
            type: item.moneda,
            symbol: CURRENCY_TRANSLATION[item.moneda]?.symbol || ''
          })) || []
      })
    )
  } catch (error) {
    return dispatch(
      onGetCurrencySearchError({
        error
      })
    )
  }
}

export const GET_TAXES = 'GET_TAXES'
export const GET_TAXES_ERROR = 'GET_TAXES_ERROR'
export const GET_TAXES_SUCCESS = 'GET_TAXES_SUCCESS'
export const onGetTaxesSearch = makeActionCreator(GET_TAXES)
export const onGetTaxesSearchError = makeActionCreator(GET_TAXES_ERROR, 'payload')
export const onGetTaxesSearchSuccess = makeActionCreator(GET_TAXES_SUCCESS, 'payload')
export const onGetTaxesSearchThunk = () => async dispatch => {
  dispatch(onGetTaxesSearch())
  try {
    const { data } = await getTaxesDetail({ actionType: GET_TAXES })
    return dispatch(
      onGetTaxesSearchSuccess({
        taxes:
          data?.payload?.map(item => ({
            value: item.id,
            name: item.nombre,
            percentage: item.porcentaje
          })) || []
      })
    )
  } catch (error) {
    return dispatch(
      onGetTaxesSearchError({
        error
      })
    )
  }
}

export const GET_INFO_REGIONS = 'GET_INFO_REGIONS'
export const GET_INFO_REGIONS_SUCCESS = 'GET_INFO_REGIONS_SUCCESS'
export const GET_INFO_REGIONS_ERROR = 'GET_INFO_REGIONS_ERROR'

export const onGetRegions = makeActionCreator(GET_INFO_REGIONS, 'payload')
export const onGetRegionsSuccess = makeActionCreator(GET_INFO_REGIONS_SUCCESS, 'payload')
export const onGetRegionsError = makeActionCreator(GET_INFO_REGIONS_ERROR, 'payload')
export const onGetRegionsThunk = () => async dispatch => {
  dispatch(onGetRegions())

  try {
    const { data } = await getRegionsAndCities({ actionType: GET_INFO_REGIONS })

    if (data && Array.isArray(data.payload)) {
      const regions = data.payload.map(region => ({
        value: region.id,
        name: region.nombre,
        orden: region.orden
      }))
      dispatch(onGetRegionsSuccess({ regions }))
    } else {
      throw new Error('Estructura de datos inválida: no se han encontrado regiones.')
    }
  } catch (err) {
    dispatch(onGetRegionsError({ err }))
  }
}

export const GET_INFO_CITIES = 'GET_INFO_CITIES'
export const GET_INFO_CITIES_SUCCESS = 'GET_INFO_CITIES_SUCCESS'
export const GET_INFO_CITIES_ERROR = 'GET_INFO_CITIES_ERROR'

export const onGetCities = makeActionCreator(GET_INFO_CITIES, 'payload')
export const onGetCitiesSuccess = makeActionCreator(GET_INFO_CITIES_SUCCESS, 'payload')
export const onGetCitiesError = makeActionCreator(GET_INFO_CITIES_ERROR, 'payload')

export const onGetCitiesThunk = updatedValue => async dispatch => {
  dispatch(onGetCities())

  try {
    const { data } = await getRegionsAndCities({ actionType: GET_INFO_CITIES })

    const cities = data?.payload
      .flatMap(region => region.comunas)
      .filter(comuna => comuna.region === updatedValue)
      .map(comuna => ({
        value: comuna.id,
        name: comuna.nombre
      }))

    dispatch(onGetCitiesSuccess({ cities }))
  } catch (err) {
    dispatch(onGetCitiesError({ err }))
  }
}

export const GET_INFO_PROVIDER = 'GET_INFO_PROVIDER'
export const GET_INFO_PROVIDER_SUCCESS = 'GET_INFO_PROVIDER_SUCCESS'
export const GET_INFO_PROVIDER_ERROR = 'GET_INFO_PROVIDER_ERROR'

export const onGetInfoProvider = makeActionCreator(GET_INFO_PROVIDER, 'payload')
export const onGetInfoProviderSuccess = makeActionCreator(GET_INFO_PROVIDER_SUCCESS, 'payload')
export const onGetInfoProviderError = makeActionCreator(GET_INFO_PROVIDER_ERROR, 'payload')

export const onGetInfoProviderThunk = payload => async dispatch => {
  dispatch(onGetInfoProvider())

  try {
    const { data } = await getProviderAbility({ providerDni: payload.providerDni, actionType: GET_INFO_PROVIDER })
    return dispatch(
      onGetInfoProviderSuccess({
        provider: {
          businessName: data?.payload?.businessName || '',
          skillState: data?.payload?.skillState
        }
      })
    )
  } catch (err) {
    return batch(() => {
      dispatch(onGetInfoProviderError({ err }))
      payload.callbackError()
    })
  }
}

export const GET_CLOSING_DATE = 'GET_CLOSING_DATE'
export const GET_CLOSING_DATE_SUCCESS = 'GET_CLOSING_DATE_SUCCESS'
export const GET_CLOSING_DATE_ERROR = 'GET_CLOSING_DATE_ERROR'
export const onGetClosingDate = makeActionCreator(GET_CLOSING_DATE, 'payload')
export const onGetClosingDateSuccess = makeActionCreator(GET_CLOSING_DATE_SUCCESS, 'payload')
export const onGetClosingDateError = makeActionCreator(GET_CLOSING_DATE_ERROR, 'payload')
export const onGetClosingDateThunk = payload => async dispatch => {
  dispatch(onGetClosingDate())
  try {
    const { data } = await getClosingDate({ closingDate: payload.closingDate, actionType: GET_CLOSING_DATE })

    return dispatch(
      onGetClosingDateSuccess({
        minDate: data?.payload.fechaCierre
      })
    )
  } catch (err) {
    return dispatch(onGetClosingDateError({ err }))
  }
}

export const GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO = 'GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO'
export const GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO_SUCCESS = 'GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO_SUCCESS'
export const GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO_ERROR = 'GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO_ERROR'

export const onGetInitialValueDirectDealStepTwo = makeActionCreator(GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO, 'payload')
export const onGetInitialValueDirectDealStepTwoSuccess = makeActionCreator(
  GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO_SUCCESS,
  'payload'
)
export const onGetInitialValueDirectDealStepTwoError = makeActionCreator(
  GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO_ERROR,
  'payload'
)

export const onGetInitialValueDirectDealStepTwoThunk = payload => async dispatch => {
  dispatch(onGetInitialValueDirectDealStepTwo())
  try {
    const { data } = await getDetailDirectDealSheet({
      id: payload.directDealCode,
      actionType: GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO
    })

    return dispatch(
      onGetInitialValueDirectDealStepTwoSuccess({
        sheet: {
          id: data?.payload?.id || '',
          code: data?.payload?.codigoTratoDirecto || '',
          cause: data?.payload?.causalTratoDirecto || '',
          providerName: data?.payload?.proveedor || '',
          providerDni: data?.payload?.rutProveedor || '',
          buyerDni: data?.payload?.rutComprador || '',
          buyerName: data?.payload?.nombreComprador || '',
          buyerLegalName: data?.payload?.nombreLegalComprador || '',
          withPublicity: data?.payload?.conPublicidad || false
        }
      })
    )
  } catch (err) {
    return dispatch(onGetInitialValueDirectDealStepTwoError({ err }))
  }
}

export const GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO = 'GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO'
export const GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_SUCCESS = 'GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_SUCCESS'
export const GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_ERROR = 'GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_ERROR'

export const onGetUnitMeasurementStepTwo = makeActionCreator(GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO)
export const onGetUnitMeasurementStepTwoSuccess = makeActionCreator(
  GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_SUCCESS,
  'payload'
)
export const onGetUnitMeasurementStepTwoError = makeActionCreator(
  GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_ERROR,
  'payload'
)
export const onGetUnitMeasurementStepTwoThunk = () => async dispatch => {
  dispatch(onGetUnitMeasurementStepTwo())

  try {
    const { data } = await getUnitsMeasurements({ actionType: GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO })
    return dispatch(
      onGetUnitMeasurementStepTwoSuccess({
        unitMeasurement: data?.payload?.unidades.map(unit => {
          return {
            value: unit?.id,
            code: unit?.codigo,
            name: unit?.nombre
          }
        })
      })
    )
  } catch (err) {
    return dispatch(onGetUnitMeasurementStepTwoError({ err }))
  }
}

export const STORAGE_DIRECT_DEAL_STEP_TWO = 'STORAGE_DIRECT_DEAL_STEP_TWO'
export const onStorageDirectDealStepTwo = makeActionCreator(STORAGE_DIRECT_DEAL_STEP_TWO, 'payload')

export const OPEN_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO = 'OPEN_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO'
export const onOpenSavedDirectDealStepTwo = makeActionCreator(OPEN_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO)

export const CLOSE_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO = 'CLOSE_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO'
export const onCloseSavedDirectDealStepTwo = makeActionCreator(CLOSE_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO)

export const GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO = 'GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO'
export const GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_SUCCESS = 'GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_SUCCESS'
export const GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_ERROR = 'GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_ERROR'

export const onGetAllCurrienciesDirectDealStepTwo = makeActionCreator(GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO)
export const onGetAllCurrienciesDirectDealStepTwoSuccess = makeActionCreator(
  GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_SUCCESS,
  'payload'
)
export const onGetAllCurrienciesDirectDealStepTwoError = makeActionCreator(
  GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_ERROR,
  'payload'
)

export const onGetAllCurrienciesDirectDealStepTwoThunk = () => async dispatch => {
  dispatch(onGetAllCurrienciesDirectDealStepTwo())

  try {
    const { data } = await getAllCurrencies({ actionType: GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO })

    return dispatch(
      onGetAllCurrienciesDirectDealStepTwoSuccess({
        currencies: data?.payload?.map(currency => {
          return {
            type: currency?.moneda,
            name: currency?.nombre,
            valueClp: currency?.valorPeso
          }
        })
      })
    )
  } catch (err) {
    return dispatch(onGetAllCurrienciesDirectDealStepTwoError({ err }))
  }
}

export const OPEN_ABILITY_DIRECT_DEAL_STEP_TWO = 'OPEN_ABILITY_DIRECT_DEAL_STEP_TWO'
export const onOpenAbilityDirectDealStepTwo = makeActionCreator(OPEN_ABILITY_DIRECT_DEAL_STEP_TWO)

export const CLOSE_ABILITY_DIRECT_DEAL_STEP_TWO = 'CLOSE_ABILITY_DIRECT_DEAL_STEP_TWO'
export const onCloseAbilityDirectDealStepTwo = makeActionCreator(CLOSE_ABILITY_DIRECT_DEAL_STEP_TWO)

export const SAVED_INFO_STEP_ONE_DIRECT_DEAL_STEP_TWO = 'SAVED_INFO_STEP_ONE_DIRECT_DEAL_STEP_TWO'
export const onSavedInfoStepOneDirectDealStepTwo = makeActionCreator(
  SAVED_INFO_STEP_ONE_DIRECT_DEAL_STEP_TWO,
  'payload'
)

export const SAVE_QUOTE_DIRECT_DEAL_STEP_TWO = 'SAVE_QUOTE_DIRECT_DEAL_STEP_TWO'
export const onSaveQuoteDirectDealStepTwo = makeActionCreator(SAVE_QUOTE_DIRECT_DEAL_STEP_TWO, 'payload')

export const UPDATE_QUOTE_DIRECT_DEAL_STEP_TWO = 'UPDATE_QUOTE_DIRECT_DEAL_STEP_TWO'
export const onUpdateQuoteDirectDealStepTwo = makeActionCreator(UPDATE_QUOTE_DIRECT_DEAL_STEP_TWO, 'payload')

export const REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO = 'REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO'
export const REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO_SUCCESS = 'REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO_SUCCESS'
export const REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO_ERROR = 'REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO_ERROR'

export const onRemoveQuoteDirectDealStepTwo = makeActionCreator(REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO)
export const onRemoveQuoteDirectDealStepTwoSuccess = makeActionCreator(
  REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO_SUCCESS,
  'payload'
)
export const onRemoveQuoteDirectDealStepTwoError = makeActionCreator(REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO_ERROR, 'payload')

export const onRemoveQuoteDirectDealStepTwoThunk = payload => async dispatch => {
  dispatch(onRemoveQuoteDirectDealStepTwo())

  try {
    await deleteQuote({ idQuote: payload.idQuote, actionType: REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO_SUCCESS })

    return batch(() => {
      dispatch(onRemoveQuoteDirectDealStepTwoSuccess({ idQuote: payload.idQuote }))
      payload.callback()
    })
  } catch (err) {
    return batch(() => {
      dispatch(onRemoveQuoteDirectDealStepTwoError({ err }))
      payload.callbackError()
    })
  }
}

export const RESET_DIRECT_DEAL_STEP_TWO = 'RESET_DIRECT_DEAL_STEP_TWO'
export const onResetDirectDealStepTwo = makeActionCreator(RESET_DIRECT_DEAL_STEP_TWO)

export const OPEN_QUOTE_DIRECT_DEAL_STEP_TWO = 'OPEN_QUOTE_DIRECT_DEAL_STEP_TWO'
export const onOpenQuoteDirectDealStepTwo = makeActionCreator(OPEN_QUOTE_DIRECT_DEAL_STEP_TWO)

export const CLOSE_QUOTE_DIRECT_DEAL_STEP_TWO = 'CLOSE_QUOTE_DIRECT_DEAL_STEP_TWO'
export const onCloseQuoteDirectDealStepTwo = makeActionCreator(CLOSE_QUOTE_DIRECT_DEAL_STEP_TWO)

export const OPEN_DETAIL_QUOTE_DIRECT_DEAL_STEP_TWO = 'OPEN_DETAIL_QUOTE_DIRECT_DEAL_STEP_TWO'
export const onOpenDetailQuoteDirectDealStepTwo = makeActionCreator(OPEN_DETAIL_QUOTE_DIRECT_DEAL_STEP_TWO)

export const CLOSE_DETAIL_QUOTE_DIRECT_DEAL_STEP_TWO = 'CLOSE_DETAIL_QUOTE_DIRECT_DEAL_STEP_TWO'
export const onCloseDetailQuoteDirectDealStepTwo = makeActionCreator(CLOSE_DETAIL_QUOTE_DIRECT_DEAL_STEP_TWO)
