import React from 'react'

import SearchIcon from '@mui/icons-material/Search'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import NearMeIcon from '@mui/icons-material/NearMe'

import { Divider as DividerBase, styled, Grid as BaseGrid, TableContainer as BaseTableContainer } from '@mui/material'

export const Root = styled('div')`
  width: 100%;
`
export const Grid = styled(({ ...props }) => <BaseGrid {...props} />)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;
  text-align: ${props => props.textAlign};
`
export const Divider = styled(({ ...props }) => <DividerBase {...props} />)`
  margin: ${props => props.margin};
  width: 100%;
`
export const SearchSuffix = styled('span')`
  border-right: 1px solid ${props => props.theme.palette.blue[50]};
  background-color: ${props => props.theme.palette.blue[50]};
  color: ${props => props.theme.palette.brand.white};
  padding: 10px;
`

export const SearchIconCustom = styled(SearchIcon)`
  width: 20px;
  height: 20px;
`
export const Link = styled('a')`
  color: ${props => props.theme.palette.blue[50]};
  cursor: pointer;
  font-size: ${props => props.fontSize || '14px'};
  margin: ${props => props.margin};
  font-family: ${props => props.theme.typography.fontFamily};
  font-style: normal;
  font-weight: ${props => props.fontWeight || 400};
  text-decoration-line: underline;
  display: block;
`
export const TableContainer = styled(BaseTableContainer)`
  &.MuiTableContainer-root {
    margin-bottom: 20px;
  }
`

export const IconArrowForward = styled(ArrowForwardIcon)`
  margin: ${props => props.margin};
  font-size: ${props => props.fontSize};
  display: inline-block;
`

export const RowRadio = styled('div')`
  text-align: left;
  width: 100%;
  margin-top: 8px;
`

export const CircleCompasArrow = styled(NearMeIcon)`
  font-size: 18px;
  color: ${props => props.theme.palette.blue[50]};
  background-color: ${props => props.theme.palette.blue[95]};
  border-radius: 50%;
  padding: 10px;
`

export const Strong = styled('strong')`
  font-size: 14px;
`
