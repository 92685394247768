import { batch } from 'react-redux'

import { makeActionCreator } from '../../config/store/utils'

import { postGenerateQuote, putSavedQuote } from '../../services/quotes'
import { getProviderAbility } from '../../services/config'

export const GENERATE_DIRECT_DEAL_QUOTE = 'GENERATE_DIRECT_DEAL_QUOTE'
export const GENERATE_DIRECT_DEAL_QUOTE_SUCCESS = 'GENERATE_DIRECT_DEAL_QUOTE_SUCCESS'
export const GENERATE_DIRECT_DEAL_QUOTE_ERROR = 'GENERATE_DIRECT_DEAL_QUOTE_ERROR'

export const onGenerateDirectDealQuote = makeActionCreator(GENERATE_DIRECT_DEAL_QUOTE, 'payload')
export const onGenerateDirectDealQuoteSuccess = makeActionCreator(GENERATE_DIRECT_DEAL_QUOTE_SUCCESS, 'payload')
export const onGenerateDirectDealQuoteError = makeActionCreator(GENERATE_DIRECT_DEAL_QUOTE_ERROR, 'payload')

export const onGenerateDirectDealQuoteThunk = payload => async dispatch => {
  dispatch(onGenerateDirectDealQuote())

  try {
    const { data } = await postGenerateQuote({ sheetCode: payload.code, actionType: GENERATE_DIRECT_DEAL_QUOTE })

    return batch(() => {
      dispatch(
        onGenerateDirectDealQuoteSuccess({
          quote: {
            idQuote: data?.payload?.id,
            idSubBusiness: data?.payload?.idBusiness,
            idBusiness: data?.payload?.idAdjunto
          }
        })
      )
      payload.callback()
    })
  } catch (err) {
    return dispatch(onGenerateDirectDealQuoteError({ err }))
  }
}

export const SAVED_DIRECT_DEAL_QUOTE = 'SAVED_DIRECT_DEAL_QUOTE'
export const SAVED_DIRECT_DEAL_QUOTE_SUCCESS = 'SAVED_DIRECT_DEAL_QUOTE_SUCCESS'
export const SAVED_DIRECT_DEAL_QUOTE_ERROR = 'SAVED_DIRECT_DEAL_QUOTE_ERROR'

export const onSavedDirectDealQuote = makeActionCreator(SAVED_DIRECT_DEAL_QUOTE, 'payload')
export const onSavedDirectDealQuoteSuccess = makeActionCreator(SAVED_DIRECT_DEAL_QUOTE_SUCCESS)
export const onSavedDirectDealQuoteError = makeActionCreator(SAVED_DIRECT_DEAL_QUOTE_ERROR, 'payload')

export const onSavedDirectDealQuoteThunk = payload => async dispatch => {
  dispatch(onSavedDirectDealQuote())

  try {
    await putSavedQuote({ payload: payload.quoteModel, actionType: SAVED_DIRECT_DEAL_QUOTE })

    return batch(() => {
      dispatch(onSavedDirectDealQuoteSuccess())
      payload.callback(payload.quoteModel)
    })
  } catch (err) {
    return batch(() => {
      dispatch(onSavedDirectDealQuoteError({ err }))
      payload.callbackError()
    })
  }
}

export const GET_INFO_DNI_DIRECT_DEAL_QUOTE = 'GET_INFO_DNI_DIRECT_DEAL_QUOTE'
export const GET_INFO_DNI_DIRECT_DEAL_QUOTE_SUCCESS = 'GET_INFO_DNI_DIRECT_DEAL_QUOTE_SUCCESS'
export const GET_INFO_DNI_DIRECT_DEAL_QUOTE_ERROR = 'GET_INFO_DNI_DIRECT_DEAL_QUOTE_ERROR'

export const onGetInfoDniDirectDealQuote = makeActionCreator(GET_INFO_DNI_DIRECT_DEAL_QUOTE, 'payload')
export const onGetInfoDniDirectDealQuoteSuccess = makeActionCreator(GET_INFO_DNI_DIRECT_DEAL_QUOTE_SUCCESS)
export const onGetInfoDniDirectDealQuoteError = makeActionCreator(GET_INFO_DNI_DIRECT_DEAL_QUOTE_ERROR, 'payload')

export const onGetInfoDniDirectDealQuoteThunk = payload => async dispatch => {
  dispatch(onGetInfoDniDirectDealQuote())

  try {
    const { data } = await getProviderAbility({
      providerDni: payload.quoteDni,
      actionType: GET_INFO_DNI_DIRECT_DEAL_QUOTE
    })

    return batch(() => {
      dispatch(onGetInfoDniDirectDealQuoteSuccess())
      payload.callback(data?.payload?.businessName)
    })
  } catch (err) {
    return batch(() => {
      dispatch(onGetInfoDniDirectDealQuoteError({ err }))
      payload.callbackError()
    })
  }
}

export const STORAGE_ID_DIRECT_DEAL_QUOTE = 'STORAGE_ID_DIRECT_DEAL_QUOTE'
export const onStorageIdDirectDealQuote = makeActionCreator(STORAGE_ID_DIRECT_DEAL_QUOTE, 'payload')

export const CLEAN_QUOTE_DIRECT_DEAL_QUOTE = 'CLEAN_QUOTE_DIRECT_DEAL_QUOTE'
export const onCleanQuoteDirectDeal = makeActionCreator(CLEAN_QUOTE_DIRECT_DEAL_QUOTE)
