import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { DatePicker, TimePicker, Typography } from '@chilecompra/react-kit/components'
import { useDatePicker, useTimePicker } from '@chilecompra/react-kit'

import { GridContainer, GridItem } from './DirectDealStepTwo.styles'

import { useImperativeFormRef } from '../../modules/hooks/useFormRef'

/**
 * The DirectDealStepTwoClosingDate's container
 */
const DirectDealStepTwoClosingDate = props => {
  const { closeDate, formStepTwoRef, loading, storage } = props

  const [minCloseDate, setMinCloseDate] = useState(new Date())

  const {
    error: dateFromCloseError,
    value: dateFromCloseValue,
    onChange: handleDateFromCloseChange,
    setValue: setDateFromClose
  } = useDatePicker({
    initialValue: (storage?.dateFromClose && new Date(storage.dateFromClose)) || new Date()
  })
  const {
    error: hourDateCloseError,
    value: hourDateCloseValue,
    onChange: handleHourDateCloseChange,
    setValue: setHourDateClose
  } = useTimePicker({
    initialValue: (storage?.hourDateCLose && new Date(storage.hourDateCLose)) || new Date()
  })

  useEffect(() => {
    if (storage?.dateFromClose) {
      setDateFromClose(new Date(storage.dateFromClose))
    } else {
      setDateFromClose(new Date(closeDate?.minDate))
      setMinCloseDate(new Date(closeDate?.minDate))
    }

    if (storage?.hourDateCLose) {
      setHourDateClose(new Date(storage.hourDateCLose))
    } else {
      setHourDateClose(new Date(closeDate?.minDate))
    }
  }, [closeDate])

  useImperativeFormRef(
    formStepTwoRef,
    () => ({
      dateFromCloseError,
      dateFromCloseValue,
      hourDateCloseError,
      hourDateCloseValue
    }),
    [dateFromCloseError, dateFromCloseValue, hourDateCloseError, hourDateCloseValue]
  )

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2}>
        <Typography variant="body1" fontWeight="bold">
          Fecha de cierre
        </Typography>
        <Typography fontSize="12px" fontWeight="regular" lineHeight="16px" variant="body2">
          La fecha de cierre debe ser a lo menos 5 días hábiles posteriores a la publicación.
        </Typography>
      </GridItem>
      <GridItem xs={12} md={6}>
        <GridContainer spacing={1}>
          <GridItem md={6} sm={12} xs={12}>
            <DatePicker
              error={dateFromCloseError}
              format="dd/MM/yyyy"
              label="Desde"
              maxDate=""
              value={dateFromCloseValue}
              typing={false}
              onChange={handleDateFromCloseChange}
              minDate={minCloseDate}
              loading={loading}
            />
          </GridItem>
          <GridItem md={6} sm={12} xs={12}>
            <TimePicker
              ampm
              error={hourDateCloseError}
              format="hh:mm"
              label="Hora de cierre"
              loading={loading}
              typing={false}
              value={hourDateCloseValue}
              onChange={handleHourDateCloseChange}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

DirectDealStepTwoClosingDate.propTypes = {
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  loading: PropTypes.bool,
  closeDate: PropTypes.shape({
    minDate: PropTypes.string,
    closingTime: PropTypes.string,
    minCloseDate: PropTypes.string
  }),
  storage: PropTypes.shape({
    dateFromClose: PropTypes.string,
    hourDateCLose: PropTypes.string
  })
}

export default DirectDealStepTwoClosingDate
