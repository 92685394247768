import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@chilecompra/react-kit/components'
import { useViewport } from '@chilecompra/react-kit'

import { Grid } from './DirectDealSummary.styles'
import { DIRECT_DEAL_SHEET_STATUS_DICTIONARY } from '../../config/settings/constants'

/**
 * The DirectDealSummary's footer.
 */
const DirectDealSummaryFooter = props => {
  const {
    disabledDesist,
    disabledIssue,
    disabledRequest,
    isBuyer,
    isSeller,
    loading,
    onBack,
    onDesist,
    onIssue,
    onRequest,
    showBtnDesist,
    status,
    withPublicity
  } = props
  const { size } = useViewport()

  return (
    <Grid container spacing={3} margin="0 0 50px" alignItems="center">
      <Grid item xs={12} sm={12} md={5} lg={4} textAlign={size.isSmall || size.isTiny ? 'center' : 'left'}>
        <Button variant="outlined" width={size.isSmall || size.isTiny ? '100%' : '60%'} onClick={onBack}>
          Volver
        </Button>
      </Grid>

      {isBuyer &&
        (status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.PUBLISH ||
          status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED) &&
        !showBtnDesist && (
          <Grid item xs={12} sm={12} md={5} lg={4} textAlign={size.isSmall || size.isTiny ? 'center' : 'right'}>
            <Button
              color="success"
              disabled={disabledIssue}
              loading={loading}
              variant="contained"
              width={size.isSmall || size.isTiny ? '100%' : 'auto'}
              onClick={onIssue}
            >
              Emitir orden de compra
            </Button>
          </Grid>
        )}

      {isSeller && status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.RECEIVING_REQUEST && withPublicity && (
        <Grid item xs={12} sm={12} md={5} lg={4} textAlign={size.isSmall || size.isTiny ? 'center' : 'right'}>
          <Button
            color="primary"
            variant="contained"
            disabled={disabledRequest}
            loading={loading}
            width={size.isSmall || size.isTiny ? '100%' : '60%'}
            onClick={onRequest}
          >
            Enviar solicitud
          </Button>
        </Grid>
      )}

      {isBuyer && status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED && showBtnDesist && (
        <Grid item xs={12} sm={12} md={5} lg={4} textAlign={size.isSmall || size.isTiny ? 'center' : 'right'}>
          <Button
            color="primary"
            disabled={disabledDesist}
            variant="contained"
            loading={loading}
            width={size.isSmall || size.isTiny ? '100%' : 'auto'}
            onClick={onDesist}
          >
            Desistir del Trato Directo
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

DirectDealSummaryFooter.propTypes = {
  disabledDesist: PropTypes.bool,
  disabledRequest: PropTypes.bool,
  disabledIssue: PropTypes.bool,
  isSeller: PropTypes.bool,
  isBuyer: PropTypes.bool,
  loading: PropTypes.bool,
  onBack: PropTypes.func,
  onDesist: PropTypes.func,
  onIssue: PropTypes.func,
  onRequest: PropTypes.func,
  showBtnDesist: PropTypes.bool,
  status: PropTypes.string,
  withPublicity: PropTypes.bool
}

export default DirectDealSummaryFooter
