import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Typography, Skeleton, Spinner } from '@chilecompra/react-kit/components'
import { useQuery } from '@chilecompra/react-kit'

import { Grid, GridItem, Link, IconArrowDownward } from './DirectDealSearchDownload.styles'
import { onDownloadDirectDealResultsThunk } from './DirectDealSearchDownload.actions'

const DirectDealSearchDownload = () => {
  const { loading: searchLoading, searchResult, error: searchError } = useSelector(state => state.searchFilters)
  const { loading: downLoading } = useSelector(state => state.directDealSearchDownload)
  const dispatch = useDispatch()
  const query = useQuery()

  const handleDownload = () => dispatch(onDownloadDirectDealResultsThunk({ query }))

  return (
    <Grid>
      <GridItem margin="0 0 16px 0">
        {searchLoading && <Skeleton width="150px" />}
        {!searchLoading && (
          <Typography variant="h4" fontWeight="bold" fontSize="16px">
            Existen {searchResult.resultCount} resultados para tu búsqueda
          </Typography>
        )}
      </GridItem>
      <GridItem margin="0 0 16px 0">
        {searchLoading && <Skeleton width="150px" />}
        {!searchLoading && searchError === undefined && searchResult.resultCount > 0 && (
          <>
            <Link onClick={handleDownload}>
              {downLoading ? 'Descargando resultados...' : 'Descargar resultados en excel'}
            </Link>

            {downLoading ? <Spinner size={15} /> : <IconArrowDownward margin="0 0 -3px 5px" fontSize="16px" />}
          </>
        )}
      </GridItem>
    </Grid>
  )
}

export default DirectDealSearchDownload
