import React from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { useEffectOnce } from '@chilecompra/react-kit'

import { onGetRegionsThunk } from './ConfigProvider.actions'
import InitialConfigurationContext from './ConfigProvider.context'

/**
 * The Configuration Provider' container.
 */
const ConfigProvider = props => {
  const { children } = props

  const dispatch = useDispatch()
  const { regionInfo, userUnitsInfo } = useSelector(state => state.configProvider)

  useEffectOnce(() => {
    batch(() => {
      dispatch(onGetRegionsThunk())
    })
  })

  return (
    <InitialConfigurationContext.Provider value={{ regionInfo, userUnitsInfo }}>
      {children}
    </InitialConfigurationContext.Provider>
  )
}

ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default ConfigProvider
