import { ONLY_NUMBERS_AND_THOUSANDS_DOT, ONLY_FORMAT_USD_CHECKED, ONLY_NUMBERS_CLF_CHECKED } from './regexes'

/**
 * Checks if the value is below the minimum and returns an error message if true
 *
 * @param {number} minValue
 * @param {string} customMessage
 * @returns
 */
export const checkInputErrorMinValue = (minValue, customMessage) => value => {
  return value < minValue ? customMessage || `El monto total cotizado no debe ser inferior a ${minValue}.` : ''
}

/**
 * Returns the appropriate regular expression for validating currency input
 * based on the provided currency type.
 *
 * Depending on the `currencyType` parameter, it matches specific formats:
 * - 'CLP': Only numbers with thousand separators (dot format).
 * - 'USD': Standard US Dollar format with potential checks.
 * - 'CLF' & 'UTM': Special formats for Chilean financial units (CLF and UTM).
 *
 * @param {string} currencyType - The type of currency ('CLP', 'USD', 'CLF', or 'UTM').
 * @returns {RegExp} - A regular expression tailored to the currency type.
 */
export function getRegexByCurrency(currencyType) {
  switch (currencyType) {
    case 'CLP':
      return ONLY_NUMBERS_AND_THOUSANDS_DOT
    case 'USD':
      return ONLY_FORMAT_USD_CHECKED
    case 'CLF':
      return ONLY_NUMBERS_CLF_CHECKED
    case 'UTM':
      return ONLY_NUMBERS_CLF_CHECKED
  }
}
