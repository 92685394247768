import React from 'react'
import { PUBLIC_URL } from '../../config/settings/environment'
import BrandLayout from '../../components/Layouts/BrandLayout'
import { Image404, Root, Text } from './NotFound.styles'

/**
 * The NotFound' page.
 */
const NotFound = () => (
  <BrandLayout>
    <Root>
      <Text>La página solicitada no existe</Text>
      <Image404 alt="La página solicitada no existe" src={`${PUBLIC_URL}/assets/images/404.svg`} />
    </Root>
  </BrandLayout>
)

export default NotFound
