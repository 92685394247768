import Cookie from 'js-cookie'
import { customizeError, getLogger } from '@chilecompra/react-kit'
import { isLocalEnv } from '../config/settings/environment'

const logger = getLogger('Cookies')

/**
 * To get a value from cookie domain mercadopublico.
 */
export const getCookieFromDomain = (key, options = {}) => {
  try {
    let entry = !isLocalEnv ? Cookie.get(key, { domain: '.mercadopublico.cl' }) : Cookie.get(key)

    if (entry && options.base64) {
      entry = window.atob(entry)
    }

    if (entry && options.json) {
      entry = JSON.parse(entry)
    }

    return entry
  } catch (error) {
    logger.warn(`Unable to get data from cookie ${key}`, error)

    throw customizeError({ originalError: error, reason: 'GET_COOKIE_ERROR' })
  }
}
