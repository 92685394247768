import React from 'react'
import PropTypes from 'prop-types'

import { Button, CircleSuccessIcon, Dialog, Typography } from '@chilecompra/react-kit/components'
import { Grid } from './DirectDealStepOne.styles'

/**
 * The DirectDealStepOne's dialog.
 */
const DirectDealStepOneDialog = ({ open, sheetCode, onBack, onNext }) => {
  return (
    <Dialog open={open} disableBackdropClick verticalAlign="top" onClose={onBack}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item textAlign="center" padding="13px">
          <CircleSuccessIcon />
          <Typography fontSize="18px" lineHeight="22px" fontWeight="bold" color="black1">
            Nuevo
          </Typography>
        </Grid>
        <Grid item margin="30px 0" textAlign="center">
          <Typography fontSize="16px" lineHeight="21px" color="black1">
            Se ha creado con éxito la Ficha de Trato Directo ID:
          </Typography>
          <Typography fontSize="16px" lineHeight="21px" color="black1">
            {sheetCode || ''}
          </Typography>
        </Grid>
        <Grid item padding="0 50px" margin="4px 0" textAlign="center">
          <Button color="primary" variant="contained" onClick={onNext}>
            Continuar con el Trato Directo
          </Button>
        </Grid>
        <Grid item padding="0 50px" margin="4px 0" textAlign="center">
          <Button variant="text" onClick={onBack}>
            Volver al inicio del módulo
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

DirectDealStepOneDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  sheetCode: PropTypes.string,
  onBack: PropTypes.func,
  onNext: PropTypes.func
}

export default DirectDealStepOneDialog
