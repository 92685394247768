import { REACT_APP_API_BASE_URL } from '../config/settings/environment'
import { factoryService, getJsonRequest, postJsonRequest } from '@chilecompra/react-kit/requests'

/**
 * Generates the request DirectDealSheet body.
 */
const generateDirectDealSheetBody = sheet => {
  const dateClose = sheet.dateClose?.split('T')[0] || ''
  const hourClose = sheet.hourClose?.split('T')[1] || ''
  return {
    idTD: sheet.id,
    codigoTratoDirecto: sheet.code,
    duracionContrato: sheet.contractDuration,
    tipoContrato: sheet.contractType,
    despacho: {
      disCode: sheet.dispatchRegion,
      disCodeComuna: sheet.dispatchCity,
      direccion: sheet.dispatchAddress,
      comentarios: sheet.dispatchComment
    },
    costo: {
      montoDespacho: sheet.dispatchAmountDelivery,
      montoDescuento: sheet.dispatchAmountDiscount,
      obsDescuento: sheet.dispatchObsDiscount,
      montoCargo: sheet.dispatchAmountCharge,
      obsCargo: sheet.dispatchObsCharge,
      taxCode: sheet.dispatchTax
    },
    justificacion: sheet.sheetJustification,
    nombre: sheet.sheetName,
    descripcion: sheet.sheetDescription,
    fechaEjecucion: sheet.dateExecute,
    fechaCierre: (dateClose && dateClose.concat('T', hourClose)) || undefined,
    moneda: sheet.sheetCurrency,
    rutProveedor: sheet.providerDni,
    productos: sheet.products.map(product => {
      return {
        codProducto: product.productId,
        codCategoria: product.categoryId,
        cantidad: product.amount,
        descripcion: product.detail,
        unidadMedida: product.unitMeasure,
        valorUnitario: product.unitPrice,
        zgen: product.codeZGEN,
        conoceCodigoZgen: product.dontKnowCodeZGEN,
        cargo: product.charge,
        descuento: product.discount
      }
    }),
    montoTotal: sheet.total,
    montoTotalClp: sheet.totalClp
  }
}

/**
 * Gets the list of Contract Duration
 */
export const getContractDuration = factoryService(() =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/parametros/duracion-contrato`)
)

/**
 * Gets the list of Contract Types
 */
export const getContractType = factoryService(() =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/parametros/tipo-contrato`)
)
/**
 * Gets the list of Currency Types
 */
export const getCurrencyType = factoryService(() =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/parametros/monedas`)
)

/**
 * Gets the list of Currency Types
 */
export const getTaxesDetail = factoryService(() =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/parametros/impuestos`)
)

/**
 * Gets the Closing Date
 */
export const getClosingDate = factoryService(({ closingDate }) => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/parametros/fecha-cierre?closingDate=${closingDate}`)
})

/**
 * POST: saved a direct deal sheet
 */
export const saveDirectDeal = factoryService(({ sheet }) => {
  const body = generateDirectDealSheetBody(sheet)
  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo`, { body })
})

/**
 * POST: publish a direct deal sheet
 */
export const publishDirectDeal = factoryService(({ sheet }) => {
  const body = generateDirectDealSheetBody(sheet)
  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/publicar`, { body })
})
