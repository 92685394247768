import React from 'react'
import PropTypes from 'prop-types'
import { useAuthorization } from '../../containers/AuthProvider/AuthProvider.hooks'

/**
 * The Public Section's component.
 */
const PublicSection = props => {
  const { children } = props
  const { isPrivate } = useAuthorization()

  if (isPrivate) {
    return null
  }

  return <>{children}</>
}

PublicSection.propTypes = {
  children: PropTypes.node.isRequired
}

export default PublicSection
