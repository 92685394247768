import React from 'react'
import PropTypes from 'prop-types'

import { Button, CircleWarningIcon, Dialog, Typography } from '@chilecompra/react-kit/components'

import { Grid } from './DirectDealVoucher.styles'

const DirectDealVoucherIssueDialog = props => {
  const { open, onClose, onIssue } = props
  return (
    <Dialog open={open} disableBackdropClick verticalAlign="top" onClose={onClose}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item textAlign="center" padding="13px">
          <CircleWarningIcon />
          <Typography variant="h3" lineHeight="22px" fontWeight="bold" color="black1">
            Serás derivado a la creación de la orden de compra
          </Typography>
        </Grid>
        <Grid item margin="16px 0" textAlign="center">
          <Typography variant="body2" lineHeight="18px" color="black1">
            Para finalizar con el proceso de Trato Directo, te derivaremos al módulo de creación de orden de compra. Se
            generará el ID y deberás completar la información restante antes de enviar al proveedor.
          </Typography>
        </Grid>
        <Grid item padding="0 50px" margin="4px 0" textAlign="center">
          <Button color="success" variant="contained" onClick={onIssue}>
            Ir a crear Orden de Compra
          </Button>
        </Grid>
        <Grid item padding="0 50px" margin="4px 0" textAlign="center">
          <Button variant="text" onClick={onClose}>
            Volver
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

DirectDealVoucherIssueDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onIssue: PropTypes.func
}

export default DirectDealVoucherIssueDialog
