import { makeActionCreator } from '../../config/store/utils'
import { getRegion } from '../../services/config'

export const GET_REGIONS = 'GET_REGIONS'
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS'
export const GET_REGIONS_ERROR = 'GET_REGIONS_ERROR'

export const onGetRegion = makeActionCreator(GET_REGIONS)
export const onGetRegionError = makeActionCreator(GET_REGIONS_ERROR, 'payload')
export const onGetRegionSuccess = makeActionCreator(GET_REGIONS_SUCCESS, 'payload')

export const onGetRegionsThunk = () => async dispatch => {
  dispatch(onGetRegion())

  try {
    const { data } = await getRegion()
    const regions_ = data.payload?.map(region => ({ name: region.nombre, value: region.id }))
    const everybodyItem = { name: 'Todos', value: '' }
    const regions = [everybodyItem, ...regions_]

    return dispatch(onGetRegionSuccess({ regions }))
  } catch (error) {
    return dispatch(onGetRegionError(error))
  }
}
