/**
 * String to boolean converter
 *
 * @param {string} str
 * @returns {boolean}
 */
export const stringToBoolean = str => {
  return str.toLowerCase() === 'true'
}
/**
 * Function that receives the dni to retonate it formatted.
 * the returned format is the following xx.xxx.xxx.xxx-x
 *
 * @param {string} dni
 * @returns {string}
 */
export const formatDNI = dni => {
  const cleanDni = dni.replace(/[^\dkK]/g, '')

  if (cleanDni.length === 0) {
    return ''
  }

  const dv = cleanDni.slice(-1)
  let numbers = cleanDni.slice(0, -1)

  let formattedDni = ''
  while (numbers.length > 3) {
    formattedDni = '.' + numbers.slice(-3) + formattedDni
    numbers = numbers.slice(0, -3)
  }

  formattedDni = numbers + formattedDni
  formattedDni += '-' + dv.toUpperCase()

  return formattedDni
}
/**
 * function that cleans the DNI to remove the dots
 *
 * @param {string} dni
 * @returns {string}
 */
export const unformatDNI = dni => {
  return dni.replace(/\./g, '')
}
/**
 * Date formatter: it must receive the date in string with the format timestamp and returns the date in the format dd-mm-yyyy,
 * and if you want to include it would be as follows dd-mm-yyyy hh:mm
 *
 * @param {string} dateString
 * @param {boolean} withHours
 * @returns {string}
 */
export function formatDate(dateString, withHours = false) {
  if (!dateString) {
    return ''
  }

  const date = new Date(dateString)

  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()

  if (!withHours) {
    return `${day}-${month}-${year}`
  }

  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  return `${day}-${month}-${year} ${hours}:${minutes}`
}

/**
 * Formats a value based on the provided currency type.
 *
 * This function applies the correct formatting for the value depending on the
 * type of currency specified:
 * - 'CLP': Formats the value as Chilean Pesos.
 * - 'USD': Formats the value as US Dollars.
 * - 'UTM' & 'CLF': Applies a format specific to Chilean financial units (UTM and CLF).
 *
 * @param {string} typeCurrency - The type of currency ('CLP', 'USD', 'UTM', or 'CLF').
 * @param {number|string} value - The value to be formatted.
 * @returns {string} - The formatted currency value.
 */
export const formatTypeCurrency = (typeCurrency, value) => {
  switch (typeCurrency) {
    case 'CLP':
      return formatCLP(value)
    case 'USD':
      return formatCurrencyUSD(value)
    case 'UTM':
    case 'CLF':
      return formatOtherCurrencyChile(value)
  }
}

/**
 * Formats a given value to match the Chilean currency formats (CLF, UTM).
 *
 * The function cleans the input value by removing unwanted characters and applying
 * Chilean number formatting. It replaces dots with commas for decimal points and adds
 * thousands separators (dots) for large numbers.
 *
 * - If the value includes a decimal, it separates the integer and fractional parts.
 * - Thousands separators are applied only to the integer portion of the value.
 *
 * @param {number|string} value - The value to be formatted.
 * @returns {string} - The formatted value as a string, adhering to Chilean currency conventions.
 */
export const formatOtherCurrencyChile = value => {
  let valueString = ''
  let valueClean = ''

  if (!isNaN(value)) {
    valueString = value.toString()
    valueClean = valueString.replace(/[^0-9.]/g, '').replace('.', ',')
  } else {
    valueString = value
    valueClean = valueString.replace(/[^0-9,]/g, '')
  }

  const valueSeparate = valueClean.split(',')
  const valueBeforeComma = valueSeparate[0]
  const valueAfterComma = valueSeparate[1]

  const valueDot = valueBeforeComma.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  if (valueSeparate.length > 1) {
    return `${valueDot},${valueAfterComma || ''}`
  } else {
    return valueDot
  }
}

/**
 * Formats a given value to match the standard US Dollar currency format.
 *
 * The function removes any non-numeric characters and applies formatting:
 * - Adds commas as thousands separators.
 * - Ensures that a period is used as a decimal separator if the value has a fractional part.
 *
 * - If the value has decimals, it separates the integer and decimal parts for formatting.
 * - Commas are added only to the integer part of the value.
 *
 * @param {number|string} value - The value to be formatted as a USD currency string.
 * @returns {string} - The formatted value as a string in US Dollar format.
 */
export const formatCurrencyUSD = value => {
  const valueString = value.toString()
  const valueClean = valueString.replace(/[^0-9.]/g, '')
  const valueSeparate = valueClean.split('.')

  const valueBeforeDot = valueSeparate[0]
  const valueAfterDot = valueSeparate[1]

  const valueDot = valueBeforeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  if (valueSeparate.length > 1) {
    return `${valueDot}.${valueAfterDot || ''}`
  } else {
    return `${valueDot}`
  }
}

/**
 * Formats a given value to match the Chilean Peso (CLP) format.
 *
 * The function removes any non-numeric characters and applies thousands separators:
 * - Only numbers are retained.
 * - Dots are added as thousands separators for large numbers.
 *
 * @param {number|string} value - The value to be formatted as Chilean Pesos (CLP).
 * @returns {string} - The formatted value as a string in CLP format with thousands separators.
 */
export const formatCLP = value => {
  const valueString = value.toString()
  const valueClean = valueString.replace(/[^0-9]/g, '')
  const valueDot = valueClean.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  return valueDot
}

/**
 * Converts a formatted currency string to a numerical value based on the specified currency type.
 *
 * The function handles different currency types as follows:
 * - For 'CLF' and 'UTM': Parses the amount by replacing non-numeric characters and converting the comma to a decimal point.
 * - For 'CLP': Removes non-numeric characters and converts the resulting string to an integer.
 * - For 'USD': Removes non-numeric characters and converts the resulting string to a float.
 *
 * @param {string} amount - The formatted currency amount as a string.
 * @param {string} currencyType - The type of currency ('CLF', 'UTM', 'CLP', or 'USD').
 * @returns {number} - The parsed numerical value of the currency amount.
 */
export const formatCurrencyToNumber = (amount, currencyType) => {
  let value = 0

  if (currencyType === 'CLF' || currencyType === 'UTM') {
    value = parseFloat(amount?.replace(/[^0-9,]/g, '').replace(',', '.') || '0')
  } else if (currencyType === 'CLP') {
    value = parseInt(amount?.replace(/[^0-9]/g, '') || '0')
  } else if (currencyType === 'USD') {
    value = parseFloat(amount?.replace(/[^0-9.]/g, '') || '0')
  }

  return value
}
