import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  Button,
  checkInputErrorRequired,
  checkInputErrorOnlyNumbers,
  checkInputErrorMaxLength,
  checkInputErrorEmail,
  useInput,
  useViewport,
  useQueryParam,
  useQuery,
  useEffectOnce,
  Skeleton,
  checkInputErrorMinLength
} from '@chilecompra/react-kit'
import { Card, Input, Typography } from '@chilecompra/react-kit/components'

import AttachmentsWrapper from '../../components/components/AttachmentsWrapper.component'

import {
  onGenerateDirectDealQuoteThunk,
  onGetInfoDniDirectDealQuoteThunk,
  onSavedDirectDealQuoteThunk
} from './DirectDealQuote.actions'
import { onSaveQuoteDirectDealStepTwo } from '../DirectDealStepTwo/DirectDealStepTwo.actions'
import { onShowSnackbar } from '../SnackBarProvider/SnackBarProvider.actions'

import { formatDNI } from '../../modules/utils/formatters'
import routes from '../../config/settings/routes'
import { CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL } from '../../config/settings/constants'

import { AmountPrefix, Grid, SearchSuffix, SearchIconCustom } from './DirectDealQuote.styles'

/**
 * The DirectDealQuote's creator container.
 */
const DirectDealQuoteCreator = () => {
  const { size } = useViewport()
  const directDealSheetCode = useQueryParam('code')
  const query = useQuery()
  const navigate = useNavigate()
  const { quote, loading } = useSelector(state => state.directDealQuote)
  const dispatch = useDispatch()

  const {
    error: quoteDniError,
    onChange: onChangeQuoteDni,
    onError: onErrorQuoteDni,
    value: quoteDniValue
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()],
    formatCallbacks: [value => formatDNI(value)]
  })

  const {
    error: quoteLegalNameError,
    onChange: onChangeQuoteLegalName,
    value: quoteLegalNameValue,
    setValue: setQuoteLegalName
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()]
  })

  const {
    error: quotePhoneError,
    onChange: onChangeQuotePhone,
    onError: onErrorQuotePhone,
    value: quotePhoneValue
  } = useInput({
    errorCallbacks: [
      checkInputErrorRequired(),
      checkInputErrorOnlyNumbers(),
      checkInputErrorMaxLength(9),
      checkInputErrorMinLength(9)
    ]
  })

  const {
    error: quoteEmailError,
    onChange: onChangeQuoteEmail,
    onError: onErrorQuoteEmail,
    value: quoteEmailValue
  } = useInput({
    errorCallbacks: [
      checkInputErrorRequired(),
      checkInputErrorMaxLength(200),
      checkInputErrorEmail('Formato de email incorrecto.')
    ]
  })

  const handlerBack = () => {
    query.set('code', directDealSheetCode)
    navigate({ pathname: routes.directDealStepTwo, search: query.toString() }, { replace: true })
  }

  const handlerSaved = () => {
    const quoteModel = {
      ...quote,
      idQuote: quote?.idQuote,
      contributorDni: quoteDniValue,
      contributorLegalName: quoteLegalNameValue,
      contributorPhone: quotePhoneValue,
      contributorEmail: quoteEmailValue
    }

    dispatch(
      onSavedDirectDealQuoteThunk({
        quoteModel,
        callback: quoteModel => {
          handlerBack()
          dispatch(onSaveQuoteDirectDealStepTwo(quoteModel))
          dispatch(
            onShowSnackbar({
              title: 'Cotización agregada con éxito',
              severity: 'success'
            })
          )
        },
        callbackError: () => {
          dispatch(
            onShowSnackbar({
              title: 'No se pudo guardar la cotización.',
              message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
              severity: 'error'
            })
          )
        }
      })
    )
  }

  const handlerGetLegalName = () => {
    dispatch(
      onGetInfoDniDirectDealQuoteThunk({
        quoteDni: quoteDniValue,
        callback: legalName => {
          setQuoteLegalName(legalName)
        },
        callbackError: () => {
          setQuoteLegalName('')
          dispatch(
            onShowSnackbar({
              title: 'Proveedor no encontrado',
              message: 'Te pedimos que lo vuelvas a intentar.',
              severity: 'error'
            })
          )
        }
      })
    )
  }

  const disableSaved = useMemo(() => {
    return (
      quoteDniValue === '' ||
      quoteDniError !== '' ||
      quoteLegalNameValue === '' ||
      quoteLegalNameError !== '' ||
      quotePhoneValue === '' ||
      quotePhoneError !== '' ||
      quoteEmailValue === '' ||
      quoteEmailError !== ''
    )
  }, [
    quoteDniValue,
    quoteDniError,
    quoteLegalNameValue,
    quoteLegalNameError,
    quotePhoneValue,
    quotePhoneError,
    quoteEmailValue,
    quoteEmailError
  ])

  useEffectOnce(() => {
    dispatch(onGenerateDirectDealQuoteThunk({ code: directDealSheetCode }))
  })

  return (
    <Card color="blank" enableBorder padding="24px 32px">
      <Grid container spacing={2} margin="8px 0 0">
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Input
            error={quoteDniError}
            label="RUT cotizante"
            loading={loading}
            suffix={
              <SearchSuffix onClick={handlerGetLegalName}>
                <SearchIconCustom />
              </SearchSuffix>
            }
            onChange={onChangeQuoteDni}
            onError={onErrorQuoteDni}
            value={quoteDniValue}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Input
            error={quoteLegalNameError}
            label="Razón social cotizante"
            loading={loading}
            onChange={onChangeQuoteLegalName}
            value={quoteLegalNameValue}
          />
        </Grid>
        <Grid item lg={6} md={4} sm={false} xs={false} />
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Input
            error={quotePhoneError}
            label="Teléfono cotizante"
            loading={loading}
            prefix={<AmountPrefix> +56</AmountPrefix>}
            onChange={onChangeQuotePhone}
            onError={onErrorQuotePhone}
            value={quotePhoneValue}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Input
            error={quoteEmailError}
            label="Email cotizante"
            loading={loading}
            type="email"
            onChange={onChangeQuoteEmail}
            onError={onErrorQuoteEmail}
            value={quoteEmailValue}
          />
        </Grid>
        <Grid item lg={6} md={4} sm={false} xs={false} />
        <Grid item lg={6} md={8} sm={12} xs={12}>
          <Typography variant="body1" fontWeight="bold">
            Adjuntar archivos
          </Typography>
          <Typography variant="body2">Se admiten archivos en formato con un peso máximo de 20mb.</Typography>

          {!loading && quote && (
            <AttachmentsWrapper
              business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
              idBusiness={quote.idBusiness}
              subBusiness={quote.idSubBusiness}
              isPrivate
              color="blank"
              canDelete
              canDeleteBulk
              canRead
              canReadBulk
              canWrite
              canWriteBulk
              showModal
            />
          )}

          {loading && <Skeleton width="250px" />}
        </Grid>
        <Grid item lg={6} md={4} sm={false} xs={false} />
        <Grid item lg={4} md={6} sm={6} xs={12} textAlign="left" order={{ lg: 1, md: 1, sm: 1, xs: 2 }}>
          <Button width={size.isSmall || size.isTiny ? '100%' : '50%'} onClick={handlerBack}>
            Volver
          </Button>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12} textAlign="left" order={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
          <Button
            disabled={disableSaved}
            loading={loading}
            variant="outlined"
            width={size.isSmall || size.isTiny ? '100%' : '60%'}
            onClick={handlerSaved}
          >
            Guardar Cotizacion
          </Button>
        </Grid>
      </Grid>
    </Card>
  )
}

export default DirectDealQuoteCreator
