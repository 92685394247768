import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { CircleSuccessIcon, getCookie, useEffectOnce, useViewport } from '@chilecompra/react-kit'
import { Button, Card, Table, Typography } from '@chilecompra/react-kit/components'

import DirectDealVoucherIssueDialog from './DirectDealVoucher.issue.dialog'

import FormattedCurrencyComponent from '../../components/components/FormattedCurrency.component'
import FormattedDateComponent from '../../components/components/FormattedDate.component'

import {
  onCleanDirectDealVoucher,
  onCloseConfirmIssueDirectDealVoucher,
  onOpenConfirmIssueDirectDealVoucher
} from './DirectDealVoucher.actions'

import {
  CellVoucher,
  Divider,
  Grid,
  HeaderVoucher,
  Strong,
  TableCell,
  TableRow,
  Root
} from './DirectDealVoucher.styles'

import routes from '../../config/settings/routes'
import { REACT_APP_MERCADO_PUBLICO_BASE_URL } from '../../config/settings/environment'

const DirectDealVoucherContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { size } = useViewport()
  const { voucher: directDealVoucherInfo, dialogs: directDealVoucherDialog } = useSelector(
    state => state.directDealVoucher
  )

  const handlerBackToMenu = () => {
    const query = getCookie('search')
    navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
    dispatch(onCleanDirectDealVoucher())
  }
  const handlerContinueIssue = () => {
    dispatch(onOpenConfirmIssueDirectDealVoucher())
  }

  const handlerCloseConfirmIssue = () => {
    dispatch(onCloseConfirmIssueDirectDealVoucher())
  }

  const handlerRedirectPurchase = () => {
    window.location.href = `${REACT_APP_MERCADO_PUBLICO_BASE_URL}/PurchaseOrder/WizardCreateAndRedirect.aspx?POMODE=POEdit&porId=${directDealVoucherInfo.porId}`
    dispatch(onCleanDirectDealVoucher())
  }

  const handlerRedirectSummarySheet = () => {
    navigate(routes.directDealSummary.replace(/:directDealId/, directDealVoucherInfo?.code))
    dispatch(onCleanDirectDealVoucher())
  }

  useEffectOnce(() => {
    handlerCloseConfirmIssue()
  })

  return (
    <Root>
      <Divider />
      <Grid container spacing={2} margin="43px 0">
        <Grid item xs={12} textAlign="center" padding="32px 0">
          <CircleSuccessIcon />
          <Typography variant="h2" color="black1" fontWeight="bold">
            Ficha de Trato Directo creada con éxito
          </Typography>
          <Typography variant="h3" color="success" fontWeight="bold">
            Número: {directDealVoucherInfo?.code}
          </Typography>
        </Grid>

        {directDealVoucherInfo?.withPublicity && (
          <>
            <Grid item lg={3} md={3} sm={0} xs={0} />
            <Grid item lg={6} md={6} sm={12} xs={12} textAlign="center">
              <Card color="primary" padding="24px 16px">
                <Typography variant="body1" color="black1">
                  Al ser un Trato Directo por la causal "<Strong>{directDealVoucherInfo?.cause?.name}</Strong>" y el
                  monto de la compra es superior a las 1.000 UTM, el estado del Trato Directo permanecerá en estado{' '}
                  <Strong>Recibiendo solicitudes</Strong> hasta que se cumpla la fecha de cierre el día "
                  <Strong>
                    <FormattedDateComponent date={directDealVoucherInfo?.dateClosed} />
                    {directDealVoucherInfo?.hourClosed}
                  </Strong>
                  ".
                </Typography>
              </Card>
            </Grid>
            <Grid item lg={3} md={3} sm={0} xs={0} />
          </>
        )}

        {size.isTiny && (
          <>
            <Grid item lg={3} md={3} sm={0} xs={0} />
            <Grid item lg={6} md={6} sm={12} xs={12} textAlign="center">
              <Grid container>
                <Grid item md={3} sm={3} xs={12}>
                  <HeaderVoucher>
                    <Typography variant="body2" fontWeight="bold">
                      Nombre
                    </Typography>
                  </HeaderVoucher>
                </Grid>
                <Grid item md={9} sm={9} xs={12}>
                  <CellVoucher>
                    <Typography variant="body2">{directDealVoucherInfo?.name}</Typography>
                  </CellVoucher>
                </Grid>
                <Grid item md={3} sm={3} xs={12}>
                  <HeaderVoucher>
                    <Typography variant="body2" fontWeight="bold">
                      Causal de Trato Directo
                    </Typography>
                  </HeaderVoucher>
                </Grid>
                <Grid item md={9} sm={9} xs={12}>
                  <CellVoucher>
                    <Typography variant="body2">{directDealVoucherInfo?.cause?.name}</Typography>
                  </CellVoucher>
                </Grid>
                <Grid item md={3} sm={3} xs={12}>
                  <HeaderVoucher>
                    <Typography variant="body2" fontWeight="bold">
                      Monto total
                    </Typography>
                  </HeaderVoucher>
                </Grid>
                <Grid item md={9} sm={9} xs={12}>
                  <CellVoucher>
                    <Typography variant="body2">
                      <FormattedCurrencyComponent
                        amount={directDealVoucherInfo?.total}
                        currency={directDealVoucherInfo?.currency?.type}
                        includeSymbol
                      />
                    </Typography>
                  </CellVoucher>
                </Grid>
                <Grid item md={3} sm={3} xs={12}>
                  <HeaderVoucher>
                    <Typography variant="body2" fontWeight="bold">
                      Proveedor
                    </Typography>
                  </HeaderVoucher>
                </Grid>
                <Grid item md={9} sm={9} xs={12}>
                  <CellVoucher>
                    <Typography variant="body2">
                      {directDealVoucherInfo?.providerLegalName}
                      <Typography color="black3" fontSize="14px">
                        RUT: {directDealVoucherInfo?.providerDni}
                      </Typography>
                    </Typography>
                  </CellVoucher>
                </Grid>
                <Grid item md={3} sm={3} xs={12}>
                  <HeaderVoucher>
                    <Typography variant="body2" fontWeight="bold">
                      Fecha de creación
                    </Typography>
                  </HeaderVoucher>
                </Grid>
                <Grid item md={9} sm={9} xs={12}>
                  <CellVoucher>
                    <FormattedDateComponent date={new Date()} />
                  </CellVoucher>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} md={3} sm={0} xs={0} />
          </>
        )}

        {!size.isTiny && (
          <>
            <Grid item lg={3} md={3} sm={0} xs={0} />
            <Grid item lg={6} md={6} sm={12} xs={12} textAlign="center">
              <Table accessibility={{ label: 'Listado del detalle de la ficha de trato directo creada' }}>
                <TableRow>
                  <TableCell enableHead full>
                    Nombre
                  </TableCell>
                  <TableCell>{directDealVoucherInfo?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell enableHead full>
                    Causal de Trato Directo
                  </TableCell>
                  <TableCell>{directDealVoucherInfo?.cause?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell enableHead full>
                    Monto total
                  </TableCell>
                  <TableCell>
                    <FormattedCurrencyComponent
                      amount={directDealVoucherInfo?.total}
                      currency={directDealVoucherInfo?.currency?.type}
                      includeSymbol
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell enableHead full>
                    Proveedor
                  </TableCell>
                  <TableCell>
                    {directDealVoucherInfo?.providerLegalName}
                    <Typography color="black3" fontSize="14px">
                      RUT: {directDealVoucherInfo?.providerDni}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell enableHead full>
                    Fecha de creación
                  </TableCell>
                  <TableCell>
                    <FormattedDateComponent date={new Date()} />
                  </TableCell>
                </TableRow>
              </Table>
            </Grid>
            <Grid item lg={3} md={3} sm={0} xs={0} />
          </>
        )}

        <Grid item lg={3} md={3} sm={0} xs={0} />
        <Grid item lg={6} md={6} sm={12} xs={12} textAlign="center">
          <Typography variant="body1">
            A continuación podrás generar la orden de compra respectiva la cuál deberás editar y enviar a autorizar en
            el módulo Búsqueda y Gestión de Orden de Compra.
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={0} xs={0} />

        {directDealVoucherInfo?.withPublicity && (
          <Grid item xs={12} textAlign="center" margin="32px 0 0">
            <Button color="primary" onClick={handlerRedirectSummarySheet}>
              Ver la ficha del Trato Directo
            </Button>
          </Grid>
        )}

        {!directDealVoucherInfo?.withPublicity && (
          <Grid item xs={12} textAlign="center" margin="32px 0 0">
            <Button color="primary" onClick={handlerContinueIssue}>
              Continuar y emitir orden de compra
            </Button>
          </Grid>
        )}

        <Grid item xs={12} textAlign="center">
          <Button variant="text" onClick={handlerBackToMenu}>
            Volver al inicio del módulo
          </Button>
        </Grid>
      </Grid>

      <DirectDealVoucherIssueDialog
        open={directDealVoucherDialog.openConfirmIssue}
        onClose={handlerCloseConfirmIssue}
        onIssue={handlerRedirectPurchase}
      />
    </Root>
  )
}

export default DirectDealVoucherContainer
