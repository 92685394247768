import { inIframe } from '@chilecompra/react-kit'
import { REACT_APP_MERCADO_PUBLICO_BASE_URL } from '../../config/settings/environment'

export const dialogScrollTop = () => {
  if (inIframe()) {
    window.parent.postMessage(
      {
        action: 'scrollTo',
        value: {
          x: 0,
          y: 0
        }
      },
      REACT_APP_MERCADO_PUBLICO_BASE_URL
    )
  }
}
