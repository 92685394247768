import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getCookie } from '@chilecompra/react-kit'

import BrandLayout from '../../components/Layouts/BrandLayout'
import routes from '../../config/settings/routes'
import { initializeReCaptcha } from '../../modules/hooks/reCaptcha'
import { PROTECT_BY_STEP_DICTIONARY } from '../../config/settings/constants'

import DirectDealPageHeader from '../../containers/DirectDealPageHeader/DirectDealPageHeader.container'
import DirectDealStepTwoContainer from '../../containers/DirectDealStepTwo/DirectDealStepTwo.container'
import AuthorizationProviderContainer from '../../containers/AuthProvider/AuthProvider.container'
import AuthorizationContext from '../../containers/AuthProvider/AuthProvider.context'

import ProtectByProfile from '../../components/Security/ProtectByProfile'
import ProtectByStep from '../../components/Security/ProtectByStep'
import NotFound from '../NotFound/NotFound'

import { Grid } from './DirectDealStepTwo.styles'

/**
 * The DirectDealStepTwo's page.
 */
const DirectDealStepTwo = () => {
  const navigate = useNavigate()
  const { stepOne: directDealStepOneSheet } = useSelector(state => state.directDealStepTwo)

  const directDealheading = {
    breadcrumbs: [
      {
        text: 'Escritorio',
        onRedirectTo: () => {
          const query = getCookie('search')
          navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
        }
      },
      {
        text: 'Trato Directo',
        onRedirectTo: () => {
          const query = getCookie('search')
          navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
        }
      },
      {
        text: `Crear Trato Directo`
      }
    ],
    step: 'PASO 2 DE 2',
    title: 'Crear ficha de Trato Directo',
    description: `Ingresa toda la información del Trato Directo que deseas realizar, la causal, justificación, productos o servicios, entre otros.
    Al finalizar podrás continuar con la emisión de la orden de compra.`,
    code: directDealStepOneSheet?.code
  }

  return (
    <AuthorizationProviderContainer>
      <AuthorizationContext.Consumer>
        {({ isPrivated }) => {
          if (isPrivated) initializeReCaptcha()
          return (
            <ProtectByProfile rule={userType => userType === 'Comprador'} fallback={() => <NotFound />}>
              <ProtectByStep step={PROTECT_BY_STEP_DICTIONARY.STEP_TWO} fallback={() => <NotFound />}>
                <BrandLayout featured={<DirectDealPageHeader {...directDealheading} />}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <DirectDealStepTwoContainer />
                    </Grid>
                  </Grid>
                </BrandLayout>
              </ProtectByStep>
            </ProtectByProfile>
          )
        }}
      </AuthorizationContext.Consumer>
    </AuthorizationProviderContainer>
  )
}

export default DirectDealStepTwo
