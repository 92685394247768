import React from 'react'
import { styled, Divider as DividerBase, Grid as BaseGrid } from '@mui/material'
import { TableCell as TableCellBase, TableRow as TableRowBase } from '@chilecompra/react-kit/components'

export const Root = styled('div')`
  width: 100%;
`
export const Divider = styled(DividerBase)`
  width: 100%;
`
export const Grid = styled(({ ...props }) => <BaseGrid {...props} />)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;
  text-align: ${props => props.textAlign};
  align-items: ${props => props.alignItems};
`
export const TableRow = styled(({ ...props }) => <TableRowBase {...props} />)``

export const TableCell = styled(({ full, ...props }) => <TableCellBase {...props} />)`
  border: 1px solid ${props => props.theme.palette.gray[85]} !important;
  background-color: ${props => props.full && props.theme.palette.blue[99]};
`

export const HeaderVoucher = styled('div')`
  border: 1px solid ${props => props.theme.palette.gray[85]};
  background-color: ${props => props.theme.palette.blue[99]};
  padding: 16px;
`
export const CellVoucher = styled('div')`
  border: 1px solid ${props => props.theme.palette.gray[85]};
  padding: 16px;
`

export const Strong = styled('strong')`
  font-size: 16px;
`
