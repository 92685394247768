import {
  CLOSE_ABILITY_DIRECT_DEAL_STEP_TWO,
  CLOSE_CONFIRM_STEP_TWO_DIALOG,
  CLOSE_DETAIL_QUOTE_DIRECT_DEAL_STEP_TWO,
  CLOSE_QUOTE_DIRECT_DEAL_STEP_TWO,
  CLOSE_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO,
  GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_ERROR,
  GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_SUCCESS,
  GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO,
  GET_CLOSING_DATE_ERROR,
  GET_CLOSING_DATE_SUCCESS,
  GET_CLOSING_DATE,
  GET_CONTRACT_DURATION_ERROR,
  GET_CONTRACT_DURATION_SUCCESS,
  GET_CONTRACT_DURATION,
  GET_CONTRACT_TYPE_ERROR,
  GET_CONTRACT_TYPE_SUCCESS,
  GET_CONTRACT_TYPE,
  GET_CURRENCY_ERROR,
  GET_CURRENCY_SUCCESS,
  GET_CURRENCY,
  GET_INFO_CITIES_ERROR,
  GET_INFO_CITIES_SUCCESS,
  GET_INFO_CITIES,
  GET_INFO_PROVIDER_ERROR,
  GET_INFO_PROVIDER_SUCCESS,
  GET_INFO_PROVIDER,
  GET_INFO_REGIONS_ERROR,
  GET_INFO_REGIONS_SUCCESS,
  GET_INFO_REGIONS,
  GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO_ERROR,
  GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO_SUCCESS,
  GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO,
  GET_TAXES_ERROR,
  GET_TAXES_SUCCESS,
  GET_TAXES,
  GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_ERROR,
  GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_SUCCESS,
  GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO,
  OPEN_ABILITY_DIRECT_DEAL_STEP_TWO,
  OPEN_CONFIRM_STEP_TWO_DIALOG,
  OPEN_DETAIL_QUOTE_DIRECT_DEAL_STEP_TWO,
  OPEN_QUOTE_DIRECT_DEAL_STEP_TWO,
  OPEN_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO,
  PUBLISH_DIRECT_DEAL_ERROR,
  PUBLISH_DIRECT_DEAL_SUCCESS,
  PUBLISH_DIRECT_DEAL,
  REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO_ERROR,
  REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO_SUCCESS,
  REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO,
  RESET_DIRECT_DEAL_STEP_TWO,
  SAVE_DIRECT_DEAL_ERROR,
  SAVE_DIRECT_DEAL_SUCCESS,
  SAVE_DIRECT_DEAL,
  SAVE_QUOTE_DIRECT_DEAL_STEP_TWO,
  SAVED_INFO_STEP_ONE_DIRECT_DEAL_STEP_TWO,
  STORAGE_DIRECT_DEAL_STEP_TWO,
  UPDATE_QUOTE_DIRECT_DEAL_STEP_TWO
} from './DirectDealStepTwo.actions'

const directDealStepTwoReducerState = {
  error: undefined,
  loading: false,
  contractDuration: [],
  contractType: null,
  currency: [],
  currencies: [],
  cities: [],
  regions: [],
  provider: null,
  taxes: [],
  closingDate: null,
  dialog: { openConfirmation: false, openSaved: false, openAbility: false, openQuote: false, openQuoteDetail: false },
  sheet: undefined,
  unitMeasurement: [],
  sheetStorage: undefined,
  stepOne: undefined,
  quotes: []
}

/**
 * The DirectDealStepTwoReducer reducer.
 */
const directDealStepTwoReducer = (state = directDealStepTwoReducerState, { payload, type }) => {
  switch (type) {
    case GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO:
    case GET_CLOSING_DATE:
    case GET_CONTRACT_DURATION:
    case GET_CONTRACT_TYPE:
    case GET_CURRENCY:
    case GET_INFO_CITIES:
    case GET_INFO_PROVIDER:
    case GET_INFO_REGIONS:
    case GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO:
    case GET_TAXES:
    case GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO:
    case PUBLISH_DIRECT_DEAL:
    case REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO:
    case SAVE_DIRECT_DEAL: {
      return { ...state, loading: true }
    }

    case GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_ERROR:
    case GET_CLOSING_DATE_ERROR:
    case GET_CONTRACT_DURATION_ERROR:
    case GET_CONTRACT_TYPE_ERROR:
    case GET_CURRENCY_ERROR:
    case GET_INFO_CITIES_ERROR:
    case GET_INFO_PROVIDER_ERROR:
    case GET_INFO_REGIONS_ERROR:
    case GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO_ERROR:
    case GET_TAXES_ERROR:
    case GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_ERROR:
    case PUBLISH_DIRECT_DEAL_ERROR:
    case REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO_ERROR:
    case SAVE_DIRECT_DEAL_ERROR: {
      return { ...state, error: payload.error, loading: false }
    }
    case GET_CLOSING_DATE_SUCCESS: {
      return { ...state, error: undefined, loading: false, closingDate: payload }
    }
    case GET_CONTRACT_DURATION_SUCCESS: {
      return { ...state, error: undefined, loading: false, contractDuration: [...payload.contractDuration] }
    }
    case GET_CONTRACT_TYPE_SUCCESS: {
      return { ...state, error: undefined, loading: false, contractType: { ...payload.contractType } }
    }
    case GET_CURRENCY_SUCCESS: {
      return { ...state, error: undefined, loading: false, currency: payload.currency }
    }
    case GET_INFO_PROVIDER_SUCCESS: {
      return { ...state, error: undefined, loading: false, provider: payload.provider }
    }
    case GET_INFO_REGIONS_SUCCESS: {
      return { ...state, loading: false, regions: payload.regions }
    }
    case GET_INFO_CITIES_SUCCESS: {
      return { ...state, loading: false, cities: payload.cities }
    }
    case GET_TAXES_SUCCESS: {
      return { ...state, error: undefined, loading: false, taxes: payload.taxes }
    }
    case SAVE_DIRECT_DEAL_SUCCESS: {
      return { ...state, error: undefined, loading: false }
    }
    case PUBLISH_DIRECT_DEAL_SUCCESS: {
      return { ...state, error: undefined, loading: false }
    }
    case OPEN_CONFIRM_STEP_TWO_DIALOG: {
      return { ...state, dialog: { ...state.dialog, openConfirmation: true } }
    }
    case CLOSE_CONFIRM_STEP_TWO_DIALOG: {
      return { ...state, dialog: { ...state.dialog, openConfirmation: false } }
    }
    case GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO_SUCCESS: {
      return { ...state, sheet: payload.sheet }
    }
    case GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_SUCCESS: {
      return { ...state, unitMeasurement: payload.unitMeasurement, loading: false }
    }
    case STORAGE_DIRECT_DEAL_STEP_TWO: {
      return { ...state, sheetStorage: { ...payload.sheet } }
    }
    case OPEN_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO: {
      return { ...state, dialog: { ...state.dialog, openSaved: true } }
    }
    case CLOSE_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO: {
      return { ...state, dialog: { ...state.dialog, openSaved: false } }
    }
    case GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_SUCCESS: {
      return { ...state, currencies: payload.currencies, loading: false }
    }
    case OPEN_ABILITY_DIRECT_DEAL_STEP_TWO: {
      return { ...state, dialog: { ...state.dialog, openAbility: true } }
    }
    case CLOSE_ABILITY_DIRECT_DEAL_STEP_TWO: {
      return { ...state, dialog: { ...state.dialog, openAbility: false } }
    }
    case SAVED_INFO_STEP_ONE_DIRECT_DEAL_STEP_TWO: {
      return { ...state, stepOne: payload.sheet }
    }
    case SAVE_QUOTE_DIRECT_DEAL_STEP_TWO: {
      return {
        ...state,
        quotes: [...state.quotes, payload]
      }
    }
    case UPDATE_QUOTE_DIRECT_DEAL_STEP_TWO: {
      return {
        ...state,
        quotes: state.quotes.map(quote =>
          quote.idQuote === payload.idQuote
            ? {
                ...quote,
                contributorDni: payload.contributorDni,
                contributorLegalName: payload.contributorLegalName,
                contributorPhone: payload.contributorPhone,
                contributorEmail: payload.contributorEmail
              }
            : quote
        )
      }
    }
    case REMOVE_QUOTE_DIRECT_DEAL_STEP_TWO_SUCCESS: {
      return {
        ...state,
        loading: false,
        quotes: state.quotes.filter(quote => quote.idQuote !== payload.idQuote)
      }
    }
    case RESET_DIRECT_DEAL_STEP_TWO: {
      return { ...state, provider: null, sheet: null, sheetStorage: null, quotes: [] }
    }
    case OPEN_QUOTE_DIRECT_DEAL_STEP_TWO: {
      return { ...state, dialog: { ...state.dialog, openQuote: true } }
    }
    case CLOSE_QUOTE_DIRECT_DEAL_STEP_TWO: {
      return { ...state, dialog: { ...state.dialog, openQuote: false } }
    }
    case OPEN_DETAIL_QUOTE_DIRECT_DEAL_STEP_TWO: {
      return { ...state, dialog: { ...state.dialog, openQuoteDetail: true } }
    }
    case CLOSE_DETAIL_QUOTE_DIRECT_DEAL_STEP_TWO: {
      return { ...state, dialog: { ...state.dialog, openQuoteDetail: false } }
    }
    default: {
      return state
    }
  }
}

export default directDealStepTwoReducer
