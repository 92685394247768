import { factoryService, getJsonRequest } from '@chilecompra/react-kit'
import { REACT_APP_API_BASE_URL } from '../config/settings/environment'

/**
 * Gets the products list
 */
export const getProducts = factoryService(({ name }) =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/catalogo/productos?query=${name}`)
)

/**
 * Gets a list of categories of the goods and services.
 */
export const getCategories = factoryService(() =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/catalogo/categorias/clasificadores`)
)

/**
 * Gets a list of categories of level two of the goods and services.
 */
export const getCategoriesLevelTwo = factoryService(({ category }) =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/catalogo/categorias/clasificadores/${category}`)
)

/**
 * Gets a list of goods and services by category.
 */
export const getGoodsAndServicesByCategory = factoryService(({ category }) =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/catalogo/categorias/${category}/productos`)
)
