import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { getCookie, useEffectOnce } from '@chilecompra/react-kit'
import { Spinner } from '@chilecompra/react-kit/components'

import { onGetAuthInfoThunk, onGetPublicTokenThunk, onGetTokenThunk } from './AuthProvider.actions'
import AuthorizationContext from './AuthProvider.context'
import { Root } from './AuthProvider.styles'

import { REACT_APP_ACCESS_TOKEN_MP_NAME } from '../../config/settings/environment'
import { useLocation } from 'react-router-dom'
import routes from '../../config/settings/routes'

/**
 * The Authorization Provider container.
 */
const AuthorizationProvider = ({ children }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { authInfo, accessToken, error, loading } = useSelector(state => state.authorizationProvider)

  const accessTokenCookie = getCookie(REACT_APP_ACCESS_TOKEN_MP_NAME)
  const isModePublic = pathname === routes.searchPublic
  const { userType, isBuyer, isSeller, userDni } = authInfo

  const isPrivated = useMemo(() => {
    return userType === '' ? undefined : userType === 'Comprador' || userType === 'Proveedor'
  }, [userType])

  useEffectOnce(() => {
    if (accessToken) {
      dispatch(onGetAuthInfoThunk())
      return
    }

    if ((!accessToken && !accessTokenCookie) || isModePublic) {
      dispatch(onGetPublicTokenThunk())
    } else {
      dispatch(onGetTokenThunk())
    }
  })

  if (!accessToken || error || loading) {
    return (
      <Root>
        <Spinner />
      </Root>
    )
  }

  return (
    <AuthorizationContext.Provider value={{ accessToken, isBuyer, isSeller, isPrivated, userDni, userType }}>
      {children}
    </AuthorizationContext.Provider>
  )
}

AuthorizationProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthorizationProvider
