import {
  GET_CATEGORIES,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_LEVEL_TWO,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_LEVEL_TWO_ERROR,
  GET_CATEGORIES_LEVEL_TWO_SUCCESS,
  GET_GOODS_AND_SERVICES_BY_CATEGORY,
  GET_GOODS_AND_SERVICES_BY_CATEGORY_ERROR,
  GET_GOODS_AND_SERVICES_BY_CATEGORY_SUCCESS
} from './ProductCategorySearch.actions'

const productCategorySearchState = {
  categories: [],
  categoriesLevelTwo: [],
  error: false,
  gettingCatergories: false,
  gettingCatergoriesLevelTwo: false,
  gettingGoodsAndServices: false,
  goodsAndServices: []
}

/**
 * The searchGoodsAndServicesByCategoryReducer's reducer.
 */
const productCategorySearchReducer = (state = productCategorySearchState, { payload, type }) => {
  switch (type) {
    case GET_CATEGORIES: {
      return { ...state, error: false, gettingCatergories: true }
    }
    case GET_CATEGORIES_ERROR: {
      return { ...state, error: true, gettingCatergories: false }
    }
    case GET_CATEGORIES_SUCCESS: {
      return { ...state, categories: payload.categories, error: false, gettingCatergories: false }
    }
    case GET_CATEGORIES_LEVEL_TWO: {
      return { ...state, error: false, gettingCatergoriesLevelTwo: true, goodsAndServices: [] }
    }
    case GET_CATEGORIES_LEVEL_TWO_ERROR: {
      return { ...state, error: true, gettingCatergoriesLevelTwo: false }
    }
    case GET_CATEGORIES_LEVEL_TWO_SUCCESS: {
      return {
        ...state,
        categoriesLevelTwo: payload.categoriesLevelTwo,
        error: false,
        gettingCatergoriesLevelTwo: false
      }
    }
    case GET_GOODS_AND_SERVICES_BY_CATEGORY: {
      return { ...state, error: false, gettingGoodsAndServices: true }
    }
    case GET_GOODS_AND_SERVICES_BY_CATEGORY_ERROR: {
      return { ...state, error: true, gettingGoodsAndServices: false }
    }
    case GET_GOODS_AND_SERVICES_BY_CATEGORY_SUCCESS: {
      return { ...state, error: false, goodsAndServices: payload.goodsAndServices, gettingGoodsAndServices: false }
    }
    default: {
      return state
    }
  }
}

export default productCategorySearchReducer
