const routes = {
  directDealStepZero: '/trato-directo-paso-cero',
  directDealStepOne: '/trato-directo-paso-uno',
  directDealStepTwo: '/trato-directo-paso-dos',
  directDealSummary: '/resumen-ficha/:directDealId',
  directDealVoucher: '/trato-directo-voucher',
  directDealQuoteCreate: '/crear-cotizacion',
  directDealQuoteEdit: '/editar-cotizacion',
  productSearch: '/buscador-productos-y-servicios',
  root: '/',
  search: '/buscador',
  searchPublic: '/buscador-publico'
}

export default routes
