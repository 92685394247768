import React from 'react'
import { createPortal } from 'react-dom'
import {
  REACT_APP_MERCADO_PUBLICO_BASE_URL,
  REACT_APP_CHILECOMPRA_BASE_URL,
  REACT_APP_CAPACITACION_BASE_URL,
  REACT_APP_CENTROAYUDA_BASE_URL
} from '../../config/settings/environment'
import { Container, ExternalLink, Item, List, RootNav } from './HeaderPublic.menu.styles'

/**
 * The Header public menu' container.
 */
const HeaderPublicMenu = () => {
  const element = document.getElementById('__menu')

  if (!element) {
    return null
  }

  return createPortal(
    <RootNav>
      <Container container>
        <List>
          <Item>
            <ExternalLink href={REACT_APP_CHILECOMPRA_BASE_URL} target="_top">
              ChileCompra
            </ExternalLink>
          </Item>
          <Item>
            <ExternalLink href={`${REACT_APP_MERCADO_PUBLICO_BASE_URL}/Home`} target="_top" selected>
              Mercado Público
            </ExternalLink>
          </Item>
          <Item>
            <ExternalLink href={REACT_APP_CAPACITACION_BASE_URL} target="_top">
              Capacitación
            </ExternalLink>
          </Item>
          <Item>
            <ExternalLink href={REACT_APP_CENTROAYUDA_BASE_URL} target="_top">
              Centro de Ayuda
            </ExternalLink>
          </Item>
        </List>
      </Container>
    </RootNav>,
    element
  )
}

export default HeaderPublicMenu
