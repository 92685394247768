import React from 'react'
import PropTypes from 'prop-types'

import { Card, Skeleton, Typography } from '@chilecompra/react-kit/components'
import { useViewport } from '@chilecompra/react-kit'

import FormattedDateComponent from '../../components/components/FormattedDate.component'
import FormattedCurrencyComponent from '../../components/components/FormattedCurrency.component'
import AttachmentsWrapper from '../../components/components/AttachmentsWrapper.component'

import { CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL } from '../../config/settings/constants'

import { Divider, Grid, Link } from './DirectDealSummary.styles'

/**
 * The DirectDealSummary's details.
 */
const DirectDealSummaryDetail = props => {
  const { loading, sheet, onRedirect } = props
  const { size } = useViewport()

  return (
    <Grid container spacing={3} margin="0">
      <Grid item md={8} xs={12}>
        <Card enableBorder lineColor="primary" color="blank" padding="32px 24px 0">
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Nombre
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" fontSize="16px" lineHeight="18px" variant="body2">
                  {sheet?.name}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Descripción
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" fontSize="16px" lineHeight="18px" variant="body2">
                  {sheet?.description}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Causal de Trato Directo
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" fontSize="16px" lineHeight="18px" variant="body2">
                  {sheet?.cause}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Justificación del Trato Directo
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" fontSize="16px" lineHeight="18px" variant="body2">
                  {sheet?.justification}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Fecha de publicación
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" fontSize="16px" lineHeight="18px" variant="body2">
                  <FormattedDateComponent date={sheet?.datePublish} includeHours />
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Fecha de cierre
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" fontSize="16px" lineHeight="18px" variant="body2">
                  <FormattedDateComponent date={sheet?.dateClosed} includeHours />
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Monto total
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" fontSize="16px" lineHeight="18px" variant="body2">
                  <FormattedCurrencyComponent amount={sheet?.totalAmount || 0} currency="CLP" includeSymbol />
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Moneda
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" fontSize="16px" lineHeight="18px" variant="body2">
                  {sheet?.currency}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Proveedor
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <>
                  <Link onClick={onRedirect}>{sheet?.providerName}</Link>
                  <Typography color="black3" fontSize="14px">
                    {sheet?.providerDni}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Duración del contrato
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" fontSize="16px" lineHeight="18px" variant="body2">
                  {sheet?.contractDuration}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Fecha de término de ejecución
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" fontSize="16px" lineHeight="18px" variant="body2">
                  <FormattedDateComponent date={sheet?.contractDateFinish} />
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Categoría del contrato
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" fontSize="16px" lineHeight="18px" variant="body2">
                  {sheet?.contractCategory}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Tipo de contrato
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" fontSize="16px" lineHeight="18px" variant="body2">
                  {sheet?.contractType}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Dirección de entrega o ejecución del servicio
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" fontSize="16px" lineHeight="18px" variant="body2">
                  {sheet?.deliveryAddress}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 24px'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontSize="14px" fontWeight="bold" lineHeight="18px" variant="body2">
                Archivos adjuntos
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {!loading && sheet?.subBusinessAttachment && sheet?.idBusinessAttachment && (
                <AttachmentsWrapper
                  business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
                  subBusiness={sheet.subBusinessAttachment}
                  idBusiness={sheet.idBusinessAttachment}
                  isPrivate
                  canRead
                  color="blank"
                  showIconList={false}
                  typeView="list"
                />
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item md xs={12}>
        <Card padding="24px 16px">
          <Grid container spacing={1}>
            <Grid item>
              <Typography fontWeight="bold" fontSize="14px" color="black1" lineHeight="18px">
                {sheet?.buyerName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontSize="14px" color="black1" lineHeight="18px">
                {sheet?.buyerDni}
              </Typography>
              <Typography fontSize="14px" lineHeight="18px" color="black3">
                {sheet?.buyerLegalName}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

DirectDealSummaryDetail.propTypes = {
  sheet: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    cause: PropTypes.string,
    justification: PropTypes.string,
    datePublish: PropTypes.string,
    dateClosed: PropTypes.string,
    totalAmount: PropTypes.number,
    currency: PropTypes.string,
    providerName: PropTypes.string,
    providerDni: PropTypes.string,
    contractDuration: PropTypes.string,
    contractDateFinish: PropTypes.string,
    contractCategory: PropTypes.string,
    contractType: PropTypes.string,
    deliveryAddress: PropTypes.string,
    buyerName: PropTypes.string,
    buyerDni: PropTypes.string,
    buyerLegalName: PropTypes.string,
    subBusinessAttachment: PropTypes.string,
    idBusinessAttachment: PropTypes.string
  }),
  loading: PropTypes.bool,
  onRedirect: PropTypes.func
}

export default DirectDealSummaryDetail
