import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getCookie } from '@chilecompra/react-kit'

import BrandLayout from '../../components/Layouts/BrandLayout'
import routes from '../../config/settings/routes'
import { initializeReCaptcha } from '../../modules/hooks/reCaptcha'

import DirectDealPageHeader from '../../containers/DirectDealPageHeader/DirectDealPageHeader.container'
import DirectDealStepOneContainer from '../../containers/DirectDealStepOne/DirectDealStepOne.container'
import AuthorizationProviderContainer from '../../containers/AuthProvider/AuthProvider.container'
import AuthorizationContext from '../../containers/AuthProvider/AuthProvider.context'

import ProtectByProfile from '../../components/Security/ProtectByProfile'
import NotFound from '../NotFound/NotFound'

import { Grid } from './DirectDealStepOne.styles'

/**
 * The DirectDealStepOne's page.
 */
const DirectDealStepOne = () => {
  const navigate = useNavigate()

  const directDealheading = {
    breadcrumbs: [
      {
        text: 'Escritorio',
        onRedirectTo: () => {
          const query = getCookie('search')
          navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
        }
      },
      {
        text: 'Trato Directo',
        onRedirectTo: () => {
          const query = getCookie('search')
          navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
        }
      },
      {
        text: 'Crear Trato Directo'
      }
    ],
    step: 'PASO 1 DE 2',
    title: 'Crear nuevo Trato Directo',
    description: `Ingresa toda la información del Trato Directo que deseas realizar, la causal, justificación, productos o servicios, entre otros. 
    Al finalizar podrás continuar con la emisión de la orden de compra.`
  }

  return (
    <AuthorizationProviderContainer>
      <AuthorizationContext.Consumer>
        {({ isPrivated }) => {
          if (isPrivated) initializeReCaptcha()
          return (
            <ProtectByProfile rule={userType => userType === 'Comprador'} fallback={() => <NotFound />}>
              <BrandLayout featured={<DirectDealPageHeader {...directDealheading} />}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <DirectDealStepOneContainer />
                  </Grid>
                </Grid>
              </BrandLayout>
            </ProtectByProfile>
          )
        }}
      </AuthorizationContext.Consumer>
    </AuthorizationProviderContainer>
  )
}

export default DirectDealStepOne
