import React from 'react'
import PropTypes from 'prop-types'

import { GridContainer, GridItem, IconArrowForward, Link, Card, SeparatorDiv, Typography } from './SearchResults.styles'

import FormattedCurrencyComponent from '../../components/components/FormattedCurrency.component'

/**
 * The SearchResultsDesktop's component.
 */
const SearchResultsDesktop = props => {
  const { directDealSheets, onLineColors, onRedirect } = props
  const handleRedirect = idDocument => {
    return () => onRedirect(idDocument)
  }

  return (
    <>
      {directDealSheets.map((sheet, index) => (
        <Card
          elevation="1"
          enableBorder
          enableLine
          lineColor={onLineColors(sheet.state)}
          color="blank"
          padding="0 16px"
          margin="0 0 16px"
          key={index}
          height="auto" // Cambiado para que el alto se ajuste al contenido
        >
          <GridContainer alignItems="center" padding="0 0 0 16px">
            <GridItem xs={12} md={4}>
              <Typography variant="body2" fontWeight="bold" padding="16px 0 0" color={onLineColors(sheet.state)}>
                {sheet.state}
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer alignItems="center" spacing={2}>
            <GridItem xs={12} md={3}>
              <Typography variant="body2" fontSize="14px" fontWeight="regular" padding="16px" color="black1">
                {sheet.idDocument}
              </Typography>
              <Typography
                variant="body1"
                fontSize="14px"
                fontWeight="bold"
                padding="0 16px"
                color="black1"
                textAlign="start"
              >
                {sheet.name}
              </Typography>
            </GridItem>
            <GridItem xs={6} md={2}>
              <Typography
                variant="body2"
                fontWeight="regular"
                padding="16px 0"
                color="black3"
                textAlign={{ xs: 'left', md: 'end' }}
              >
                Publicado el
              </Typography>
              <Typography
                variant="body2"
                fontWeight="regular"
                fontSize="14px"
                padding="0px 0"
                color="black1"
                textAlign="end"
              >
                {sheet.creationDate}
              </Typography>
            </GridItem>
            <GridItem xs={6} md={4}>
              <Typography
                variant="body2"
                fontWeight="regular"
                padding="16px 0 0"
                color="black3"
                textAlign={{ xs: 'left', md: 'end' }}
              >
                Proveedor
              </Typography>
              <Typography
                variant="body2"
                fontWeight="regular"
                fontSize="14px"
                padding="8px 0"
                color="black1"
                textAlign="end"
              >
                {sheet.provider}
              </Typography>
            </GridItem>
            <GridItem xs={12} md={3}>
              <Typography variant="body2" fontWeight="regular" padding="16px 0" color="black3" textAlign="end">
                Monto Total
              </Typography>
              <Typography variant="h4" fontWeight="bold" padding="8px 0 0" margin="0" color="black1" textAlign="end">
                <FormattedCurrencyComponent amount={sheet.totalAmount || 0} currency="CLP" includeSymbol />
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <SeparatorDiv />
          </GridContainer>
          <GridContainer alignItems="center">
            <GridItem xs={12} md={10}>
              <Typography
                variant="body2"
                fontWeight="regular"
                padding="16px"
                color="black3"
                fontStyle="italic !important"
              >
                Causal: {sheet.cause}
              </Typography>
            </GridItem>
            <GridItem xs={12} md={2} textAlign="right">
              <Link onClick={handleRedirect(sheet.idDocument)}>Revisar detalle</Link>
              <IconArrowForward fontSize="16px" />
            </GridItem>
          </GridContainer>
        </Card>
      ))}
    </>
  )
}

SearchResultsDesktop.propTypes = {
  directDealSheets: PropTypes.arrayOf(
    PropTypes.shape({
      cause: PropTypes.string,
      creationDate: PropTypes.string,
      idDocument: PropTypes.string,
      name: PropTypes.string,
      provider: PropTypes.string,
      state: PropTypes.string,
      totalAmount: PropTypes.number
    })
  ),
  onLineColors: PropTypes.func,
  onRedirect: PropTypes.func
}

export default SearchResultsDesktop
