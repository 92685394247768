import { useEffect, useRef, useState } from 'react'

/**
 * Custom hook to force updates from partial form sections.
 */
export const useFormRef = () => {
  const [, setTick] = useState(0)
  const ref = useRef({ update: () => setTick(currentTick => currentTick + 1) })

  return ref
}

/**
 * Custom hook to handle partial form updates.
 */
export const useImperativeFormRef = (ref, callback, dependencies) => {
  useEffect(() => {
    ref.current = { ...ref.current, ...callback() }

    ref.current.update()
  }, dependencies)
}

/**
 * Custom hook to manage partial form updates and integrating the functionality of cleaning or restarting referenced inputs
 */
export const useImperativeFormRefWithReset = (ref, callback, dependencies) => {
  useEffect(() => {
    const callbackResult = callback()
    ref.current = {
      ...ref.current,
      ...callbackResult,
      resetField: fieldName => {
        if (callbackResult[fieldName] !== undefined) {
          callbackResult[fieldName].onChange('')
        }
      },
      cleanErrorField: fieldName => {
        if (callbackResult[fieldName] !== undefined) {
          callbackResult[fieldName].onError('')
        }
      }
    }

    ref.current.update()
  }, dependencies)
}
