import { REACT_APP_API_PRINCIPAL_URL } from '../config/settings/environment'
import { factoryService, getJsonRequest } from '@chilecompra/react-kit/requests'
/**
 * Gets a valid public token.
 */
export const getPublicToken = factoryService(() => {
  return getJsonRequest(`${REACT_APP_API_PRINCIPAL_URL}/v1/auth/publico`, { isPublic: true })
})

/**
 * Gets the user info contained in the token.
 */
export const getAuthInfo = factoryService(() => {
  return getJsonRequest(`${REACT_APP_API_PRINCIPAL_URL}/v1/auth/info`)
})
