import { factoryService, getFileRequest, getJsonRequest, postJsonRequest } from '@chilecompra/react-kit/requests'
import { REACT_APP_API_BASE_URL } from '../config/settings/environment'

/**
 * Gets a excel file to download with the quote search results
 */
export const getDownloadDirectDealSearch = factoryService(({ queryString }) =>
  getFileRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/reportes/excel?${queryString}`)
)

/**
 * Gets the DIRECT DEAL search
 */
export const getDirectDealSearch = factoryService(({ queryString }) =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/buscar?${queryString}`)
)

/**
 * POST: Create a direct deal sheet
 */
export const postDirectDealCreate = factoryService(({ sheet, initialSheet }) => {
  const body = {
    causal: sheet?.cause?.value,
    esMayor1000UTM: sheet?.amount,
    requirente: {
      rut: sheet?.dni,
      nombre: sheet?.name,
      apellido: sheet?.lastName,
      cargo: sheet?.job,
      correo: sheet?.email
    },
    firmante: {
      id: '',
      resolucion: initialSheet?.resolutionNumberValueInput,
      fechaResolucion: initialSheet?.resolutionDate,
      nombres: initialSheet?.names,
      apellidos: initialSheet?.lastNames,
      rut: initialSheet?.dni,
      cargo: initialSheet?.job
    }
  }
  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/crear`, { body })
})
/**
 * Gets the descriptions of the direct deal causes
 */
export const getDetailDirectDealSheet = factoryService(directDeal => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/detalle?idFicha=${directDeal.id}`)
})
/**
 * POST: to apply the decision of a direct deal sheet
 */
export const postApplyDecisionDirectDealSheet = factoryService(({ request }) => {
  const body = {
    idTratoDirecto: request.sheetId,
    mecanismoCompra: request.mechanism,
    decision: request.decision,
    descripcion: request.description
  }

  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/decision`, { body })
})
