import {
  DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS,
  DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS_ERROR,
  DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS_SUCCESS
} from './DirectDealSearchDownload.actions'

const directDealSearchDownloadState = {
  error: undefined,
  loading: false
}

const directDealSearchDownloadReducer = (state = directDealSearchDownloadState, { payload, type }) => {
  switch (type) {
    case DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS: {
      return { error: undefined, loading: true }
    }
    case DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS_ERROR: {
      return { error: payload.error, loading: false }
    }
    case DOWNLOAD_DIRECT_DEAL_SEARCH_RESULTS_SUCCESS: {
      return { error: undefined, loading: false }
    }
    default: {
      return state
    }
  }
}

export default directDealSearchDownloadReducer
