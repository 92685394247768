import { styled } from '@mui/material'
export const SearchGridLayout = styled('div')`
  display: grid;
  gap: 16px;
  grid-template-areas:
    'new-directdeal-box'
    'heading-filters'
    'resume'
    'filters'
    'results';
  grid-template-columns: 1fr;
  width: 100%;

  ${props => props.theme.breakpoints.up('sm')} {
    ${props => {
      if (props.isBuyer || (!props.isBuyer && !props.isSeller))
        return `
        grid-template-areas:
          'heading-filters heading-filters new-directdeal-box'
          'resume resume resume'
          'filters filters filters'
          'results results results';
        grid-template-columns: 250px 1fr 239px;
      `
      if (props.isSeller)
        return `
        grid-template-areas:
          'heading-filters heading-filters'
          'resume resume'
          'filters filters'
          'results results';
        grid-template-columns: 250px 1fr;
      `
    }}
  }

  ${props => props.theme.breakpoints.up('md')} {
    ${props => {
      if (props.isBuyer || (!props.isBuyer && !props.isSeller))
        return `
        grid-template-areas:
          'heading-filters heading-filters new-directdeal-box'
          'resume resume resume'
          'filters results results';
        grid-template-columns: 250px 1fr 239px;
      `
      if (props.isSeller)
        return `
        grid-template-areas:
          'heading-filters heading-filters heading-filters'
          'resume resume resume'
          'filters results results';
        grid-template-columns: 250px 1fr 239px;
      `
      else
        return `
        grid-template-areas:
          'heading-filters heading-filters new-directdeal-box'
          'resume resume resume'
          'filters results results';
        grid-template-columns: 250px 1fr 239px;`
    }}
  }
`
