import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getCookie, useEffectOnce } from '@chilecompra/react-kit'

import BrandLayout from '../../components/Layouts/BrandLayout'
import routes from '../../config/settings/routes'
import { initializeReCaptcha } from '../../modules/hooks/reCaptcha'

import DirectDealPageHeader from '../../containers/DirectDealPageHeader/DirectDealPageHeader.container'
import AuthorizationProviderContainer from '../../containers/AuthProvider/AuthProvider.container'
import AuthorizationContext from '../../containers/AuthProvider/AuthProvider.context'

import ProtectByProfile from '../../components/Security/ProtectByProfile'
import NotFound from '../NotFound/NotFound'

import DirectDealQuoteEditor from '../../containers/DirectDealQuote/DirectDealQuote.container.edit'

import { Grid } from './DirectDealQuote.styles'

/**
 * The DirectDealQuote's edit page.
 */
const DirectDealQuoteEdit = () => {
  const navigate = useNavigate()
  const { idQuoteEdit, loading } = useSelector(state => state.directDealQuote)
  const { quotes } = useSelector(state => state.directDealStepTwo)

  const [quoteModelEdit, setQuoteModelEdit] = useState(null)

  const directDealheading = {
    breadcrumbs: [
      {
        text: 'Escritorio',
        onRedirectTo: () => {
          const query = getCookie('search')
          navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
        }
      },
      {
        text: 'Trato Directo',
        onRedirectTo: () => {
          const query = getCookie('search')
          navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
        }
      },
      {
        text: 'Crear Trato Directo'
      },
      {
        text: 'Cotización'
      }
    ],
    title: `Cotización Rut: ${quoteModelEdit?.contributorDni || '-'}`
  }

  useEffectOnce(() => {
    setQuoteModelEdit(quotes?.find(quote => quote.idQuote === idQuoteEdit))
  })

  return (
    <AuthorizationProviderContainer>
      <AuthorizationContext.Consumer>
        {({ isPrivated }) => {
          if (isPrivated) initializeReCaptcha()
          return (
            <ProtectByProfile rule={userType => userType === 'Comprador'} fallback={() => <NotFound />}>
              <BrandLayout featured={<DirectDealPageHeader {...directDealheading} />}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <DirectDealQuoteEditor loading={loading} quote={quoteModelEdit} />
                  </Grid>
                </Grid>
              </BrandLayout>
            </ProtectByProfile>
          )
        }}
      </AuthorizationContext.Consumer>
    </AuthorizationProviderContainer>
  )
}

export default DirectDealQuoteEdit
