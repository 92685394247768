import { DIRECT_DEAL_FILTER_DEFAULT_ROWS } from '../../config/settings/constants'
import { makeActionCreator } from '../../config/store/utils'
import { getCauses, getStatus } from '../../services/causes'
import { getDirectDealSearch } from '../../services/directdeal'

export const GET_DIRECT_DEAL_SEARCH = 'GET_DIRECT_DEAL_SEARCH'
export const GET_DIRECT_DEAL_SEARCH_ERROR = 'GET_DIRECT_DEAL_SEARCH_ERROR'
export const GET_DIRECT_DEAL_SEARCH_SUCCESS = 'GET_DIRECT_DEAL_SEARCH_SUCCESS'

export const onGetDirectDealSearch = makeActionCreator(GET_DIRECT_DEAL_SEARCH)
export const onGetDirectDealSearchError = makeActionCreator(GET_DIRECT_DEAL_SEARCH_ERROR, 'payload')
export const onGetDirectDealSearchSuccess = makeActionCreator(GET_DIRECT_DEAL_SEARCH_SUCCESS, 'payload')

export const onGetDirectDealThunk = payload => async dispatch => {
  dispatch(onGetDirectDealSearch())

  try {
    const filters = Object.fromEntries(payload.query.entries())
    const queryString = new URLSearchParams({
      ...filters,
      size: DIRECT_DEAL_FILTER_DEFAULT_ROWS
    })
    const { data } = await getDirectDealSearch({ actionType: GET_DIRECT_DEAL_SEARCH, queryString })

    return dispatch(
      onGetDirectDealSearchSuccess({
        results:
          data?.payload?.content.map(item => ({
            state: item.estado,
            idDocument: item.idFicha,
            name: item.nombre,
            idPurchaseOrder: item.idOrdenCompra,
            currency: item.moneda,
            totalAmount: item.montoTotal,
            cause: item.causal,
            creationDate: item.fechaPublicacion,
            provider: item.nombreProveedor,
            buyer: item.nombreOrganismo
          })) || [],
        page: data?.payload?.pageNumber,
        pageCount: data?.payload?.totalPages || 0,
        pageSize: data?.payload?.pageSize || 0,
        resultCount: data?.payload?.totalElements || 0
      })
    )
  } catch (error) {
    return dispatch(
      onGetDirectDealSearchError({
        error: {
          code: error.code,
          message: error.message
        }
      })
    )
  }
}

export const GET_CAUSE = 'GET_CAUSE'
export const GET_CAUSE_ERROR = 'GET_CAUSE_ERROR'
export const GET_CAUSE_SUCCESS = 'GET_CAUSE_SUCCESS'
export const onGetCausesSearch = makeActionCreator(GET_CAUSE)
export const onGetCausesSearchError = makeActionCreator(GET_CAUSE_ERROR, 'payload')
export const onGetCausesSearchSuccess = makeActionCreator(GET_CAUSE_SUCCESS, 'payload')
export const onGetCausesSearchThunk = () => async dispatch => {
  dispatch(onGetCausesSearch())
  try {
    const { data } = await getCauses({ actionType: GET_CAUSE })
    return dispatch(
      onGetCausesSearchSuccess({
        causes: [
          { value: 'ALL', name: 'Todas las causales' },
          ...(data?.payload
            ?.filter(cause => cause?.descCausal !== 'Todos')
            ?.map(item => ({
              value: item.idCausal,
              name: item.descCausal
            })) || [])
        ]
      })
    )
  } catch (error) {
    return dispatch(
      onGetCausesSearchError({
        error
      })
    )
  }
}

export const GET_STATUS = 'GET_STATUS'
export const GET_STATUS_ERROR = 'GET_STATUS_ERROR'
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS'
export const onGetStatus = makeActionCreator(GET_CAUSE)
export const onGetStatusSearchError = makeActionCreator(GET_STATUS_ERROR, 'payload')
export const onGetStatusSearchSuccess = makeActionCreator(GET_STATUS_SUCCESS, 'payload')
export const onGetStatusSearchThunk = () => async dispatch => {
  dispatch(onGetStatus())
  try {
    const { data } = await getStatus({ actionType: GET_STATUS })
    return dispatch(
      onGetStatusSearchSuccess({
        state: [
          { value: 'ALL', name: 'Todos los estados' },
          ...(data?.payload
            ?.filter(state => state?.descEstado !== 'Todos')
            ?.map(item => ({
              value: item.idEstado,
              name: item.descEstado
            })) || [])
        ]
      })
    )
  } catch (error) {
    return dispatch(onGetStatusSearchError({ error }))
  }
}

export const SET_CAUSE_CRITERIA = 'SET_CAUSE_CRITERIA'
export const onSetCauseCriteria = makeActionCreator(SET_CAUSE_CRITERIA, 'payload')
