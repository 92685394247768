import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Input, Select, Typography } from '@chilecompra/react-kit/components'
import {
  checkInputErrorMaxLength,
  checkInputErrorRegEx,
  checkInputErrorRequired,
  useInput,
  useSelect
} from '@chilecompra/react-kit'

import { AmountPrefix, GridContainer, GridItem } from './DirectDealStepTwo.styles'

import { useImperativeFormRef } from '../../modules/hooks/useFormRef'
import { formatTypeCurrency } from '../../modules/utils/formatters'
import { getRegexByCurrency } from '../../modules/utils/checkers'

/**
 * DirectDealStepTwoTaxes's container
 */
const DirectDealStepTwoTaxes = props => {
  const { currency, formStepTwoRef, loading, storage, taxes } = props

  const {
    error: deliveryError,
    value: deliveryValue,
    onChange: deliveryValueOnChange,
    setValue: setDelivery
  } = useInput({
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorRegEx(getRegexByCurrency(currency?.type))],
    formatCallbacks: [value => formatTypeCurrency(currency?.type, value)],
    initialValue: storage?.delivery || '0'
  })

  const {
    error: discountError,
    value: discountValue,
    onChange: discountValueOnChange,
    setValue: setDiscount
  } = useInput({
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorRegEx(getRegexByCurrency(currency?.type))],
    formatCallbacks: [value => formatTypeCurrency(currency?.type, value)],
    initialValue: storage?.discount || '0'
  })

  const {
    error: observationDiscountError,
    value: observationDiscountValue,
    onChange: observationDiscountValueOnChange
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(100), checkInputErrorRequired()],
    initialValue: storage?.observationDiscount || ''
  })

  const {
    error: chargeError,
    value: chargeValue,
    onChange: chargeValueOnChange,
    setValue: setCharge
  } = useInput({
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorRegEx(getRegexByCurrency(currency?.type))],
    formatCallbacks: [value => formatTypeCurrency(currency?.type, value)],
    initialValue: storage?.chargeValue || '0'
  })

  const {
    error: observationChargeError,
    value: observationChargeValue,
    onChange: observationChargeValueOnChange
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(100), checkInputErrorRequired()],
    initialValue: storage?.observationCharge || ''
  })

  const {
    error: taxesError,
    value: taxesValue,
    onChange: handleTaxesChange
  } = useSelect({
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: storage?.tax || ''
  })

  useImperativeFormRef(
    formStepTwoRef,
    () => ({
      chargeError,
      chargeValue,
      deliveryError,
      deliveryValue,
      discountError,
      discountValue,
      observationChargeError,
      observationChargeValue,
      observationDiscountError,
      observationDiscountValue,
      taxesError,
      taxesValue
    }),
    [
      chargeError,
      chargeValue,
      deliveryError,
      deliveryValue,
      discountError,
      discountValue,
      observationChargeError,
      observationChargeValue,
      observationDiscountError,
      observationDiscountValue,
      taxesError,
      taxesValue
    ]
  )

  useEffect(() => {
    if (!storage?.coin || storage?.coin !== currency?.value) {
      setDelivery(0)
      setDiscount(0)
      setCharge(0)
    } else {
      setDelivery(storage?.delivery || '0')
      setDiscount(storage?.discount || '0')
      setCharge(storage?.chargeValue || '0')
    }
  }, [currency])

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2}>
        <Typography variant="body1" fontWeight="bold">
          Despacho, impuestos, descuentos y cargos
        </Typography>
        <Typography fontSize="12px" fontWeight="regular" lineHeight="16px" variant="body2">
          Aplican al total de los productos y servicios especificados.
        </Typography>
      </GridItem>
      <GridItem xs={12} md={6}>
        <GridContainer spacing={2}>
          <GridItem lg={6} md={6} sm={12} xd={12}>
            <Input
              error={deliveryError}
              label="Despacho"
              prefix={<AmountPrefix>{currency?.symbol}</AmountPrefix>}
              loading={loading}
              value={deliveryValue}
              onChange={deliveryValueOnChange}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xd={12} />
          <GridItem lg={6} md={6} sm={12} xd={12}>
            <Input
              error={discountError}
              label="Descuento"
              loading={loading}
              prefix={<AmountPrefix>{currency?.symbol}</AmountPrefix>}
              value={discountValue}
              onChange={discountValueOnChange}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xd={12}>
            <Input
              error={observationDiscountError}
              label="Observaciones"
              loading={loading}
              maxCount={100}
              value={observationDiscountValue}
              onChange={observationDiscountValueOnChange}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xd={12}>
            <Input
              error={chargeError}
              label="Cargos"
              loading={loading}
              prefix={<AmountPrefix>{currency?.symbol}</AmountPrefix>}
              value={chargeValue}
              onChange={chargeValueOnChange}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xd={12}>
            <Input
              error={observationChargeError}
              label="Observaciones"
              loading={loading}
              maxCount={100}
              value={observationChargeValue}
              onChange={observationChargeValueOnChange}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xd={12}>
            <Select
              error={taxesError}
              label="Impuestos"
              loading={loading}
              placeholder="Seleccione una opción"
              options={taxes}
              value={taxesValue}
              onChange={handleTaxesChange}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

DirectDealStepTwoTaxes.propTypes = {
  currency: PropTypes.shape({
    value: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    symbol: PropTypes.string
  }),
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  loading: PropTypes.bool,
  storage: PropTypes.shape({
    coin: PropTypes.string,
    delivery: PropTypes.string,
    discount: PropTypes.string,
    observationDiscount: PropTypes.string,
    chargeValue: PropTypes.string,
    observationCharge: PropTypes.string,
    tax: PropTypes.string
  }),
  taxes: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number, name: PropTypes.string }))
}

export default DirectDealStepTwoTaxes
