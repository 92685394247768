import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Card, Select, Skeleton, Typography, Radio } from '@chilecompra/react-kit/components'
import { checkInputErrorRequired, useSelect, useRadio, useViewport } from '@chilecompra/react-kit'
import FormattedCurrencyComponent from '../../components/components/FormattedCurrency.component'

import { Grid, RowRadio, Strong } from './DirectDealStepOne.styles'
import { useImperativeFormRef } from '../../modules/hooks/useFormRef'

import {
  BINARY_CONFIRMATION_RESPONSE,
  DIRECT_DEAL_TYPE_CAUSES_NEED_ADITIONAL_DOCUMENTS,
  DIRECT_DEAL_CAUSES_DESCRIPTION_DEFAULT,
  DIRECT_DEAL_MORE_DOCUMENTS_ARE_REQUIRED
} from '../../config/settings/constants'

/**
 * The DirectDealStepOne's basic background information.
 */
const DirectDealBasicBackground = ({ causes, loading, formRef, utm }) => {
  const [isSelectedCausePublicity, setCausePublicity] = useState(false)
  const [isSelectedCausePublicityConfidence, setCausePublicityConfidence] = useState(false)
  const [causeDescription, setCauseDescription] = useState(DIRECT_DEAL_CAUSES_DESCRIPTION_DEFAULT)
  const [isRequiringMoreDocuments, setRequiresMoreDocuments] = useState(false)
  const { size } = useViewport()

  const {
    error: causeError,
    value: causeValue,
    onChange: handleCauseChange
  } = useSelect({
    errorCallbacks: [checkInputErrorRequired()],
    changeCallback: updatedValue => {
      blockRadioDirectDealPublicity(updatedValue)
      verificationCauseNeedsMoreDocuments(updatedValue)
      changesCauseDescriptions(updatedValue)
    }
  })

  const {
    value: valueRadioAmount,
    onChange: handleRadioAmount,
    setValue: setRadioAmountValue
  } = useRadio({
    errorCallbacks: [checkInputErrorRequired()]
  })

  const itRequiresDocuments = id => {
    return DIRECT_DEAL_TYPE_CAUSES_NEED_ADITIONAL_DOCUMENTS.some(item => item.id === id)
  }
  const verificationCauseNeedsMoreDocuments = updatedValue => {
    setRequiresMoreDocuments(itRequiresDocuments(updatedValue))
  }

  const verificationCausePublicity = updatedValue => {
    if (causes.find === (cause => (cause.publicity === updatedValue.publicity) === true)) {
      setCausePublicity(true)
    } else {
      setCausePublicity(false)
    }
  }

  const blockRadioDirectDealPublicity = updatedValue => {
    if (verificationCausePublicity(updatedValue)) {
      if (setCausePublicity(true)) {
        setCausePublicityConfidence(true)
        setRadioAmountValue('1')
      } else {
        setCausePublicityConfidence(false)
      }
    } else {
      setCausePublicity(false)
      setCausePublicityConfidence(false)
    }
  }

  const changesCauseDescriptions = updatedValue => {
    const description = causes.find(cause => cause.value === updatedValue)?.description
    setCauseDescription(description || DIRECT_DEAL_CAUSES_DESCRIPTION_DEFAULT)
  }

  useImperativeFormRef(formRef, () => ({ causeError, causeValue, valueRadioAmount, isRequiringMoreDocuments }), [
    causeError,
    causeValue,
    valueRadioAmount,
    isRequiringMoreDocuments
  ])

  return (
    <Grid container margin="33px 0" spacing={2}>
      <Grid item xs={12} md={2}>
        <Typography fontWeight="bold" variant="body2" fontSize="16px">
          Antecedentes básicos
        </Typography>
        <Typography fontSize="14px" fontWeight="regular" lineHeight="18px" variant="body2">
          Ingresa información específica de tu Trato Directo.
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid item>
          <Select
            error={causeError}
            label="Causal de Trato Directo"
            placeholder="Selecciona la causal de tu Trato Directo"
            loading={loading}
            options={causes}
            value={causeValue}
            onChange={handleCauseChange}
          />
        </Grid>
        <Grid item>
          {loading && <Skeleton width="150px" />}

          {!loading && (
            <>
              <Typography fontSize="14px" fontWeight="bold" lineHeight="18px">
                ¿El Trato Directo será por un monto mayor a 1.000 UTM?
              </Typography>
              <Typography fontSize="12px" lineHeight="16px" color="black3">
                Monto referencial calculado en peso chileno{' '}
                {utm?.valueCLPRef && (
                  <FormattedCurrencyComponent amount={utm?.valueCLPRef} currency="CLP" includeSymbol />
                )}
              </Typography>
              <Typography fontSize="12px" lineHeight="16px" color="black3">
                Valor UTM al día {utm?.date}:{' '}
                {utm?.valueCLP && <FormattedCurrencyComponent amount={utm?.valueCLP} currency="CLP" includeSymbol />}
              </Typography>
            </>
          )}

          <RowRadio>
            <Radio
              disabled={isSelectedCausePublicityConfidence}
              options={BINARY_CONFIRMATION_RESPONSE}
              loading={loading}
              value={valueRadioAmount}
              onChange={handleRadioAmount}
            />
          </RowRadio>
        </Grid>

        {isSelectedCausePublicity && valueRadioAmount === '1' && (
          <Grid item margin="0 0 32px">
            <Card color="primary">
              <Typography fontSize="14px" lineHeight="18px">
                Debido a la causal y monto del Trato Directo que deseas realizar, este{' '}
                <Strong>deberá permanecer publicado al menos 5 días hábiles</Strong> en caso de que exista otro
                proveedor que pueda ofrecer el mismo bien o servicio.
              </Typography>
            </Card>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} md={4}>
        <Card padding="24px">
          <Grid container>
            <Grid item>
              <Typography fontSize="16px" fontWeight="bold">
                Causal y justificación de Trato Directo
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontSize="14px" lineHeight="18px">
                {causeDescription}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} md={4} padding="17px">
        {!size.isDesktop && isRequiringMoreDocuments && valueRadioAmount === '1' && (
          <Grid item xs={12} md={12}>
            <Card enableBorder="true" lineColor="default" color="blank" padding="24px">
              <Grid container>
                <Grid item>
                  <Typography variant="h4" fontWeight="bold" padding="0 0 8px">
                    Se requiere informe técnico
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" lineHeight="18px">
                    {DIRECT_DEAL_MORE_DOCUMENTS_ARE_REQUIRED}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

DirectDealBasicBackground.propTypes = {
  causes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string
    })
  ),
  loading: PropTypes.bool,
  formRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  utm: PropTypes.shape({
    date: PropTypes.string,
    valueCLP: PropTypes.number,
    valueCLPRef: PropTypes.number
  })
}

export default DirectDealBasicBackground
