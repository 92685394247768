import React from 'react'
import PropTypes from 'prop-types'

import { Button, CircleWarningIcon, Dialog, Typography } from '@chilecompra/react-kit/components'
import { GridContainer, GridItem } from './DirectDealStepTwo.styles'

/**
 * The DirectDealStepTwo's confirm dialog.
 */
const DirectDealStepTwoConfirmDialog = props => {
  const { loading, onCreate, onClose, open } = props

  return (
    <Dialog open={open} disableBackdropClick verticalAlign="top" onClose={onClose}>
      <GridContainer direction="row" justifyContent="center" alignItems="center">
        <GridItem textAlign="center" padding="13px" xs={12}>
          <CircleWarningIcon />
          <Typography variant="h3" lineHeight="22px" fontWeight="bold" color="black1">
            Estás a punto de crear una ficha de Trato Directo
          </Typography>
        </GridItem>
        <GridItem margin="19px 0" textAlign="center" xs={12}>
          <Typography variant="body2" lineHeight="21px" color="black1">
            Una vez publicada la ficha, los proveedores podrán ingresar consultas y hacer observaciones, solicitando la
            realización de la compra por otro mecanismo de compra.
          </Typography>
        </GridItem>
        <GridItem padding="0 50px" margin="4px 0" textAlign="center" xs={12}>
          <Button color="success" variant="contained" onClick={onCreate} loading={loading}>
            Crear ficha
          </Button>
        </GridItem>
        <GridItem padding="0 50px" margin="4px 0" textAlign="center" xs={12}>
          <Button variant="text" onClick={onClose} loading={loading}>
            Volver
          </Button>
        </GridItem>
      </GridContainer>
    </Dialog>
  )
}

DirectDealStepTwoConfirmDialog.propTypes = {
  loading: PropTypes.bool,
  onCreate: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
}

export default DirectDealStepTwoConfirmDialog
