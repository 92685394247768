import React from 'react'
import { getCookie } from '@chilecompra/react-kit'

import AuthorizationProviderContainer from '../../containers/AuthProvider/AuthProvider.container'
import AuthorizationContext from '../../containers/AuthProvider/AuthProvider.context'

import DirectDealPageHeader from '../../containers/DirectDealPageHeader/DirectDealPageHeader.container'
import DirectDealSummaryContainer from '../../containers/DirectDealSummary/DirectDealSummary.container'

import BrandLayout from '../../components/Layouts/BrandLayout'

import { Grid } from './DirectDealSummary.styles'

import { useAuthorization } from '../../containers/AuthProvider/AuthProvider.hooks'
import useConditionalSearchNavigate from '../../modules/hooks/useCondicionalSearchNavigate'
import { useLocation } from 'react-router-dom'
import routes from '../../config/settings/routes'
import FooterPublicContainer from '../../containers/FooterPublic/FooterPublic.container'
import HeaderPublicContainer from '../../containers/HeaderPublic/HeaderPublic.container'

/**
 * The DirectDealSummary's page.
 */
const DirectDealSummary = () => {
  const { isBuyer, isSeller } = useAuthorization()
  const { conditionalNavigate } = useConditionalSearchNavigate(isSeller, isBuyer)
  const { state } = useLocation()

  const handlerRedirectSearch = () => {
    const query = getCookie('search')
    conditionalNavigate(query)
  }

  const summaryHeader = {
    breadcrumbs: [
      {
        text: 'Escritorio',
        onRedirectTo: handlerRedirectSearch
      },
      {
        text: 'Trato Directo',
        onRedirectTo: handlerRedirectSearch
      }
    ]
  }

  return (
    <AuthorizationProviderContainer>
      <AuthorizationContext.Consumer>
        {() => (
          <BrandLayout
            header={state?.from === routes.searchPublic && <HeaderPublicContainer />}
            featured={<DirectDealPageHeader {...summaryHeader} />}
            footer={state?.from === routes.searchPublic && <FooterPublicContainer />}
          >
            <Grid container>
              <Grid item xs={12} md={12}>
                <DirectDealSummaryContainer />
              </Grid>
            </Grid>
          </BrandLayout>
        )}
      </AuthorizationContext.Consumer>
    </AuthorizationProviderContainer>
  )
}

export default DirectDealSummary
